import { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import TopExpertsProfileCover from "./TopExpertsProfileCover";
import { useGetTopExpertDetailsMutation } from "../../lib/apis/expertApis";

const TopExpertsDetails: React.FC = () => {
  const [getTopExpertDetails, { isLoading, isError }] =
    useGetTopExpertDetailsMutation();

  const [searchParams] = useSearchParams();

  const params = useParams();

  const expertId = searchParams.get("eid");

  const { name } = params;

  useEffect(() => {
    getTopExpertDetails(expertId);
  }, [expertId, name]);

  return (
    <>
      <TopExpertsProfileCover />
    </>
  );
};

export default TopExpertsDetails;
