import { ReactNode, createContext, useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
// import { convertUrlToBase64 } from "../helpers/files";

import { sortChatMessagesByTime } from "../helpers/message";

export const ChatContext = createContext({
  messages: [] as {
    senderId: string;
    message: string;
    created_at: Date;
    file: string | null;
    serverFile: string | null;
  }[],
  updateSocketMessages: (messages?: any, messageId?: string) => {},
  joinRoom: (roomId: string, userId: string) => {},
  sendMessage: (
    roomId: string,
    message: string,
    senderId: string,
    base64File?: string | null | ArrayBuffer | File,
    fileName?: string
  ) => {},
  // listenToChatEvents: () => {},
  onlineUsers: {} as { roomId: string; users: [] },
  markMessageAsRead: (roomId: string, otherUserId: string) => {},
});

const ChatContextProvider = ({ children }: { children: ReactNode }) => {
  const [socketMessages, setSocketMessages] = useState<
    {
      senderId: string;
      message: string;
      created_at: Date;
      file: string | null;
      serverFile: string | null;
    }[]
  >([]);

  const [onlineUsers, setOnlineUsers] = useState<{
    roomId: string;
    users: [];
  }>({ roomId: "", users: [] });

  const API_URL = "https://api.deskzone.pw";

  const socket = useRef(
    io(API_URL, {
      transports: ["websocket"],
      secure: true,
      reconnection: true,
      reconnectionAttempts: 1000,
      reconnectionDelay: 1000,
    })
  );

  const updateSocketMessages = (messages?: any, messageId?: string) => {
    if (messageId) return setSocketMessages([]);
    if (messages && messages.length > 0) {
      const sortedItems = sortChatMessagesByTime(messages);

      sortedItems.slice(1).forEach((message: any) => {
        setSocketMessages((prevMessages) => [
          ...prevMessages,
          {
            senderId: message.sender.id,
            message: message.message,
            created_at: message.created_at,
            serverFile: message?.message_attachment?.attachment,
            file: null,
          },
        ]);
      });
    }
  };

  // join room function
  const joinRoom = (roomId: string, userId: string) => {
    socket?.current.emit("joinRoom", {
      roomId,
      userId,
    });
  };

  // send message function
  const sendMessage = (
    roomId: string,
    message: string,
    senderId: string,
    base64File?: string | null | ArrayBuffer | File,
    fileName?: string
  ) => {
    console.log("message is sending");
    socket.current.emit("sendMessage", {
      roomId,
      message,
      senderId,
      file: base64File,
      fileName,
    });
  };

  const markMessageAsRead = (roomId: string, otherUserId: string) => {
    socket?.current?.emit("viewedMessage", { roomId, otherUserId });
  };

  useEffect(() => {
    const currentSocket = socket.current;

    currentSocket.on("newMessage", (message: any) => {
      setSocketMessages((prevMessages) => [
        ...prevMessages,
        {
          senderId: message.senderId,
          message: message.message,
          created_at: message?.messageSaved[0]?.created_at,
          file: message?.reConstructBase64,
          serverFile: message?.messageSaved[1]?.attachment,
        },
      ]);
    });

    return () => {
      currentSocket.off("newMessage");
    };
  }, [socket]);

  useEffect(() => {
    socket?.current?.on("connect", () => {
      console.log("socket connected");
    });

    socket?.current?.on("disconnect", () => console.log("socket disconnected"));

    socket?.current.on("connect_error", (error: any) =>
      console.log(error.message)
    );
  }, [socket]);

  useEffect(() => {
    socket.current.on("onlineUsers", (users: any) => {
      setOnlineUsers(users);
    });
  }, [socket]);

  const value = {
    messages: socketMessages,
    updateSocketMessages,
    joinRoom,
    sendMessage,
    // listenToChatEvents,
    onlineUsers,
    markMessageAsRead,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export default ChatContextProvider;
