import { useEffect } from "react";
import ButtonLoader from "./ButtonLoader";
import styles from "./SuccessModal.module.css";

const WarningModal: React.FC<{
  message?: string;
  open?: boolean;
  buttonText?: string;
  onCloseModal?: () => void;
  isLoading?: boolean;
  onCancelOperation?: () => void;
  warningMessage?: string;
}> = ({
  message,
  open,
  onCloseModal,
  buttonText,
  isLoading,
  onCancelOperation,
  warningMessage,
}) => {
  useEffect(() => {
    // Add or remove overflow hidden on <html> tag
    if (open) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }

    // Cleanup function to reset overflow on unmount
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [open]);

  return (
    <div
      id="successModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${styles.modal_container} ${
        open ? "flex" : "hidden"
      }   overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-full`}
    >
      <div className="relative p-4 w-full flex justify-center items-center  max-w-96 h-full md:h-auto">
        <div className="relative p-4 text-center w-full rounded-lg shadow bg-gray-800 sm:p-5">
          <div
            className={`${styles.warning_modal_icon}  w-8 h-8 rounded-full  p-2 flex items-center justify-center mx-auto mb-3.5`}
          >
            <svg
              fill="currentColor"
              className="w-6 h-6 text-cyan-100"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
            </svg>

            <span className="sr-only">Success</span>
          </div>
          <p className="mb-4 text-xs md:text-sm font-semibold text-gray-900 dark:text-white">
            {message}
          </p>

          <span className="block text-xs font-semibold text-red-500">
            {warningMessage}
          </span>
          <div className="flex gap-10 mt-7 items-center justify-center">
            <button
              data-modal-toggle="successModal"
              onClick={onCloseModal}
              type="button"
              className={`${styles.warning_modal_btn_danger}  py-2 px-3 text-xs font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900`}
            >
              {isLoading ? <ButtonLoader /> : buttonText}
            </button>

            <button
              data-modal-toggle="successModal"
              onClick={onCancelOperation}
              type="button"
              className={`${styles.warning_modal_btn} py-2 px-3 text-xs font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
