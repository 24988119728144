import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedInExpert: null,
};

export const loggedInExpertSlice = createSlice({
  initialState,
  name: "loggedInExpertState",
  reducers: {
    setExpertDetails: (state, action) => {
      state.loggedInExpert = action.payload;
    },

    clearExpertDetails: (state) => {
      state.loggedInExpert = null;
    },
  },
});

export default loggedInExpertSlice.reducer;

export const { setExpertDetails, clearExpertDetails } =
  loggedInExpertSlice.actions;
