import { useState } from "react";
import Slider from "@mui/material/Slider";

const PriceRange: React.FC<{
  onApplyResult: (searchData: { price: number; order: string }) => void;
}> = ({ onApplyResult }) => {
  const [sliderValue, setSliderValue] = useState(0); // Default value
  const [previousValue, setPreviousValue] = useState(50); // Initial previous value
  const [priceOrder, setPriceOrder] = useState("");
  const [price, setPrice] = useState(0);

  const handleChange = (event: Event, newValue: number | number[]) => {
    // Compare current and previous values
    if (typeof newValue === "number") {
      if (newValue > previousValue) {
        setPrice(newValue);
      } else if (newValue < previousValue) {
        setPrice(newValue);
      }
    }
    // Update state
    setPreviousValue(sliderValue);
    if (typeof newValue === "number") {
      setSliderValue(newValue);
    }
  };

  return (
    <div className="w-[400px] pb-3 shadow-sm rounded-s-md px-5">
      <Slider
        value={sliderValue}
        onChange={handleChange}
        min={0}
        max={10000}
        aria-label="Slider"
      />

      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center mb-4 mt-5">
          <input
            id="default-radio-1"
            type="radio"
            value="lte"
            name="default-radio"
            className="w-4 h-4 text-[#24b47e] bg-gray-100 border-gray-300 focus:ring-[#24b47e]  dark:ring-offset-[#24b47e] focus:ring-2 dark:bg-[#24b47e] dark:border-[#24b47e]"
            onChange={(event) => setPriceOrder(event.target.value)}
          />
          <label
            htmlFor="default-radio"
            className="ms-2 text-[10px] font-medium text-gray-900 dark:text-gray-300"
          >
            Least acceptable amount
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="default-radio"
            type="radio"
            value="gte"
            name="default-radio"
            className="w-4 h-4 text-[#24b47e] bg-gray-100 border-gray-300 focus:ring-[#24b47e]  dark:ring-offset-[#24b47e] focus:ring-2 dark:bg-[#24b47e] dark:border-[#24b47e]"
            onChange={(event) => setPriceOrder(event.target.value)}
          />
          <label
            htmlFor="default-radio"
            className="ms-2 text-[10px] font-medium text-gray-900 dark:text-gray-300"
          >
            Highest acceptable amount
          </label>
        </div>
      </div>

      <div className="">
        <p className="text-[10px] font-medium text-gray-900 dark:text-gray-300">
          Price Range: ${price} - $10000
        </p>

        <button
          disabled={(!price || Number(price) <= 0) && !priceOrder}
          className="w-full bg-[#24b47e] text-white rounded-md py-2 mt-5"
          onClick={() =>
            onApplyResult({ price: Number(price), order: priceOrder })
          }
          type="button"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default PriceRange;
