import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import StyledDropdown from "../commons/DropDown";
import ExpertServiceCard from "../Dashboard/services/ExpertServiceCard";
import ServicesLoader from "../commons/ServicesLoader";
import { useGetAllExpertServicesMutation } from "../../lib/apis/serviceApis";
import { useGetTopExpertsMutation } from "../../lib/apis/expertApis";
import PriceDropdown from "./PriceDropdown";
import ErrorPage from "../commons/ErrorPage";
import styles from "./Search.module.css";

const SearchResult: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [dropDownCategoryId, setDropDownCategoryId] = useState<string | null>(
    ""
  );
  const [expertInfo, setExpertInfo] = useState<
    { value: string; label: string }[]
  >([{ value: "Loko Dev Team", label: "Loko Dev Team" }]);
  const [topExpertName, setTopExpertName] = useState("");
  const [categoryData, setCategoryData] = useState([
    { value: "", label: "UI Design" },
  ]);
  const [servicePrice, setServicePrice] = useState("");
  const [scrollTop, setScrollTop] = useState(0);

  const searchQuery = searchParams.get("q");

  const { serviceCategories } = useSelector(
    (state: any) => state.serviceCategoryState
  );

  const [getAllExpertServices, { isLoading, data, isError }] =
    useGetAllExpertServicesMutation();

  const [getTopExperts, { data: expertData }] = useGetTopExpertsMutation();

  useEffect(() => {
    if (serviceCategories && searchQuery) {
      const categoryObj = serviceCategories.find(
        (cat: any) => cat.name === searchQuery
      );
      if (categoryObj) {
        setCategoryId(categoryObj.id);
      } else {
        setCategoryId(null);
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    getTopExperts(null);
  }, []);

  useEffect(() => {
    if (expertData) {
      setExpertInfo(
        expertData?.data.map((expert: any) => ({
          value: expert?.expert_profile_business_name,
          label: expert?.expert_profile_business_name,
        }))
      );
    }

    if (serviceCategories) {
      setCategoryData(
        serviceCategories?.map((cat: any) => ({
          value: cat.id,
          label: cat.name,
        }))
      );
    }
  }, [expertData, serviceCategories]);

  useEffect(() => {
    if (categoryId) {
      getAllExpertServices(
        `services?page=1&limit=30&filterBy[service_category_id]=${categoryId}`
      );
    } else {
      getAllExpertServices(`services?page=1&limit=30&search=${searchQuery}`);
    }
  }, [categoryId, searchQuery]);

  useEffect(() => {
    if (dropDownCategoryId) {
      getAllExpertServices(
        `services?page=1&limit=30&filterBy[service_category_id]=${dropDownCategoryId}`
      );
    } else {
      getAllExpertServices(`services?page=1&limit=30&search=${searchQuery}`);
    }
  }, [dropDownCategoryId]);

  useEffect(() => {
    if (topExpertName) {
      getAllExpertServices(`services?page=1&limit=30&search=${topExpertName}`);
    }
  }, [topExpertName]);

  const onGetServiceByPrice = async (price: number, priceOrder: string) => {
    getAllExpertServices(
      `services?page=1&limit=30&lowest_acceptable_amount[${priceOrder}]=${price}`
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let dashboardContent;

  if (isLoading) {
    dashboardContent = <ServicesLoader />;
  }

  if (isError) {
    dashboardContent = <ErrorPage />;
  }
  if (data?.data?.length <= 0) {
    dashboardContent = (
      <ErrorPage message="404" mainMessage="No service found" />
    );
  }
  if (data && data?.data?.length > 0) {
    dashboardContent = (
      <section className="w-full my-10 px-4 pb-9 pt-5 md:px-6 md:pb-10 md:pt-6 lg:px-7 lg:pb-12 3xl:px-8 ">
        <div className="-z-10 grid grid-cols-1 lsm:grid-cols-2  md:grid-cols-2 smd:grid-cols-3 gap-5 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
          {data &&
            data?.data?.length > 0 &&
            data?.data?.map((service: any) => {
              return (
                <ExpertServiceCard
                  businessLogo={service.expert_profile?.business_logo}
                  serviceVideo={service.explainer_video}
                  businessName={service.expert_profile?.business_name}
                  price={service.lowest_acceptable_amount}
                  servicePoster={service.thumbnail}
                  service={service.title}
                  key={service.id}
                  id={service.id}
                  category={service?.service_category?.name}
                  slug={service.slug}
                  expertId={service.expert_profile?.id}
                  averageRating={service.average_rating}
                />
              );
            })}
        </div>
      </section>
    );
  }

  return (
    <>
      {/* <div className=""> */}
      <div
        className={`z-[3] h-fit sticky top-24 ${
          scrollTop > 90 &&
          `bg-[#181818] ease-in-out shadow-md ${styles.sticky_shadow}`
        }  py-7 w-full mt-[100px] gap-7 flex-col flex md:flex-row justify-between pl-8`}
      >
        <div className="flex flex-wrap mt-7 gap-10">
          <div>
            <p className="text-white text-[10px] md:text-xs font-bold mb-2">
              Top Experts
            </p>
            <StyledDropdown
              options={expertInfo}
              defaultValue="Expert"
              onChange={(value) => setTopExpertName(value)}
            />
          </div>

          <div>
            <p className="text-white text-[10px] md:text-xs font-bold mb-2">
              Top Categories
            </p>
            <StyledDropdown
              options={categoryData.length > 0 ? categoryData : []}
              defaultValue="Price"
              onChange={(value) => setDropDownCategoryId(value)}
            />
          </div>

          <div>
            <p className="text-white text-[10px] md:text-xs font-bold mb-2">
              Filter By Price
            </p>
            <PriceDropdown
              options={[{ value: "$0", label: "$0" }]}
              defaultValue="Price"
              onChange={(value) =>
                onGetServiceByPrice(value.currentPrice, value.order)
              }
            />
          </div>
        </div>
      </div>
      {/* </div> */}
      {dashboardContent}
    </>
  );
};

export default SearchResult;
