import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCategories } from "./redux/serviceCategorySlice";
import { getToken } from "../../helpers/firebase";

let baseUrl: string;

if (process.env.NODE_ENV === "development") {
  baseUrl = process.env.REACT_APP_DEV_API_BASE_URL || "";
} else {
  baseUrl =
    process.env.REACT_APP_PROD_API_BASE_URL || "https://api.deskzone.pw/api";
}

export const serviceApis = createApi({
  reducerPath: "serviceApis",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
      const token = await getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getServiceCategories: builder.mutation({
      query: (payload) => ({
        url: "/expert-service-category",
        method: "GET",
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(setCategories(data?.data));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),

    getCurrencies: builder.mutation({
      query: (payload) => ({
        url: "/currencies",
        method: "GET",
      }),
    }),

    createExpertService: builder.mutation({
      query: (payload) => ({
        url: "/expert/create-service",
        method: "POST",
        body: payload,
      }),
    }),
    getAllExpertServices: builder.mutation({
      query: (payload) => ({
        url: `/expert/${payload}`,
        method: "GET",
      }),
    }),

    getExpertServiceById: builder.mutation({
      query: (payload) => ({
        url: `/expert/services/${payload}`,
        method: "GET",
      }),
    }),

    getExpertServiceBySlug: builder.mutation({
      query: (payload) => ({
        url: `/expert/services/slug/${payload}`,
        method: "GET",
      }),
    }),

    createServiceRequest: builder.mutation({
      query: (paload) => ({
        url: `/expert/create-service-requests`,
        method: "POST",
        body: paload,
      }),
    }),

    getLoggedInExpertServices: builder.mutation({
      query: () => ({
        url: "/expert/my-profile",
        method: "GET",
      }),
    }),

    getExpertOtherServices: builder.mutation({
      query: ({ expertServiceId, expertId }) => ({
        url: `/expert/more-expert-services/${expertServiceId}/${expertId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetServiceCategoriesMutation,
  useGetCurrenciesMutation,
  useCreateExpertServiceMutation,
  useGetAllExpertServicesMutation,
  useGetExpertServiceByIdMutation,
  useCreateServiceRequestMutation,
  useGetExpertServiceBySlugMutation,
  useGetLoggedInExpertServicesMutation,
  useGetExpertOtherServicesMutation,
} = serviceApis;
