import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  archiveMessages: null,
};

export const archiveMessageSlice = createSlice({
  initialState,
  name: "archiveMessageState",
  reducers: {
    setArchiveMessages: (state, action) => {
      state.archiveMessages = action.payload;
    },
  },
});

export default archiveMessageSlice.reducer;

export const { setArchiveMessages } = archiveMessageSlice.actions;
