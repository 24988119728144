import { useSelector } from "react-redux";
import ExpertServiceCard from "../Dashboard/services/ExpertServiceCard";

const ExpertProducts: React.FC = () => {
  const { topExpertDetails } = useSelector(
    (state: any) => state.topExpertDetailsState
  );

  return (
    <div className="h-full  mb-6">
      <div className="flex h-full pt-6 focus:outline-none md:pt-8 lg:pt-10">
        <div className="w-full px-4 pb-9 pt-5 md:px-6 md:pb-10 md:pt-6 lg:px-7 lg:pb-12 3xl:px-8">
          <div className="-z-10 grid grid-cols-1 lsm:grid-cols-2  md:grid-cols-2 smd:grid-cols-3 gap-5 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
            {topExpertDetails &&
              topExpertDetails?.services.length > 0 &&
              topExpertDetails?.services.map((service: any) => {
                return (
                  <ExpertServiceCard
                    businessLogo={topExpertDetails?.business_logo}
                    businessName={topExpertDetails?.business_name}
                    id={service?.id}
                    price={service?.lowest_acceptable_amount}
                    service={service?.title}
                    servicePoster={service?.thumbnail}
                    serviceVideo={service?.explainer_video}
                    // category = {}
                    expertId={topExpertDetails?.id}
                    slug={service?.slug}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertProducts;
