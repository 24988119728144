import { useSelector } from "react-redux";
import { IconContext } from "react-icons";
import { FaCheck } from "react-icons/fa6";
import { TiStarburst } from "react-icons/ti";
import styles from "./TopExperts.module.css";

const TopExpertsAbout: React.FC = () => {
  const { topExpertDetails } = useSelector(
    (state: any) => state.topExpertDetailsState
  );

  return (
    <div
      // variants={fadeInBottom()}
      className="mx-auto lsm:flex items-start  mt-10 w-full   justify-between px-24"
    >
      <div className="md:w-6/12 lg:w-7/12 xl:w-5/12">
        <h2
          className={`${styles.intro_text} mb-3 text-sm font-medium text-dark lg:text-15px`}
        >
          {topExpertDetails?.business_name}
        </h2>
        <p className={`leading-6 text-sm ${styles.about}`}>
          {topExpertDetails?.about_me}
        </p>
        <div className="space-y-3.5 pt-4 text-dark/80 dark:text-light/80 md:pt-6 xl:pt-7">
          <address className="flex max-w-sm items-start not-italic leading-[1.8]">
            <span className="mt-[3px] w-7 shrink-0 text-dark-800 dark:text-light-900">
              {/* <MapPinIcon className="h-4 w-4" /> */}
            </span>
            {/* {formatAddress(address)} */}
          </address>
          <div className="flex items-center">
            <span className="w-7 shrink-0 text-dark-800 dark:text-light-900">
              {/* <AtIcon className="h-4 w-4" /> */}
            </span>
            {/* <a href={`mailto:${owner?.email}`} className="hover:text-brand">
            {owner?.email}
          </a> */}
          </div>
        </div>
      </div>
      <div
        className={`${styles.summary} mt-7 mb-28 flex-shrink-0 rounded-md bg-light p-6 shadow-card dark:bg-dark-250 md:mt-0 md:w-72 lg:p-8`}
      >
        <div className="-mx-2 flex pb-6 lg:pb-3">
          <div className="px-2 pr-10 text-13px capitalize text-dark-500 dark:text-light-900 w-full lg:pr-0">
            <span
              className={`${styles.intro_text} mb-0.5 text-2xl text-dark dark:text-light`}
            >
              {topExpertDetails?.services.length > 0
                ? topExpertDetails?.services.length
                : 0}
            </span>
            <div className={styles.about}>
              {topExpertDetails?.services?.length <= 1 ? "Service" : "Services"}
            </div>
          </div>
          {/* <div className="flex flex-shrink-0 flex-col px-2 pr-10 text-13px capitalize text-dark-500 dark:text-light-900 xl:w-1/2 xl:pr-0">
            <span className="mb-0.5 text-2xl text-dark dark:text-light">
              10
            </span>
          </div> */}
        </div>
        <div className={`${styles.socials} space-y-3`}>
          <div className="mt-3">
            <div>
              <div className="flex">
                <div className="flex items-center">
                  <IconContext.Provider
                    value={{ color: "#24b47e", size: "2em" }}
                  >
                    <TiStarburst />
                  </IconContext.Provider>

                  <div className="-ml-6 mr-4">
                    <IconContext.Provider
                      value={{ color: "#fff", size: "1em" }}
                    >
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                </div>

                <div className="text-white text-xs mt-[0.5rem]">
                  Verified by{" "}
                  <span className="font-bold font-brand text-[#24b47e]">
                    DeskZone
                  </span>
                </div>
              </div>

              <div className="flex mt-2">
                <div className="flex items-center">
                  <IconContext.Provider
                    value={{ color: "#24b47e", size: "2em" }}
                  >
                    <TiStarburst />
                  </IconContext.Provider>

                  <div className="-ml-6 mr-4">
                    <IconContext.Provider
                      value={{ color: "#fff", size: "1em" }}
                    >
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                </div>

                <div className="text-white text-xs mt-[0.5rem]">
                  Quality{" "}
                  <span className="font-bold font-brand text-[#24b47e]">
                    Assurance
                  </span>
                </div>
              </div>

              <div className="flex mt-2">
                <div className="flex items-center">
                  <IconContext.Provider
                    value={{ color: "#24b47e", size: "2em" }}
                  >
                    <TiStarburst />
                  </IconContext.Provider>

                  <div className="-ml-6 mr-4">
                    <IconContext.Provider
                      value={{ color: "#fff", size: "1em" }}
                    >
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                </div>

                <div className="text-white text-xs mt-[0.5rem]">
                  Secured{" "}
                  <span className="font-bold font-brand text-[#24b47e]">
                    Escrow{" "}
                  </span>
                  Service
                </div>
              </div>

              <div className="flex mt-2">
                <div className="flex items-center">
                  <IconContext.Provider
                    value={{ color: "#24b47e", size: "2em" }}
                  >
                    <TiStarburst />
                  </IconContext.Provider>

                  <div className="-ml-6 mr-4">
                    <IconContext.Provider
                      value={{ color: "#fff", size: "1em" }}
                    >
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                </div>
                <div className="text-white text-xs mt-[0.5rem]">
                  Dedicated{" "}
                  <span className="font-bold font-brand text-[#24b47e]">
                    24/7{" "}
                  </span>
                  Support
                </div>
              </div>
            </div>
          </div>

          {/* {socials.map(({ icon, url }, idx) => (
          <a
            key={idx}
            href={url}
            target="_blank"
            rel="noreferrer"
            className="group flex items-center"
          >
            {getIcon({
              iconList: socialIcons,
              iconName: icon,
              className:
                'w-3.5 h-3.5 text-dark-800 dark:text-light-900 shrink-0',
            })}
            <span className="transition-colors group-hover:text-dark ltr:pl-2 rtl:pr-2 dark:group-hover:text-light">
              {url.slice(12, -1).split('/').slice(0, 1)}
            </span>
          </a>
        ))} */}
        </div>
      </div>
    </div>
  );
};

export default TopExpertsAbout;
