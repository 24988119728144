import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import {
  useDeleteItemFromWishlistMutation,
  useDeleteWishlistCategoryMutation,
} from "../../../lib/apis/wishlistApi";
import Spinner from "../../commons/Spinner";
// import ProfileLoader from "../common/ProfileLoader";
import WishlistVideoPreview from "../common/WishlistVideoPreview";
import WarningModal from "../../commons/WarningModal";
import styles from "../Profile.module.css";
import { Link } from "react-router-dom";

const WishList = () => {
  const [wishlistId, setWishlistId] = useState<string | null>(null);

  const [showDeleteAllWishlistModal, setShowDeleteAllWishlistModal] =
    useState(false);

  const [deleteItemFromWishlist, { isLoading }] =
    useDeleteItemFromWishlistMutation();

  const [deleteWishlistCategory, { isLoading: _isLoading, isSuccess }] =
    useDeleteWishlistCategoryMutation();

  const { wishlist } = useSelector((state: any) => state.userWishlistState);

  const onDeleteItemFromWishlist = async (
    serviceId: string,
    wishlistId: string
  ) => {
    setWishlistId(serviceId);
    if (wishlist.length > 1) {
      await deleteItemFromWishlist(wishlistId);
    } else {
      await deleteWishlistCategory(wishlist[0].wishListCategory.id);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setShowDeleteAllWishlistModal(false);
    }
  }, [isSuccess]);

  return (
    <>
      {showDeleteAllWishlistModal && (
        <WarningModal
          open={showDeleteAllWishlistModal}
          onCloseModal={() =>
            deleteWishlistCategory(wishlist[0].wishListCategory.id)
          }
          onCancelOperation={() => setShowDeleteAllWishlistModal(false)}
          message="Continue to delete wishlist"
          buttonText="Continue"
          isLoading={_isLoading}
        />
      )}
      <main className="flex w-full flex-grow flex-col lg:flex-grow-0 lg:bg-light lg:px-12 lg:py-8 lg:dark:bg-dark-250">
        <div
          className="flex min-h-full flex-grow flex-col"
          style={{ opacity: "1", transform: "none" }}
        >
          <div
            className={`${styles.wishlist_category} p-2 mb-10 lg:pl-0 flex justify-between`}
          >
            <div className="">
              <h1 className="mb-3 text-15px font-medium text-cyan-50">
                My Wishlist
                <span className="ml-1">
                  ({wishlist ? wishlist?.length : 0})
                </span>
              </h1>
              {wishlist ? (
                <p className={`text-[#8a8989] -mt-2 text-xs`}>
                  {wishlist[0]?.wishListCategory.name}
                </p>
              ) : (
                <p className={`text-[#8a8989] -mt-2 text-xs`}>
                  You do not have any service in your wishlist
                </p>
              )}
            </div>
            {wishlist && wishlist.length > 0 && (
              <div className="text-xs">
                <button
                  className="text-white font-bold rounded-2xl px-2 py-1 bg-[#e24c4c] hover:bg-[#fe7171] cursor-pointe"
                  onClick={() => setShowDeleteAllWishlistModal(true)}
                >
                  Delete Wishlist
                </button>
              </div>
            )}
          </div>

          {wishlist &&
            wishlist.length > 0 &&
            wishlist?.map((service: any) => {
              return (
                <AnimatePresence>
                  <motion.div
                    exit={{ opacity: 0, scale: 1.1 }}
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className={`${styles.wishlist_card} sm:flex sm:items-start border-b  mb-5  pt-4 px-4  border-dark-400 sm:gap-5`}
                  >
                    <div className="relative  w-full flex-shrink-0 border border-light-300 dark:border-0 sm:w-32 md:w-36">
                      <WishlistVideoPreview
                        serviceVideo={service?.expertService?.explainer_video}
                        servicePoster={service?.expertService?.thumbnail}
                        id={service?.expertService.id}
                        slug={service?.expertService.slug}
                      />
                    </div>
                    <div className="flex-start flex flex-1 flex-row justify-between items-center gap-3 sm:flex-row sm:justify-between md:gap-0">
                      <div className="pb-4 dark:border-dark-600">
                        <a
                          className="font-medium text-white text-xs lsm:text-sm  sm:mb-1.5"
                          href="/products/borobazar-react-next-grocery-template"
                        >
                          {service?.expertService?.title}
                        </a>
                        <Link
                          to={`/experts/${service?.expertService?.expert_profile?.business_name}/services?eid?${service?.expertService?.expert_profile?.id}`}
                        >
                          <p className="pt-0.5 text-xs  font-medium text-gray-400 sm:pt-0">
                            {
                              service?.expertService?.expert_profile
                                ?.business_name
                            }
                          </p>
                        </Link>
                        <div className="mt-2 sm:mt-3">
                          <span
                            style={{ color: "#24b47e" }}
                            className="py-1 text-sm font-semibold text-brand-dark"
                          >
                            ${service?.expertService?.lowest_acceptable_amount}
                          </span>
                        </div>
                      </div>
                      <div className="sm:flex sm:shrink-0 sm:items-center sm:gap-2.5 xs:pb-4 xs:pt-8 md:flex-nowrap md:gap-3.5 lg:gap-4">
                        {wishlistId === service.expertService.id &&
                        (isLoading || _isLoading) ? (
                          <div className="mr-5">
                            <Spinner />
                          </div>
                        ) : (
                          <button
                            onClick={async () =>
                              await onDeleteItemFromWishlist(
                                service?.expertService.id,
                                service?.id
                              )
                            }
                            type="button"
                            className="flex min-h-[46px] w-12 shrink-0 items-center justify-center rounded   transition-colors hover:bg-transparent hover:text-light-200 dark:border-dark-600 sm:h-12 !border-accent"
                          >
                            <svg
                              fill="currentColor"
                              className={`${styles.wishlist_icon}  text-accent h-5 w-5 text-brand dark:text-brand dark:hover:text-brand-dark `}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  </motion.div>
                </AnimatePresence>
              );
            })}
        </div>
      </main>
    </>
  );
};

export default WishList;
