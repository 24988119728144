import { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import { ChatContext } from "../../context/chat-context";
import {
  useGetChatMessagesMutation,
  useGetMoreChatMessagesMutation,
} from "../../lib/apis/messageApis";
import ChatBubble from "./ChatBubble";
import { IconContext } from "react-icons";
import PaymentModal from "./PaymentModal";
// import { generateRandomHttpLink } from "../../helpers/message";
import { useExpertStartProjectMutation } from "../../lib/apis/messageApis";
import Moment from "react-moment";
import { FaCheck } from "react-icons/fa6";
import { TiStarburst } from "react-icons/ti";
import { FaArrowRightLong } from "react-icons/fa6";
import { RiInboxArchiveLine } from "react-icons/ri";
import Icon from "../commons/Icon";
import styles from "./Negotiation.module.css";
import ChatInput from "./ChatInput";
import ProfileLoader from "../Profile/common/ProfileLoader";

const Negotation: React.FC = () => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const { user } = useSelector((state: any) => state.userState);

  const { chatData } = useSelector((state: any) => state.chatSessionState);

  const [showPaymentInitiateModal, setShowPaymentInitiateModal] =
    useState(false);
  const [showDeclineProjectModal, setShowDeclineProjectModal] = useState(false);

  const excludedRef = useRef<HTMLDivElement>(null);

  const params = useParams();

  const { messageId } = params;

  const location = useLocation();

  const [getChatMessages, { isLoading, error, isError, isSuccess }] =
    useGetChatMessagesMutation();

  const [getMoreChatMessages] = useGetMoreChatMessagesMutation();

  const [
    expertStartProject,
    {
      isSuccess: startProjectSuccess,
      isLoading: startProjectLoading,
      error: expertStartProjectError,
    },
  ] = useExpertStartProjectMutation();

  const chatCtx = useContext(ChatContext);

  useEffect(() => {
    if (messageId && user) {
      chatCtx.joinRoom(messageId, user.id);
    }
  }, [messageId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        excludedRef.current &&
        !excludedRef.current.contains(event.target as Node)
      ) {
        setOpenEmoji(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEmoji]);

  useEffect(() => {
    if (startProjectSuccess) {
      setShowPaymentInitiateModal(false);
    }
  }, [startProjectSuccess]);

  useEffect(() => {
    if (messageId) {
      localStorage.setItem("m_id", messageId);
      const timer = setTimeout(() => {
        getChatMessages(messageId);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [messageId]);

  useEffect(() => {
    if (isSuccess && chatData) {
      const timer = setTimeout(() => {
        chatCtx.updateSocketMessages(chatData?.chatMessages, "");
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  useEffect(() => {
    const handleNavigationAway = () => {
      chatCtx.updateSocketMessages([], "resetId");
    };

    return () => handleNavigationAway();
  }, [location]);

  // useEffect(() => {
  //   const onGetMoreChatMessages = async () => {
  //     // console.log("checking server health");
  //     getMoreChatMessages(messageId);
  //   };

  //   const timer = setInterval(() => {
  //     onGetMoreChatMessages();
  //   }, 300);

  //   return () => clearInterval(timer);
  // }, []);

  return (
    <>
      {isLoading && (
        <div className="w-4/5 mx-auto  mt-40 ">
          <ProfileLoader />
        </div>
      )}
      <PaymentModal
        open={showPaymentInitiateModal}
        onCloseModal={() =>
          expertStartProject(chatData?.expert_service_request?.id)
        }
        onCancelOperation={() => setShowPaymentInitiateModal(false)}
        message={`Start project for ${chatData?.client_user?.first_name}`}
        buttonText="Continue"
        isLoading={startProjectLoading}
        price={chatData?.expert_service_request?.amount_offered}
        warningMessage="Clicking continue is an indication that you are ready to start the project"
      />

      <PaymentModal
        open={showDeclineProjectModal}
        onCloseModal={() =>
          expertStartProject(chatData?.expert_service_request?.id)
        }
        onCancelOperation={() => setShowDeclineProjectModal(false)}
        message={`Decline project for ${chatData?.client_user?.first_name}`}
        buttonText="Decline"
        isLoading={startProjectLoading}
        price={chatData?.expert_service_request?.amount_offered}
        warningMessage="Clicking continue is an indication that you are not ready to start the project"
      />

      {!isLoading && isSuccess && (
        <main
          className={`flex w-full  max-h-full mt-28 max-w-[95%] mx-auto flex-grow flex-col lg:flex-grow-0 lg:bg-light lg:px-12 npm lg:dark:bg-dark-250`}
        >
          <div
            className="flex h-full  flex-grow flex-col"
            style={{ opacity: 1, transform: "none" }}
          >
            <div>
              <div className="flex justify-start items-center">
                {chatData?.client_user?.id === user?.id ? (
                  <Link
                    to={"/client/profile/messages"}
                    className="text-cyan-50 flex gap-2 items-center"
                    // onClick={() => setStage(1)}
                  >
                    <Icon iconClasses="fa-solid fa-arrow-left" />
                    Back to inbox
                  </Link>
                ) : (
                  <Link
                    to={"/expert/profile/messages"}
                    className="text-cyan-50 flex gap-2 items-center"
                    // onClick={() => setStage(1)}
                  >
                    <Icon iconClasses="fa-solid fa-arrow-left" />
                    Back to inbox
                  </Link>
                )}
              </div>

              <div className="mt-9 flex items-center justify-center gap-3 md:justify-start  md:gap-9">
                {chatData?.client_user?.id === user?.id ? (
                  <div className="flex gap-2 items-center">
                    <img
                      src={chatData?.expert?.business_logo}
                      className={`${styles.dev_logo} w-7 h-7  md:h-10 md:w-10 rounded-full`}
                      alt="dev_logo"
                    />

                    <h1 className="text-sm md:text-lg lg:text-3xl font-semibold text-white">
                      {chatData?.expert?.business_name}
                    </h1>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center">
                    <img
                      src={
                        chatData?.client_user?.avatar ||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQABqQIdskCD9BK0I81EbVfV9tTz320XvJ35A&s"
                      }
                      className={`${styles.dev_logo} w-7 h-7  md:h-10 md:w-10 rounded-full`}
                      alt="dev_logo"
                    />

                    <h1 className="text-sm md:text-lg lg:text-3xl font-semibold text-white">
                      {chatData?.client_user?.first_name}{" "}
                      {chatData?.client_user?.last_name}
                    </h1>
                  </div>
                )}

                <Link to="#">
                  <IconContext.Provider
                    value={{ color: "#24b47e", size: "1.5em" }}
                  >
                    <RiInboxArchiveLine />
                  </IconContext.Provider>
                </Link>
              </div>

              <div
                className={`${styles.chat_session} items-center gap-5 my-10 flex`}
              >
                <div className="basis-full px-0 smpx-2 md:px-5 lg:px-0 lg:basis-5/6 mb-8 mt-8 md:mb-0 ">
                  <div className="text-center ">
                    <h1 className="text-cyan-50 text-sm md:text-lg font-semibold mb-5">
                      <Moment format="MMMM D, YYYY">
                        {chatData?.expert_service_request?.updated_at}
                      </Moment>
                    </h1>

                    <p
                      className={`${styles.request_message} text-sm md:text-lg`}
                    >
                      {chatData?.client_user?.id === user?.id
                        ? "You sent a project request"
                        : "You received a project request"}
                    </p>
                    <p className="text-cyan-50 text-[10px] md:text-xs">
                      last updated ({" "}
                      <Moment fromNow>
                        {chatData?.expert_service_request?.updated_at}
                      </Moment>{" "}
                      )
                    </p>
                  </div>

                  <ScrollToBottom
                    className={`${styles.main_chat_request} mt-10 px-0 md:px-3 py-5`}
                  >
                    <div className="flex items-center gap-3 mb-5">
                      <Icon
                        iconClasses={`${styles.message_icon} fa-solid text-sm md:text-lg lg:text-2xl fa-message text-cyan-50`}
                      />
                      <h1 className="text-cyan-100 text-sm md:text-lg lg:text-2xl font-semibold">
                        Project Request
                      </h1>
                    </div>

                    <div
                      className={`${styles.request} text-sm  lg:text-lg pl-5 md:pl-10 pr-2 py-5 md:py-10`}
                    >
                      <p className="text-cyan-50 mb-5">
                        {chatData?.chatMessages[0]?.message}
                      </p>
                      <p className="text-cyan-50">
                        <span className="font-semibold">Date: </span>
                        <Moment format="MMMM D, YYYY">
                          {chatData?.expert_service_request?.project_end_date}
                        </Moment>{" "}
                        <span className="text-xs text-[#24b47e]">
                          (
                          <Moment fromNow>
                            {chatData?.expert_service_request?.project_end_date}
                          </Moment>
                          )
                        </span>
                      </p>
                      <p className="text-cyan-50">
                        <span className="font-semibold">Budget:</span> $
                        {chatData?.expert_service_request?.amount_offered}
                      </p>
                    </div>

                    <div className={`${styles.expert_response}  mt-10`}>
                      {chatCtx.messages.length > 0 &&
                        chatCtx.messages.map((message: any) => {
                          return (
                            <ChatBubble
                              message={message?.message}
                              isOwn={message?.senderId === user?.id}
                              key={message?.created_at}
                              created_at={message?.created_at}
                              otherUser={
                                chatData?.client_user?.id === user?.id
                                  ? chatData?.expert?.business_name
                                  : `${chatData?.client_user?.first_name} ${chatData?.client_user?.last_name}`
                              }
                              otherUserImage={
                                chatData?.client_user?.id === user?.id
                                  ? chatData?.expert?.business_logo
                                  : chatData?.client_user?.avatar ||
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQABqQIdskCD9BK0I81EbVfV9tTz320XvJ35A&s"
                              }
                              file={message?.file}
                              serverFile={message?.serverFile}
                            />
                          );
                        })}
                    </div>
                  </ScrollToBottom>

                  <ChatInput
                    onCloseEmoji={() => setOpenEmoji(openEmoji ? false : true)}
                    openEmoji={openEmoji}
                    excludedRef={excludedRef}
                  />
                </div>
                <div className="basis-1/6 hidden lg:block">
                  {chatData?.client_user?.id === user?.id ? (
                    <div
                      className={`${styles.certified_section2} flex items-center justify-center mb-5 w-[350px] rounded-md py-5 px-7`}
                    >
                      <div className="flex items-center gap-5">
                        <div className="basis-1/2">
                          <img
                            src={
                              chatData?.expert?.business_logo ||
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQABqQIdskCD9BK0I81EbVfV9tTz320XvJ35A&s"
                            }
                            className={`${styles.dev_logo} h-[90px] w-36 rounded-md`}
                            alt="dev_logo"
                          />
                        </div>

                        <div className="basis-1/2">
                          <h1 className="text-white md:text-sm text-center font-semibold">
                            {chatData?.expert?.business_name}
                          </h1>

                          <div className="mt-2">
                            {/* <div className="flex gap-1 py-2 rounded-lg text-lg font-bold items-center justify-center text-white bg-[#24b47e]"> */}
                            {/* <button
                                onClick={() =>
                                  setShowPaymentInitiateModal(true)
                                }
                                title={`Initiate payment for ${data?.data?.client_user?.first_name}`}
                                className="flex gap-1 py-4 rounded-lg text-xs w-full font-bold items-center justify-center text-white bg-[#24b47e]"
                              >
                                Initiate Payment
                              </button> */}
                            {/* <Icon iconClasses={`fa-solid fa-star`} /> */}
                            {/* </div> */}
                          </div>

                          <Link
                            to={`/experts/${chatData?.expert?.business_name}/services?eid=${chatData?.expert?.id}`}
                            className="text-white text-xs mt-1 ml-8 text-center"
                          >
                            View Profile
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`${styles.certified_section2} flex items-center justify-center mb-5 w-[350px] rounded-md py-5 px-7`}
                    >
                      <div className="flex w-full items-start gap-1">
                        <div className="basis-1/2">
                          <h1 className="text-white md:text-sm text-center font-semibold">
                            {chatData?.client_user?.first_name}{" "}
                            {chatData?.client_user?.last_name}
                          </h1>

                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQABqQIdskCD9BK0I81EbVfV9tTz320XvJ35A&s"
                            className={`${styles.dev_logo} h-[90px] w-36 rounded-md`}
                            alt="dev_logo"
                          />
                        </div>

                        <div className="basis-1/2">
                          <div className="mt-2 flex flex-col gap-1">
                            {/* <div className="flex gap-1 py-2 rounded-lg text-lg font-bold items-center justify-center text-white bg-[#24b47e]"> */}
                            <button
                              onClick={() => setShowPaymentInitiateModal(true)}
                              title={`Start for ${chatData?.client_user?.first_name}`}
                              className="flex gap-1 py-4 rounded-lg text-xs w-full font-bold items-center justify-center text-white bg-[#24b47e]"
                            >
                              Start Project
                            </button>

                            <button
                              onClick={() => setShowDeclineProjectModal(true)}
                              title={`Decline for ${chatData?.client_user?.first_name}`}
                              className="flex gap-1 py-4 rounded-lg text-xs w-full font-bold items-center justify-center text-white bg-[#e24c4c]"
                            >
                              Declient Project
                            </button>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={`${styles.certified_section} mb-5 w-[350px] rounded-md  px-7`}
                  >
                    <div className={"space-y-3"}>
                      <div className="mt-5 flex-col flex items-center justify-center">
                        <p className="text-cyan-50">Negotiation</p>
                        <div className={styles.progress_border}></div>

                        <p className="text-[#858585]">Expert Approved</p>
                        <div className={styles.progress_border_pending}></div>

                        <p className="text-[#858585]">Project Start</p>
                        <div className={styles.progress_border_pending}></div>
                        <p className="text-[#858585]">Project Completed</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${styles.certified_section} w-[350px] rounded-md  px-7`}
                  >
                    <h1 className="md:text-sm lg:text-lg mt-3 text-white">
                      Why transact on DeskZone
                    </h1>

                    <div className={"space-y-3"}>
                      <div className="mt-5">
                        <div>
                          <div className="flex">
                            <div className="flex items-center">
                              <IconContext.Provider
                                value={{ color: "#24b47e", size: "2em" }}
                              >
                                <TiStarburst />
                              </IconContext.Provider>

                              <div className="-ml-6 mr-4">
                                <IconContext.Provider
                                  value={{ color: "#fff", size: "1em" }}
                                >
                                  <FaCheck />
                                </IconContext.Provider>
                              </div>
                            </div>

                            <div className="text-white  text-xs mt-[0.5rem]">
                              Payment protection
                            </div>
                          </div>

                          <div className="flex mt-2">
                            <div className="flex items-center">
                              <IconContext.Provider
                                value={{ color: "#24b47e", size: "2em" }}
                              >
                                <TiStarburst />
                              </IconContext.Provider>

                              <div className="-ml-6 mr-4">
                                <IconContext.Provider
                                  value={{ color: "#fff", size: "1em" }}
                                >
                                  <FaCheck />
                                </IconContext.Provider>
                              </div>
                            </div>

                            <div className="text-white text-xs mt-[0.5rem]">
                              Fraud prevention
                            </div>
                          </div>

                          <div className="flex mt-2">
                            <div className="flex items-center">
                              <IconContext.Provider
                                value={{ color: "#24b47e", size: "2em" }}
                              >
                                <TiStarburst />
                              </IconContext.Provider>

                              <div className="-ml-6 mr-4">
                                <IconContext.Provider
                                  value={{ color: "#fff", size: "1em" }}
                                >
                                  <FaCheck />
                                </IconContext.Provider>
                              </div>
                            </div>

                            <div className="text-white text-xs mt-[0.5rem]">
                              Advanced / Real-time customer support
                            </div>
                          </div>

                          <div className="mt-6 text-xs">
                            <Link
                              to=""
                              className="text-white mb-5 flex items-center justify-between"
                            >
                              <p>Visit Help Center</p>
                              <IconContext.Provider
                                value={{ color: "#fff", size: "1em" }}
                              >
                                <FaArrowRightLong />
                              </IconContext.Provider>
                            </Link>
                            <Link
                              to=""
                              className="text-white mb-5 flex items-center justify-between"
                            >
                              <p>Contact Center</p>
                              <IconContext.Provider
                                value={{ color: "#fff", size: "1em" }}
                              >
                                <FaArrowRightLong />
                              </IconContext.Provider>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Negotation;
