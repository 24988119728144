import { memo } from "react";
import styles from "../icons/icons.module.css";
import Btn from "./Btn";

interface VolumeProps {
  volume: number;
  onToggle: () => void;
  onSeek: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Volume: React.FC<VolumeProps> = ({ volume, onToggle, onSeek }) => {
  return (
    <div className="vp-volume">
      <Btn onClick={onToggle}>
        {volume > 0.7 && (
          <svg
            className={styles.player_icon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.58078 4.43141C10.0595 3.9832 10.4519 4.13528 10.4519 4.76929V19.2305C10.4519 19.8647 10.0595 20.0168 9.58078 19.5684L5.07457 15.3535H2.08696V8.64685H5.07457L9.58078 4.43141ZM12.3382 16.0001C12.1089 16.0001 11.8792 15.9185 11.7043 15.7546C11.3541 15.427 11.3541 14.896 11.7043 14.569C13.2184 13.1527 13.2184 10.8484 11.7043 9.43217C11.3541 9.10463 11.3541 8.57368 11.7043 8.24653C12.0544 7.91937 12.622 7.91937 12.9714 8.24653C15.1846 10.3171 15.1846 13.6852 12.9714 15.7553C12.7969 15.9178 12.5675 16.0001 12.3382 16.0001ZM15.5212 17.5808C15.2927 17.5808 15.0625 17.4991 14.8876 17.3353C14.5374 17.0076 14.5374 16.4767 14.8876 16.1495C17.3334 13.8617 17.3334 10.139 14.8876 7.85119C14.5374 7.52365 14.5374 6.9927 14.8876 6.66555C15.2378 6.33801 15.8055 6.33801 16.1547 6.66555C17.6791 8.08984 18.5182 9.98495 18.5182 12C18.5182 14.0155 17.6791 15.91 16.1555 17.3353C15.9807 17.4991 15.751 17.5808 15.5212 17.5808ZM18.7058 19.1618C18.476 19.1618 18.2467 19.0801 18.0714 18.9165C17.7217 18.5886 17.7217 18.058 18.0714 17.7309C19.7084 16.1992 20.6093 14.1644 20.6093 12C20.6093 9.83556 19.7084 7.80062 18.0714 6.26982C17.7217 5.94267 17.7217 5.41133 18.0714 5.08418C18.4216 4.75702 18.9896 4.75702 19.339 5.08418C21.3142 6.9314 22.4019 9.38812 22.4019 12C22.4019 14.6122 21.3142 17.0689 19.339 18.9157C19.1641 19.0798 18.9352 19.1618 18.7058 19.1618Z" />
          </svg>
        )}
        {volume <= 0.7 && volume > 0.3 && (
          <svg
            className={styles.player_icon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.58078 4.43141C10.0595 3.9832 10.4519 4.13528 10.4519 4.76929V19.2305C10.4519 19.8647 10.0595 20.0168 9.58078 19.5684L5.07417 15.3535H2.08696V8.64685H5.07417L9.58078 4.43141ZM12.3382 16.0001C12.1089 16.0001 11.8792 15.9185 11.7039 15.7546C11.3537 15.427 11.3537 14.896 11.7039 14.569C13.218 13.1527 13.218 10.8484 11.7039 9.43217C11.3537 9.10463 11.3537 8.57368 11.7039 8.24653C12.0541 7.91937 12.6216 7.91937 12.9714 8.24653C15.1846 10.3171 15.1846 13.6852 12.9714 15.7553C12.7969 15.9178 12.5676 16.0001 12.3382 16.0001ZM15.5216 17.5808C15.2919 17.5808 15.0625 17.4991 14.8872 17.3353C14.5374 17.0076 14.5374 16.4767 14.8872 16.1495C17.3334 13.8617 17.3334 10.139 14.8872 7.85119C14.5374 7.52365 14.5374 6.9927 14.8872 6.66555C15.2374 6.33801 15.805 6.33801 16.1547 6.66555C17.6787 8.08984 18.5178 9.98495 18.5178 12C18.5178 14.0155 17.6787 15.91 16.1551 17.3353C15.9804 17.4991 15.751 17.5808 15.5216 17.5808Z" />
          </svg>
        )}
        {volume <= 0.3 && volume > 0 && (
          <svg
            className={styles.player_icon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.58078 4.43141C10.0599 3.9832 10.4523 4.13528 10.4523 4.76929V19.2305C10.4523 19.8647 10.0599 20.0168 9.58078 19.5684L5.07457 15.3535H2.08696V8.64685H5.07457L9.58078 4.43141ZM12.3386 16.0001C12.1093 16.0001 11.8796 15.9185 11.7043 15.7546C11.3541 15.427 11.3541 14.896 11.7043 14.569C13.2184 13.1527 13.2184 10.8484 11.7043 9.43217C11.3541 9.10463 11.3541 8.57368 11.7043 8.24653C12.0544 7.91937 12.622 7.91937 12.9718 8.24653C15.1846 10.3171 15.1846 13.6852 12.9718 15.7553C12.7969 15.9178 12.568 16.0001 12.3386 16.0001Z" />
          </svg>
        )}
        {volume === 0 && (
          <svg
            className={styles.player_icon}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20.0115 13.8711V15.3536H18.427L16.4268 13.4827L14.4265 15.3536H12.8416V13.8711L14.8418 12.0001L12.8416 10.1291V8.64697H14.4265L16.4268 10.518L18.427 8.64697H20.0115V10.1291L18.0113 12.0001L20.0115 13.8711ZM9.5804 4.43151C10.0596 3.98331 10.4515 4.135 10.4515 4.76939V19.2309C10.4515 19.8649 10.0596 20.0165 9.5804 19.5687L5.07418 15.3536H2.08696V8.64697H5.07418L9.5804 4.43151Z" />
          </svg>
        )}
      </Btn>
      {/* <div className="vp-volume__range">
        <div className="vp-volume__range--background" />
        <div
          className="vp-volume__range--current"
          style={{ width: `${volume * 100}%` }}
        >
          <div className="vp-volume__range--current__thumb" />
        </div>
        <input
          className="vp-volume__range--seek"
          type="range"
          value={volume}
          max="1"
          step="0.05"
          onChange={onSeek}
        />
      </div> */}
    </div>
  );
};

export default memo(Volume);
