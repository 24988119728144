import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ProfileLoader from "../common/ProfileLoader";
import EditServiceModal from "./editService/EditServiceModal";
import Spinner from "../../commons/Spinner";
import { FaRegEdit } from "react-icons/fa";
import { GiSightDisabled } from "react-icons/gi";
import { IconContext } from "react-icons";
import WishlistVideoPreview from "../common/WishlistVideoPreview";
import { useGetLoggedInExpertServicesMutation } from "../../../lib/apis/serviceApis";
import styles from "../Profile.module.css";

const Products: React.FC = () => {
  const [getLoggedInExpertServices, { data, isLoading, error }] =
    useGetLoggedInExpertServicesMutation();

  const [editService, setEditService] = useState(false);

  useEffect(() => {
    getLoggedInExpertServices(null);
  }, []);

  return (
    <>
      <EditServiceModal open={editService} />

      <main className="flex w-full flex-grow flex-col lg:flex-grow-0 lg:bg-light lg:px-12 lg:py-8 lg:dark:bg-dark-250">
        {isLoading ? (
          <ProfileLoader />
        ) : (
          <div
            className="flex min-h-full flex-grow flex-col"
            style={{ opacity: "1", transform: "none" }}
          >
            <div className="pb-5 lg:pl-0">
              <h1 className="mb-3 text-15px font-medium text-cyan-50">
                My Services
                <span className="ml-1 text-light-900">
                  (
                  {data?.data[0]?.services.length > 0
                    ? data?.data[0]?.services.length
                    : 0}
                  )
                </span>
              </h1>
              {
                <p className={`text-[#8a8989] -mt-2 text-xs`}>
                  {data?.data[0]?.business_name}
                </p>
              }
            </div>
            {data &&
              data?.data[0]?.services?.length > 0 &&
              data?.data[0]?.services?.map((service: any) => {
                return (
                  <AnimatePresence>
                    <motion.div
                      exit={{ opacity: 0, scale: 1.1 }}
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      className={`${styles.wishlist_card} sm:flex sm:items-start border-b  mb-5  pt-4 px-4  border-dark-400 sm:gap-5`}
                    >
                      <div className="relative  w-full flex-shrink-0 border border-light-300 dark:border-0 sm:w-32 md:w-36">
                        <WishlistVideoPreview
                          serviceVideo={service?.explainer_video}
                          servicePoster={service?.thumbnail}
                          id={service?.id}
                          slug={service?.slug}
                        />
                      </div>
                      <div className="flex-start flex flex-1 flex-row justify-between items-center gap-3 sm:flex-row sm:justify-between md:gap-0">
                        <div className="pb-4 dark:border-dark-600">
                          <a
                            className="font-medium text-white text-xs lsm:text-sm sm:mb-1.5"
                            href={`/services/${service?.slug}`}
                          >
                            {service?.title}
                          </a>
                          <Link
                            to={`/experts/${data?.data[0]?.business_name}/services?eid=${data?.data[0]?.id}`}
                          >
                            <p className="pt-0.5 text-xs  font-medium text-gray-400 sm:pt-0">
                              {data?.data[0]?.business_name}
                            </p>
                          </Link>
                          <div className="mt-2 sm:mt-3">
                            <span
                              style={{ color: "#24b47e" }}
                              className="py-1 text-sm font-semibold text-brand-dark"
                            >
                              ${service?.lowest_acceptable_amount}
                            </span>
                          </div>
                        </div>
                        <div className="flex  sm:flex-row sm:shrink-0 sm:items-center sm:gap-2.5 xs:pb-4 xs:pt-8 md:flex-nowrap md:gap-3.5 lg:gap-4">
                          <button
                            className={`${styles.delete_btn} transition-fill-colors flex items-center justify-center gap-1 opacity-100 min-h-[40px] rounded  px-2 text-xs bg-brand text-white hover:bg-brand-dark focus:bg-brand-dark relative pointer-events-auto cursor-pointer w-full flex-1 shrink-0  border border-light-200 bg-brand text-brand hover:bg-transparent hover:text-light-200 dark:border-dark-600 dark:bg-dark-250 dark:text-brand dark:hover:text-brand-dark`}
                          >
                            <IconContext.Provider
                              value={{ color: "#e24c4c", size: "1.3em" }}
                            >
                              <GiSightDisabled />
                            </IconContext.Provider>
                            Disable
                          </button>
                          <button
                            className={`${styles.update_btn} transition-fill-colors flex items-center justify-center gap-1 opacity-100 min-h-[40px] rounded  px-2 text-xs bg-brand text-white hover:bg-brand-dark focus:bg-brand-dark relative pointer-events-auto cursor-pointer w-full flex-1 shrink-0  border border-light-200 bg-brand text-brand hover:bg-transparent hover:text-light-200 dark:border-dark-600 dark:bg-dark-250 dark:text-brand dark:hover:text-brand-dark`}
                          >
                            <IconContext.Provider
                              value={{ color: "white", size: "1.3em" }}
                            >
                              <FaRegEdit />
                            </IconContext.Provider>
                            Edit
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                );
              })}
          </div>
        )}
      </main>
    </>
  );
};

export default Products;
