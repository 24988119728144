import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetUnreadMessageCountMutation } from "../lib/apis/messageApis";
import Icon from "../components/commons/Icon";
import styles from "./SideNavigationBar.module.css";

const SideNavigationBar: React.FC = () => {
  const { pathname } = useLocation();

  const [getUnreadMessageCount, { data, error }] =
    useGetUnreadMessageCountMutation();

  const { user } = useSelector((state: any) => state.userState);
  const { messageCount } = useSelector((state: any) => state.messageCountState);

  useEffect(() => {
    if (user) {
      getUnreadMessageCount(null);
    }
  }, [user]);

  return (
    <section
      className="fixed top-14 left-0 w-[80px] h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div className={`h-full overflow-y-scroll  ${styles.side_nav}`}>
        <ul className="space-y-2  mt-8 flex flex-col  font-medium">
          <li
            className={`${
              pathname.split(" ")[0] === "/" && styles.active
            } text-center hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-2 text-gray-900  dark:text-white`}
          >
            <Link to="/" title="HOME" className="group">
              <Icon
                iconClasses={
                  "fa-solid fa-house  text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                }
              />
              <span className="text-xs block">Home</span>
            </Link>
          </li>
          <li className="text-center hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-2 text-gray-900  dark:text-white ">
            <Link to="#" title="EXPLORE" className="group">
              <Icon
                iconClasses={
                  "fa-solid fa-compass w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                }
              />
              <span className="text-xs block">Explore</span>
            </Link>
          </li>
          <li className="text-center hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-2 text-gray-900  dark:text-white ">
            <Link to="#" title="POPULAR SERVICES" className="group">
              <Icon
                iconClasses={
                  "fa-solid fa-fire w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                }
              />
              <span className="text-xs block">Popular Services</span>
            </Link>
          </li>
          <li
            className={`${
              pathname.split("/")[1] === "experts" && styles.active
            } text-center  hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-2 text-gray-900  dark:text-white`}
          >
            <Link to="/experts" title="TOP EXPERTS" className="group">
              <Icon
                iconClasses={
                  "fa-solid fa-user-group w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                }
              />
              <span className="text-xs block">Top Experts</span>
            </Link>
          </li>

          {user && user?.role === "expert" && (
            <li
              className={`${
                pathname.split("/")[1] === "experts" && styles.active
              } text-center  hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-2 text-gray-900  dark:text-white`}
            >
              <Link
                to={`/expert/profile/services`}
                title="My Services"
                className="group"
              >
                <Icon
                  iconClasses={
                    "fa-brands fa-usps w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  }
                />
                <span className="text-xs block">My Services</span>
              </Link>
            </li>
          )}
          {user &&
            (user?.role === "expert" ? (
              <li className="text-center hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-2 text-gray-900  dark:text-white ">
                <Link
                  to="/expert/profile/messages"
                  title="MY MESSAGES"
                  className="group flex flex-col items-center justify-center"
                >
                  {messageCount > 0 && (
                    <span className="inline-flex items-center justify-center -mb-3 ml-auto w-5 h-5 text-[10px]  text-white  bg-red-500  border-white rounded-full dark:border-gray-900">
                      {messageCount}
                    </span>
                  )}
                  <Icon
                    iconClasses={
                      "fa-solid fa-message w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    }
                  />

                  <span className="text-xs block">Messages</span>
                </Link>
              </li>
            ) : (
              <li className="text-center hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-2 text-gray-900  dark:text-white ">
                <Link
                  to="/client/profile/messages"
                  title="MY MESSAGES"
                  className="group flex flex-col items-center justify-center"
                >
                  {messageCount > 0 && (
                    <span className="inline-flex items-center justify-center -mb-3 ml-auto w-5 h-5 text-[10px]  text-white  bg-red-500  border-white rounded-full dark:border-gray-900">
                      {messageCount}
                    </span>
                  )}
                  <Icon
                    iconClasses={
                      "fa-solid fa-message w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    }
                  />

                  <span className="text-xs block">Messages</span>
                </Link>
              </li>
            ))}
          <li
            className={`${styles.demacation} text-center hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-2 text-gray-900  dark:text-white`}
          >
            <a href="#" title="CONTACT US" className="group">
              <Icon
                iconClasses={
                  "fa-brands fa-telegram w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                }
              />
              <span className="text-xs block">Contact Support</span>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SideNavigationBar;
