interface SEOData {
  title?: string;
  metaDescription?: string;
}

export const SEO = (data: SEOData = {}) => {
  data.title = data.title || "DeskZone";
  data.metaDescription =
    data.metaDescription ||
    "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!";

  document.title = data.title;
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", data.metaDescription);
  }
};
