import { useEffect } from "react";
import ButtonLoader from "../../../commons/ButtonLoader";
import styles from "./EditServiceModal.module.css";

const EditServiceModal: React.FC<{
  message?: string;
  open?: boolean;
  buttonText?: string;
  onCloseModal?: () => void;
  isLoading?: boolean;
  onCancelOperation?: () => void;
  warningMessage?: string;
}> = ({
  message,
  open,
  onCloseModal,
  buttonText,
  isLoading,
  onCancelOperation,
  warningMessage,
}) => {
  useEffect(() => {
    // Add or remove overflow hidden on <html> tag
    if (open) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }

    // Cleanup function to reset overflow on unmount
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [open]);

  return (
    <div
      id="successModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${styles.modal_container} ${
        open ? "flex" : "hidden"
      }   overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-full`}
    >
      <div className="relative p-4 w-full flex justify-center items-center  max-w-96 h-full md:h-auto">
        <div className="relative p-4 text-center w-full rounded-lg shadow bg-gray-800 sm:p-5">
          {/* <span className="block text-xs font-semibold text-red-500">
            {warningMessage}
          </span> */}

          <div className="flex gap-10 mt-7 items-center justify-center">
            <button
              data-modal-toggle="successModal"
              onClick={onCloseModal}
              type="button"
              className={`${styles.warning_modal_btn_danger}  py-2 px-3 text-xs font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900`}
            >
              {isLoading ? <ButtonLoader /> : buttonText}
            </button>

            <button
              data-modal-toggle="successModal"
              onClick={onCancelOperation}
              type="button"
              className={`${styles.warning_modal_btn} py-2 px-3 text-xs font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditServiceModal;
