import Icon from "../../commons/Icon";
import styles from "./StarRatings.module.css";

const StarRatings: React.FC = () => {
  return (
    <div className={`flex gap-3 items-center ${styles.ratings_container}`}>
      <div className="mt-10 pr-5 md:pr-10">
        <div className="bg-[#23B47E] shrink-0 items-center rounded-full text-white px-3 lg:px-6 py-2 sm:text-lg lg:text-xl font-semibold mb-2 flex">
          <h1 className="font-semibold text-xl lg:text-3xl text-center">4.0</h1>

          <div className="text-md lg:text-xl ml-2">
            <Icon iconClasses={`fa-solid fa-star`} />
          </div>
        </div>
        <p className="text-gray-300 text-sm text-center">3 Ratings</p>
      </div>

      <div
        className={`mt-5 -mb-5 ${styles.rating_container} border-l-[1px] border-gray-700 pl-5 md:pl-14`}
      >
        <div
          className={`${styles.rating_cat} w-[200px] md:w-[500px] lg:w-[600px]`}
        >
          <div className="text-sm flex">
            <div>5</div>
            <div className="ml-1 text-[9.5px] text-gray-300">
              <Icon iconClasses={`fa-solid fa-star`} />
            </div>
          </div>

          <div
            className={` w-full md:w-1/3 ${styles.progress_bg} ml-3 rounded-full h-1.5`}
          >
            <div
              className={`${styles.progress} h-1.5 rounded-full`}
              style={{ width: "35%" }}
            ></div>
          </div>
          <p className="ml-1 text-xs">1</p>
        </div>

        <div
          className={`${styles.rating_cat} w-[200px] md:w-[500px] lg:w-[600px] mt-2`}
        >
          <div className="text-sm flex">
            <div>4</div>
            <div className="ml-1 text-[9.5px] text-gray-300">
              <Icon iconClasses={`fa-solid fa-star`} />
            </div>
          </div>

          <div
            className={` w-full md:w-1/3 ${styles.progress_bg} ml-3 rounded-full h-1.5`}
          >
            <div
              className={`${styles.progress} h-1.5 rounded-full`}
              style={{ width: "35%" }}
            ></div>
          </div>
          <p className="ml-1 text-xs">1</p>
        </div>

        <div
          className={`${styles.rating_cat} w-[200px] md:w-[500px] lg:w-[600px] mt-2`}
        >
          <div className="text-sm flex">
            <div>3</div>
            <div className="ml-1 text-[9.5px] text-gray-300">
              <Icon iconClasses={`fa-solid fa-star`} />
            </div>
          </div>

          <div
            className={` w-full md:w-1/3 ${styles.progress_bg} ml-3 rounded-full h-1.5`}
          >
            <div
              className={`${styles.progress} h-1.5 rounded-full`}
              style={{ width: "35%" }}
            ></div>
          </div>
          <p className="ml-1 text-xs">1</p>
        </div>

        <div
          className={`${styles.rating_cat} w-[200px] md:w-[500px] lg:w-[600px] mt-2`}
        >
          <div className="text-sm flex">
            <div>2</div>
            <div className="ml-1 text-[9.5px] text-gray-300">
              <Icon iconClasses={`fa-solid fa-star`} />
            </div>
          </div>

          <div
            className={` w-full md:w-1/3 ${styles.progress_bg} ml-3 rounded-full h-1.5`}
          >
            <div
              className={`${styles.progress} h-1.5 rounded-full`}
              style={{ width: "35%" }}
            ></div>
          </div>
          <p className="ml-1 text-xs">1</p>
        </div>

        <div
          className={`${styles.rating_cat} w-[200px] md:w-[500px] lg:w-[600px] mt-2`}
        >
          <div className="text-sm flex">
            <div>1</div>
            <div className="ml-[6px] text-[9.5px] text-gray-300">
              <Icon iconClasses={`fa-solid fa-star`} />
            </div>
          </div>

          <div
            className={` w-full md:w-1/3 ${styles.progress_bg} ml-3 rounded-full h-1.5`}
          >
            <div
              className={`${styles.progress} h-1.5 rounded-full`}
              style={{ width: "35%" }}
            ></div>
          </div>
          <p className="ml-1 text-xs">1</p>
        </div>
      </div>
    </div>
  );
};

export default StarRatings;
