import { memo } from "react";
import styles from "../icons/icons.module.css";

import Btn from "./Btn";

interface FullscreenProps {
  isFullscreen: boolean;
  onToggle: () => void;
}

const Fullscreen: React.FC<FullscreenProps> = ({ isFullscreen, onToggle }) => (
  <Btn
    label={isFullscreen ? "Fullscreen Off" : "Fullscreen"}
    onClick={onToggle}
  >
    {!isFullscreen && (
      <svg
        className={styles.player_icon}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.016 5.01601H18.985V9.98501H17.016V6.98501H14.016V5.01601V5.01601ZM17.016 17.016V14.016H18.985V18.985H14.016V17.016H17.016ZM5.016 9.98401V5.01501H9.985V6.98401H6.985V9.98401H5.016V9.98401ZM6.984 14.016V17.016H9.984V18.985H5.015V14.016H6.984V14.016Z" />
      </svg>
    )}
    {isFullscreen && (
      <svg
        className={styles.player_icon}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.984 8.01599H18.984V9.98499H14.015V5.01599H15.984V8.01599V8.01599ZM14.016 18.984V14.015H18.985V15.984H15.985V18.984H14.016ZM8.016 8.01599V5.01599H9.985V9.98499H5.016V8.01599H8.016V8.01599ZM5.016 15.984V14.015H9.985V18.984H8.016V15.984H5.016V15.984Z" />
      </svg>
    )}
  </Btn>
);

export default memo(Fullscreen);
