import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../../helpers/firebase";
import { setArchiveMessages } from "./redux/archiveMessageSlice";
import { updateMessageCount } from "./redux/messageCountSlice";
import { updateChatData } from "./redux/chatSessionSlice";
import { setInboxes } from "./redux/inboxSlice";

let baseUrl: string;

if (process.env.NODE_ENV === "development") {
  baseUrl = process.env.REACT_APP_DEV_API_BASE_URL || "";
} else {
  baseUrl =
    process.env.REACT_APP_PROD_API_BASE_URL || "https://api.deskzone.pw/api";
}

export const messageApis = createApi({
  reducerPath: "messageApis",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
      const token = await getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllInbox: builder.mutation({
      query: () => ({
        url: "/chat-sessions/my-chats",
        method: "GET",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInboxes(data?.data));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    getChatMessages: builder.mutation({
      query: (payload) => ({
        url: `/chat-sessions/${payload}`,
        method: "GET",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateChatData(data?.data));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    getMoreChatMessages: builder.mutation({
      query: (payload) => ({
        url: `/chat-sessions/${payload}`,
        method: "GET",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateChatData(data?.data));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    expertStartProject: builder.mutation({
      query: (payload) => ({
        url: `/expert/expert-agree-to-start/${payload}`,
        method: "PATCH",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          const messageId = localStorage.getItem("m_id");
          if (messageId) {
            await dispatch(
              messageApis.endpoints.getChatMessages.initiate(messageId)
            );
          }
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    getAllArchiveMessages: builder.mutation({
      query: () => ({
        url: `/chat-sessions/my-archived-chat`,
        method: "GET",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setArchiveMessages(data?.data));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    markMessageAsRead: builder.mutation({
      query: (payload) => ({
        url: `/chat-sessions/mark-as-read`,
        method: "PATCH",
        body: { chatSessionIds: payload },
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(messageApis.endpoints.getAllInbox.initiate(null));
          await dispatch(
            messageApis.endpoints.getUnreadMessageCount.initiate(null)
          );
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    markMessageAsUnRead: builder.mutation({
      query: (payload) => ({
        url: `/chat-sessions/mark-unread`,
        method: "PATCH",
        body: { chatSessionIds: payload },
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(messageApis.endpoints.getAllInbox.initiate(null));
          await dispatch(
            messageApis.endpoints.getUnreadMessageCount.initiate(null)
          );
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    archiveMessage: builder.mutation({
      query: (payload) => ({
        url: `/chat-sessions/archive-chat`,
        method: "PATCH",
        body: { chatSessionIds: payload },
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(
            messageApis.endpoints.getAllArchiveMessages.initiate(null)
          );

          await dispatch(messageApis.endpoints.getAllInbox.initiate(null));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    unArchiveMessage: builder.mutation({
      query: (payload) => ({
        url: `/chat-sessions/unarchive-chat`,
        method: "PATCH",
        body: { chatSessionIds: payload },
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(
            messageApis.endpoints.getAllArchiveMessages.initiate(null)
          );

          await dispatch(messageApis.endpoints.getAllInbox.initiate(null));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    getUnreadMessageCount: builder.mutation({
      query: () => ({
        url: "/chat-sessions/unread-messages-count",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(updateMessageCount(data?.data?.countUnread));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useGetAllInboxMutation,
  useGetChatMessagesMutation,
  useMarkMessageAsReadMutation,
  useMarkMessageAsUnReadMutation,
  useArchiveMessageMutation,
  useUnArchiveMessageMutation,
  useGetAllArchiveMessagesMutation,
  useGetUnreadMessageCountMutation,
  useExpertStartProjectMutation,
  useGetMoreChatMessagesMutation,
} = messageApis;
