import { memo } from "react";
import styles from "../icons/icons.module.css";
import Btn from "./Btn";

interface PlaybackProps {
  isPlaying: boolean;
  onToggle: () => void;
}

const Playback: React.FC<PlaybackProps> = ({ isPlaying, onToggle }) => (
  <Btn label={isPlaying ? "Pause" : "Play"} onClick={onToggle}>
    {isPlaying ? (
      <svg
        className={styles.player_icon}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.7417 0.00171412H22.5714V23.9486H15.7417V0.00171412ZM2 23.9469V0H8.82971V23.9469H2Z" />
      </svg>
    ) : (
      <svg
        className={styles.player_icon}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3 0.00171416L21.804 11.9743L3 23.9469V0V0.00171416Z" />
      </svg>
    )}
  </Btn>
);

export default memo(Playback);
