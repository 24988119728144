import React, { useState } from "react";
import styles from "./VideoPlayOptions.module.css";

interface Option {
  value: string;
  label: string;
}

interface StyledDropdownProps {
  options: Option[];
  defaultValue?: string;
  onChange: (value: string) => void;
}

const VideoPlayerOptions: React.FC<StyledDropdownProps> = ({
  options,
  defaultValue = "",
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`${styles.dropdown} flex mr-2`}>
      <button
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
        className={`${styles.menu_btn} ml-auto w-8  h-8 px-2 py-2  text-white text-sm font-medium`}
      >
        <svg
          className="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 4 15"
        >
          <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
        </svg>
      </button>

      <ul
        className={`${
          isOpen
            ? `${styles.show_dropdown} py-2  -mb-12  text-white text-right pr-3 -mr-7 text-sm absolute w-32`
            : styles.dropdown_menu
        }`}
      >
        <li className={styles.option_items}>
          <a href="#">Option 1</a>
        </li>
        <li className={styles.option_items}>
          <a href="#">Option 2</a>
        </li>
        <li className={styles.option_items}>
          <a href="#">Option 3</a>
        </li>
      </ul>
    </div>
  );
};

export default VideoPlayerOptions;
