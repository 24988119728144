import { useSelector } from "react-redux";
import {
  useAccordion,
  useAccordionProvider,
  AccordionProvider,
} from "@szhsin/react-accordion";
import AccordionItem from "./AccordionItem";

import styles from "./accordion.module.css";

export const TopExpertsHelp = () => {
  const { topExpertDetails } = useSelector(
    (state: any) => state.topExpertDetailsState
  );

  const providerValue = useAccordionProvider({
    // Omit these two options if you don't want to implement any transition
    transition: true,
    transitionTimeout: 250,
  });
  const { accordionProps } = useAccordion();

  console.log(topExpertDetails.faq);

  return (
    <div className="mt-10 w-11/12 mx-auto">
      <div className="mb-5">
        <p>FAQs</p>
      </div>
      <AccordionProvider value={providerValue}>
        {topExpertDetails &&
          topExpertDetails?.faq &&
          topExpertDetails?.faq.length > 0 &&
          topExpertDetails?.faq.map((expertFaq: any) => {
            return (
              <div className={styles.accordion} {...accordionProps}>
                <AccordionItem header={expertFaq?.question} initialEntered>
                  {expertFaq?.answer}
                </AccordionItem>
              </div>
            );
          })}
      </AccordionProvider>
    </div>
  );
};

export default TopExpertsHelp;
