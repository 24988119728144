import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topExpertDetails: null,
};

export const topExpertDetailsSlice = createSlice({
  initialState,
  name: "topExpertDetailsState",
  reducers: {
    setTopExpertDetails: (state, action) => {
      state.topExpertDetails = action.payload;
    },
  },
});

export default topExpertDetailsSlice.reducer;

export const { setTopExpertDetails } = topExpertDetailsSlice.actions;
