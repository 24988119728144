import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceCategories: null,
};

export const serviceCategorySlice = createSlice({
  initialState,
  name: "serviceCategoryState",
  reducers: {
    setCategories: (state, action) => {
      state.serviceCategories = action.payload;
    },
  },
});

export default serviceCategorySlice.reducer;

export const { setCategories } = serviceCategorySlice.actions;
