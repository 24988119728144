import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inboxes: null,
};

export const inboxSlice = createSlice({
  initialState,
  name: "inboxState",
  reducers: {
    setInboxes: (state, action) => {
      state.inboxes = action.payload;
    },
  },
});

export default inboxSlice.reducer;

export const { setInboxes } = inboxSlice.actions;
