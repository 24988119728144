import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userApi } from "./userApi";
import { setTopExpertDetails } from "./redux/topExpertDetailsSlice";
import { setExpertDetails } from "./redux/loggedInExpertSlice";
import { getToken } from "../../helpers/firebase";

let baseUrl: string;

if (process.env.NODE_ENV === "development") {
  baseUrl = process.env.REACT_APP_DEV_API_BASE_URL || "";
} else {
  baseUrl =
    process.env.REACT_APP_PROD_API_BASE_URL || "https://api.deskzone.pw/api";
}

export const expertApis = createApi({
  reducerPath: "expertApis",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
      const token = await getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    createExpert: builder.mutation({
      query: (payload) => ({
        url: "/expert/create-expert",
        method: "POST",
        body: payload,
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const token = await getToken();
          if (token) {
            await dispatch(userApi.endpoints.getCurrentUser.initiate(token));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),

    getLoggedInExperts: builder.mutation({
      query: () => ({
        url: "/expert/profile",
        method: "GET",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setExpertDetails(data?.data));
        } catch (error) {
          // console.log(error);
        }
      },
    }),

    createExpertFaq: builder.mutation({
      query: (payload) => ({
        url: "/expert/faq",
        method: "POST",
        body: payload,
      }),
    }),
    updateSocialLinks: builder.mutation({
      query: (payload) => ({
        url: "/expert/social-media",
        method: "PATCH",
        body: payload,
      }),
    }),

    updateExpertProfile: builder.mutation({
      query: (payload) => ({
        url: "expert/update-expert",
        method: "PATCH",
        body: payload,
      }),
    }),

    getExpertFaqs: builder.mutation({
      query: () => ({
        url: "/expert/faqs",
        method: "GET",
      }),
    }),

    getTopExperts: builder.mutation({
      query: () => ({
        url: "/expert/top-experts",
        method: "GET",
      }),
    }),

    getTopExpertDetails: builder.mutation({
      query: (payload) => ({
        url: `/expert/get-profile/${payload}`,
        method: "GET",
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(dispatch(setTopExpertDetails(data?.data)));
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  }),
});

export const {
  useCreateExpertFaqMutation,
  useGetExpertFaqsMutation,
  useUpdateSocialLinksMutation,
  useCreateExpertMutation,
  useGetLoggedInExpertsMutation,
  useGetTopExpertsMutation,
  useGetTopExpertDetailsMutation,
  useUpdateExpertProfileMutation,
} = expertApis;
