import { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { base64ToArrayBuffer } from "../../helpers/files";
import { useSelector } from "react-redux";
import ErrorModal from "../commons/ErrorModal";
import { ChatContext } from "../../context/chat-context";
import Icon from "../commons/Icon";
import EmojiPicker, { Theme, EmojiStyle } from "emoji-picker-react";

import styles from "./Negotiation.module.css";

interface FileInputEvent extends React.ChangeEvent<HTMLInputElement> {}

interface OnLoadFn {
  (dataURL: string | ArrayBuffer | null, fileName: string): void;
}

const ChatInput: React.FC<{
  onCloseEmoji: () => void;
  openEmoji: boolean;
  excludedRef?: any;
}> = ({ onCloseEmoji, openEmoji, excludedRef }) => {
  const [value, setValue] = useState("");
  const [filePrev, setFilePrev] = useState({ file: "", type: "" });
  const [newFile, setNewFile] = useState<{
    file: string | ArrayBuffer | null | File;
    fileName: string;
  }>();

  const [sizeInKb, setSizeInKb] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { user } = useSelector((state: any) => state.userState);

  const focusInput = useRef<HTMLTextAreaElement>(null);

  const params = useParams();

  const { messageId } = params;

  const chatCtx = useContext(ChatContext);

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const textarea = event.target;
    textarea.style.height = "auto"; // Reset height to calculate the new height
    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content
    setValue(textarea.value);
  };

  const onLoadFn: OnLoadFn = (dataURL, fileName) => {
    if (typeof dataURL === "string") {
      const type = atob(btoa(dataURL)).split(":")[1].split(";")[0];

      setFilePrev({ file: dataURL, type });
      // if (
      //   type !== "image/png" &&
      //   type !== "image/jpg" &&
      //   type !== "image/jpeg"
      // ) {
      //   // setPrevImage("");
      //   return;
      // }

      const result = base64ToArrayBuffer(dataURL, type);

      setNewFile({ file: result, fileName });
    }
  };

  const onSelectFile = (event: FileInputEvent): void => {
    if (event.target.files && event.target.files.length > 0) {
      const fileName = event.target.files[0].name;
      const fileSizeInBytes = event.target.files[0].size; // File size in bytes
      const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2); // Convert to KB
      setSizeInKb(fileSizeInKB);
      const reader = new FileReader();
      reader.addEventListener("load", () => onLoadFn(reader.result, fileName));
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const sendMessageHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (Number(sizeInKb) > 500) return setShowErrorModal(true);

    if ((!value || value.trim().length === 0) && !filePrev.file) return;

    if (messageId) {
      if (newFile) {
        chatCtx.sendMessage(
          messageId,
          value,
          user?.id,
          newFile.file,
          newFile.fileName
        );

        setValue("");
        setFilePrev({ file: "", type: "" });

        focusInput?.current?.focus();
      } else {
        chatCtx.sendMessage(messageId, value, user?.id, null);

        setValue("");

        focusInput?.current?.focus();
      }
    }
  };

  useEffect(() => {
    if (chatCtx?.onlineUsers?.users?.length > 1) {
      const otherUser = chatCtx?.onlineUsers?.users?.find(
        (roomUsers: { userId: string }) => roomUsers.userId !== user?.id
      ) as { userId: string } | undefined;

      if (otherUser) {
        chatCtx.markMessageAsRead(
          chatCtx.onlineUsers.roomId,
          otherUser?.userId
        );
      }
    }
  }, [chatCtx.onlineUsers, sendMessageHandler]);

  return (
    <>
      <ErrorModal
        open={showErrorModal}
        buttonText="Close"
        message={`File size should not be more than 500kb`}
        onCloseModal={() => setShowErrorModal(false)}
      />

      {openEmoji && (
        <div
          className="relative -mt-[350px] w-1/2 ml-0 md:ml-3 mb-1"
          ref={excludedRef}
        >
          <EmojiPicker
            open={openEmoji}
            onEmojiClick={(emoji: any) =>
              setValue((prev) => prev + emoji.emoji)
            }
            theme={Theme.DARK}
            emojiStyle={EmojiStyle.NATIVE}
            searchDisabled={true}
            previewConfig={{
              showPreview: false,
            }}
            height="350px"
          />
        </div>
      )}

      {filePrev?.file && (
        <div className="relative flex flex-col -mt-[150px] ml-0 md:ml-3 rounded-md mb-1 bg-[#333] w-64">
          <div className="ml-2">
            <Icon
              iconClasses="fa-regular  z-50 cursor-pointer text-white fa-trash-can"
              onClickIcon={() => setFilePrev({ file: "", type: "" })}
            />
          </div>
          {filePrev.type.split("/")[0] === "image" && (
            <img
              src={filePrev?.file}
              alt="preview"
              className="h-[150px] w-full mx-auto"
            />
          )}

          {filePrev.type.split("/")[0] === "video" && (
            <video
              controls
              controlsList="nodownload noplaybackrate nofullscreen"
              disablePictureInPicture
              className="h-[150px] w-full mx-auto "
              //  ref={videoRef}
              src={filePrev.file}
            />
          )}

          {filePrev.type.split("/")[1] === "pdf" && (
            <div>
              <iframe src={filePrev.file} width="100%" height="150px" />
            </div>
          )}
        </div>
      )}
      <form onSubmit={sendMessageHandler}>
        <label htmlFor="chat" className="sr-only">
          Type a message
        </label>
        <div
          className={`${styles.chat_input_container} flex items-end py-2 px-3`}
        >
          <button
            type="button"
            className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
          >
            <label
              htmlFor="logo"
              className="flex items-center justify-center w-full cursor-pointer"
            >
              <input
                id="logo"
                type="file"
                className="hidden"
                accept="image/*, video/*, application/pdf"
                onInput={onSelectFile}
              />
              <svg
                fill="currentColor"
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z" />
              </svg>
            </label>
          </button>
          <button
            type="button"
            onClick={onCloseEmoji}
            className="p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <textarea
            id="chat"
            rows={1}
            onChange={handleInput}
            // onFocus={onCloseEmoji}
            value={value}
            className={`block p-2.5 w-full text-sm bg-[transparent] text-white overflow-hidden ${styles.chat_input}`}
            placeholder="Your message..."
            ref={focusInput}
          ></textarea>
          <button
            type="submit"
            className="inline-flex justify-center p-2 text-[#24b47e] rounded-full cursor-pointer"
          >
            <svg
              className="w-6 h-6 rotate-90"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button>
        </div>
      </form>
    </>
  );
};

export default ChatInput;
