import imageCompression from "browser-image-compression";
import { fileTypeFromBuffer } from "file-type";
const { PDFDocument } = require("pdf-lib");

export const convertBase64File = async (thumbnail: string) => {
  const base64Data = thumbnail.split(",")[1];

  // Convert to binary data
  const binaryData = atob(base64Data);

  // Create an array of 8-bit unsigned integers
  const byteNumbers = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteNumbers[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the byte array
  const blob = new Blob([byteNumbers], { type: "image/png" });

  // Optional: Create a downloadable file
  const file = new File([blob], "output.png", { type: "image/png" });

  try {
    // Options for compression
    const options = {
      maxSizeMB: 0.5, // Maximum size in MB
      maxWidthOrHeight: 1920, // Max width or height
      useWebWorker: true,
    };

    // Compress the file
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Error compressing the file:", error);
  }
};

export const convertBlobImage = async (blogImage: string) => {
  const response = await fetch(blogImage);
  const blob = await response.blob();

  const file = new File([blob], "image.jpg", { type: blob.type });
  return file;
};

export const getFileType = (file: string) => {
  const imageTypes = [
    ".image",
    ".jpeg",
    ".png",
    ".jpg",
    ".gif",
    ".svg",
    ".webp",
  ];
  const videoTypes = [".video", ".mp4", ".webm", ".mov"];
  const type = atob(btoa(file)).split(":")[1].split(";")[0];

  if (imageTypes.includes(type)) return "image";

  if (videoTypes.includes(type)) return "video";

  // const match = file.match(/^data:(.+?);base64,/);
  // return match ? match[1] : ""; // Returns file type or null if not found
};

export const getPdfInfo = async (file: string) => {
  try {
    const cleanedBase64 = file
      .replace(/^data:application\/pdf;base64,/, "")
      .replace(/\s/g, "");
    const paddedBase64 = cleanedBase64.padEnd(
      Math.ceil(cleanedBase64.length / 4) * 4,
      "="
    );

    // Decode Base64 to binary
    const binaryString = atob(paddedBase64);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Load PDF document
    const pdfDoc = await PDFDocument.load(byteArray);

    // Get number of pages
    const numPages = pdfDoc.getPageCount();

    // Get page dimensions (assuming the first page)
    // const pageDimensions = pdfDoc.getPage(0).getSize();

    // Get file size
    const fileSize = byteArray.byteLength;

    return { numPages, fileSize };
  } catch (error) {
    console.error("Error getting pdf info:", error);
  }
};

export const generateRandomNameForFile = () => {
  const randomString = Math.random().toString(36).substring(7);
  return randomString;
};

export const compressImageFile = async (file: File) => {
  try {
    // Options for compression
    const options = {
      maxSizeMB: 0.5, // Maximum size in MB
      maxWidthOrHeight: 1920, // Max width or height
      useWebWorker: true,
    };

    // Compress the file
    const compressedFile = await imageCompression(file, options);

    return compressedFile;

    // if (compressedFile) {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const arrayBuffer = reader.result; // Get file content as ArrayBuffer
    //     if (arrayBuffer) {
    //       const newBlob = new Blob([arrayBuffer], {
    //         type: compressedFile.type,
    //       });
    //       // console.log(newBlob);
    //     } else {
    //       console.error("Error: arrayBuffer is null");
    //     }
    //   };
    //   reader.readAsArrayBuffer(compressedFile);
    // }

    // console.log(compressedFile);
  } catch (error) {
    console.error("Error compressing the file:", error);
  }
};

export const convertNewBase64File = async (thumbnail: string) => {
  const base64Data = thumbnail.split(",")[1];

  // Convert to binary data
  const binaryData = atob(base64Data);

  // Create an array of 8-bit unsigned integers
  const byteNumbers = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteNumbers[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the byte array
  const blob = new Blob([byteNumbers], { type: "image/png" });

  // Optional: Create a downloadable file
  const file = new File([blob], "output.png", { type: "image/png" });

  try {
    // Options for compression
    const options = {
      maxSizeMB: 0.5, // Maximum size in MB
      maxWidthOrHeight: 1920, // Max width or height
      useWebWorker: true,
    };

    // Compress the file
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Error compressing the file:", error);
  }
};

export const base64ToArrayBuffer = (
  base64String: string,
  type: string
): ArrayBuffer => {
  const len = base64String.length;
  const bytes = new Uint8Array(len); // Create a Uint8Array
  for (let i = 0; i < len; i++) {
    bytes[i] = base64String.charCodeAt(i); // Map each char to its UTF-16 code unit
  }
  return bytes.buffer; // Return the ArrayBuffer
};

export const convertUrlToBase64 = async (url: string) => {
  let file = "";
  try {
    // Fetch the file from the URL
    const response = await fetch(url);
    const blob = await response.blob();

    // Use FileReader to convert blob to Base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const mimeType = blob.type; // Get the MIME type of the blob

      if (reader?.result && typeof reader.result === "string") {
        const base64 = reader.result.replace(/^data:.+;base64,/, ""); // Remove the prefix
        file = `data:${mimeType};base64,${base64}`;
      }

      return file;
    };
    reader.readAsDataURL(blob);
  } catch (error) {
    console.error("Error converting file to Base64:", error);
  }
};
