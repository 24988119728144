export const sortChatMessagesByTime = (messages: []) => {
  const itemsWithTimestamps = messages.map((item: any) => ({
    ...item,
    timestamp: new Date(item.created_at).getTime(),
  }));

  const sortedItems = itemsWithTimestamps.sort(
    (a: any, b: any) => a.timestamp - b.timestamp
  );

  return sortedItems;
};

export const sortMainMesageByTime = (messages: any[]) => {
  const sortedItems = messages.sort(
    (a: any, b: any) => b.lastSentMessageTime - a.lastSentMessageTime
  );

  return sortedItems;
};

export const getServerFileType = (file: string) => {
  const fileType = file.split(".").pop();

  // console.log(fileType);

  if (fileType === "pdf") {
    return "pdf";
  } else if (
    fileType === "mp4" ||
    fileType === "mov" ||
    fileType === "avi" ||
    fileType === "WebM"
  ) {
    return "video";
  } else if (fileType === "jpg" || fileType === "jpeg" || fileType === "png") {
    return "image";
  } else {
    return "file";
  }
};

export const generateRandomHttpLink = () => {
  const protocols = ["http", "https"];
  const domains = ["example.com", "testsite.org", "mywebsite.net"];
  const paths = ["home", "about", "contact", "blog", "products"];
  const queryParams = ["user", "id", "ref", "source"];

  // Select random protocol, domain, and path
  const protocol = protocols[Math.floor(Math.random() * protocols.length)];
  const domain = domains[Math.floor(Math.random() * domains.length)];
  const path = paths[Math.floor(Math.random() * paths.length)];

  // Generate random query string
  const queryKey = queryParams[Math.floor(Math.random() * queryParams.length)];
  const queryValue = Math.floor(Math.random() * 1000); // Random value

  // Construct the URL
  const randomLink = `${protocol}://${domain}/${path}?${queryKey}=${queryValue}`;
  return randomLink;
};
