const Avatar: React.FC<{ firstName?: string; lastName?: string }> = ({
  firstName,
  lastName,
}) => {
  return (
    <div className="relative inline-flex items-center justify-center w-9 h-9 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
      <span className="font-semibold text-gray-300">
        {lastName && lastName[0].toUpperCase()}{" "}
        {firstName && firstName[0].toUpperCase()}
      </span>
    </div>
  );
};

export default Avatar;
