import { useEffect } from "react";
import styles from "./SuccessModal.module.css";

const ErrorModal: React.FC<{
  message?: string;
  open?: boolean;
  buttonText?: string;
  onCloseModal?: () => void;
}> = ({ message, open, onCloseModal, buttonText }) => {
  useEffect(() => {
    // Add or remove overflow hidden on <html> tag
    if (open) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }

    // Cleanup function to reset overflow on unmount
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [open]);

  return (
    <div
      id="successModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${styles.modal_container} ${
        open ? "flex" : "hidden"
      }   overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full  h-full`}
    >
      <div className="relative p-4 w-full flex justify-center items-center max-w-96 h-full md:h-auto">
        <div className="relative p-4 text-center w-full rounded-lg shadow bg-gray-800 sm:p-5">
          <div
            className={`${styles.error_modal_icon} w-8 h-8 rounded-full  p-2 flex items-center justify-center mx-auto mb-3.5`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-cyan-100"
              fill="currentColor"
              viewBox="0 0 384 512"
              aria-hidden="true"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
            <span className="sr-only">Success</span>
          </div>
          <p className="mb-4 text-xs md:text-sm font-semibold text-gray-900 dark:text-white">
            {message}
          </p>
          <button
            data-modal-toggle="successModal"
            onClick={onCloseModal}
            type="button"
            className={`${styles.error_modal_btn} py-2 px-3 text-xs font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900`}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
