import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messageCount: null,
};

export const messageCountSlice = createSlice({
  initialState,
  name: "messageCountState",
  reducers: {
    updateMessageCount: (state, action) => {
      state.messageCount = action.payload;
    },
  },
});

export default messageCountSlice.reducer;

export const { updateMessageCount } = messageCountSlice.actions;
