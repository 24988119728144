import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ExpertServiceCard from "./ExpertServiceCard";
import ServicesLoader from "../../commons/ServicesLoader";
import { useGetAllExpertServicesMutation } from "../../../lib/apis/serviceApis";
import ErrorPage from "../../commons/ErrorPage";

const ExpertServices: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const category = searchParams.get("category");

  const { serviceCategories } = useSelector(
    (state: any) => state.serviceCategoryState
  );

  const [getAllExpertServices, { isLoading, data, isError }] =
    useGetAllExpertServicesMutation();

  useEffect(() => {
    if (serviceCategories && category) {
      const categoryObj = serviceCategories.find(
        (cat: any) => cat.name === category
      );
      if (categoryObj) {
        setCategoryId(categoryObj.id);
      } else {
        setCategoryId(null);
      }
    }
  }, [category]);

  useEffect(() => {
    if (categoryId) {
      getAllExpertServices(
        `services?page=1&limit=30&filterBy[service_category_id]=${categoryId}`
      );
    } else {
      getAllExpertServices("services?page=1&limit=30");
    }
  }, [categoryId, category]);

  let dashboardContent;

  if (isLoading) {
    dashboardContent = <ServicesLoader />;
  } else if (isError) {
    dashboardContent = <ErrorPage />;
  } else {
    dashboardContent = (
      <section className="w-full my-10 px-4 pb-9 pt-5 md:px-6 md:pb-10 md:pt-6 lg:px-7 lg:pb-12 3xl:px-8 ">
        <div className="-z-10 grid grid-cols-1 lsm:grid-cols-2  md:grid-cols-2 smd:grid-cols-3 gap-5 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
          {data &&
            data?.data?.length > 0 &&
            data?.data?.map((service: any) => {
              return (
                <ExpertServiceCard
                  businessLogo={service.expert_profile?.business_logo}
                  serviceVideo={service.explainer_video}
                  businessName={service.expert_profile?.business_name}
                  price={service.lowest_acceptable_amount}
                  servicePoster={service.thumbnail}
                  service={service.title}
                  key={service.id}
                  id={service.id}
                  category={service?.service_category?.name}
                  slug={service.slug}
                  expertId={service.expert_profile?.id}
                  averageRating={service.average_rating}
                />
              );
            })}
        </div>
      </section>
    );
  }

  return <>{dashboardContent}</>;
};

export default ExpertServices;
