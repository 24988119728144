import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./Services.module.css";

const removeHighlight = (html: any) => {
  // Remove background-color inline styles using regex
  return html.replace(/background-color:\s*[^;]+;/g, "");
};

const modules = {
  toolbar: [
    ["bold"], // Text styling
  ],
};

const ServiceDesc: React.FC<{
  onChangeDescription?: (description: string, plainDescription: string) => void;
  formError?: any;
}> = ({ onChangeDescription, formError }) => {
  const [content, setContent] = useState("");
  const [descLen, setDescLen] = useState("");

  const quillRef = useRef<ReactQuill | null>(null);

  const handleContentChange = (value: string) => {
    const cleanContent = removeHighlight(value);

    const quill = quillRef?.current && quillRef.current.getEditor(); // Access Quill instance
    const plainText = quill && quill.getText(); // Get plain text from the editor

    if (onChangeDescription && plainText) {
      onChangeDescription(cleanContent, plainText);

      setContent(cleanContent);
      setDescLen(plainText);
    }
  };

  return (
    <>
      <div className="h-72">
        <ReactQuill
          value={content}
          onChange={handleContentChange}
          modules={modules}
          theme="snow"
          ref={quillRef}
        />

        <div className=" ml-auto flex w-24 justify-end -mt-5 mr-1">
          <span className=" text-fuchsia-200 text-sm">
            {descLen.trim().length}/600
          </span>
        </div>
      </div>
      {formError && formError.field === "description" && (
        <div className="mt-5">
          <p className={styles.intro3}>{formError.error}</p>
        </div>
      )}
    </>
  );
};

export default ServiceDesc;
