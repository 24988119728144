// import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import ModalOverlay from "../components/commons/ModalOverlay";
import LoginForm from "../components/Auth/LoginForm";
import SignupForm from "../components/Auth/SignupForm";
import PasswordResetForm from "../components/Auth/PasswordResetForm";
import VerificationForm from "../components/Auth/VerificationForm";
import VerifyResetPasswordForm from "../components/Auth/VerifyResetPasswordForm";
import ProtectedRoutes from "./ProtectedRoutes";
import UpdatePasswordForm from "../components/Auth/UpdatePasswordForm";
import ExpertProducts from "../components/TopExperts/ExpertProducts";
import TopExpertContact from "../components/TopExperts/TopExpertContacts";
import TopExpertsAbout from "../components/TopExperts/TopExpertsAbout";
import TopExpertsHelp from "../components/TopExperts/TopExpertsHelp";
import TopExpertTerms from "../components/TopExperts/TopExpertsTerms";
import ExpertRoutes from "./ExpertRoutes";
import Profile from "../components/Profile/Expert/Profile";
import WishList from "../components/Profile/Expert/WishList";
import Products from "../components/Profile/Expert/Products";
import ClientGeneralProfile from "../components/Profile/Client/Profile";
// import ServicesLoader from "../components/commons/ServicesLoader";
import Messages from "../components/Profile/Expert/Messages";
import ClientMessages from "../components/Profile/Client/ClientMessages";
import SearchPage from "../pages/SearchPage";
// import ServiceDetailsLoader from "../components/commons/ServiceDetailsLoader";
// import ProfileLoader from "../components/Profile/common/ProfileLoader";
// import CreateServiceLoader from "../components/Services/CreateServiceLoader";
// import MainProfileLoader from "../components/Profile/common/MainProfileLoader";
// import TopExpertsLoader from "../components/commons/TopExpertsLoader";

// const HomePage = lazy(() => import("../pages/HomePage"));
import HomePage from "../pages/HomePage";
import TopExpertsPage from "../pages/TopExpertsPage";
// const TopExpertsPage = lazy(() => import("../pages/TopExpertsPage"));

// const ServiceDetailsPage = lazy(() => import("../pages/ServiceDetailsPage"));
import ServiceDetailsPage from "../pages/ServiceDetailsPage";

// const ExpertDetailsPage = lazy(() => import("../pages/ExpertDetailsPage"));
import ExpertDetailsPage from "../pages/ExpertDetailsPage";
// const CreateServicePage = lazy(() => import("../pages/CreateServicePage"));
import CreateServicePage from "../pages/CreateServicePage";

// const ClientProfilePage = lazy(() => import("../pages/ClientProfilePage"));
import ClientProfilePage from "../pages/ClientProfilePage";
// const ExpertProfilePage = lazy(() => import("../pages/ExpertProfilePage"));
import ExpertProfilePage from "../pages/ExpertProfilePage";

// const ExpertApplicationPage = lazy(
//   () => import("../pages/ExpertApplicationPage")
// );

import ExpertApplicationPage from "../pages/ExpertApplicationPage";

// const ServiceNegotationPage = lazy(
//   () => import("../pages/ServiceNegotationPage")
// );

import ServiceNegotationPage from "../pages/ServiceNegotationPage";

const AppRoutes: React.FC = () => {
  const { user } = useSelector((state: any) => state.userState);

  return (
    <Routes>
      <Route
        path="/"
        element={
          // <Suspense fallback={<ServicesLoader />}>
          <HomePage />
          // </Suspense>
        }
      >
        <Route
          path="auth/login"
          element={<ModalOverlay children={<LoginForm />} />}
        />

        <Route
          path="auth/sign-up"
          element={<ModalOverlay children={<SignupForm />} />}
        />

        <Route
          path="auth/verify"
          element={<ModalOverlay children={<VerificationForm />} />}
        />

        <Route
          path="auth/password/reset"
          element={<ModalOverlay children={<PasswordResetForm />} />}
        />

        <Route
          path="auth/password/reset/verify"
          element={<ModalOverlay children={<VerifyResetPasswordForm />} />}
        />

        <Route
          path="auth/password/update"
          element={<ModalOverlay children={<UpdatePasswordForm />} />}
        />
      </Route>

      <Route
        path="/experts"
        element={
          // <Suspense fallback={<TopExpertsLoader />}>
          <TopExpertsPage />
          // </Suspense>
        }
      />

      <Route path="/search/services" element={<SearchPage />} />

      <Route
        path="/experts/:name"
        element={
          // <Suspense fallback={<></>}>
          <ExpertDetailsPage />
          // </Suspense>
        }
      >
        <Route path="services" element={<ExpertProducts />} />
        <Route path="about" element={<TopExpertsAbout />} />
        <Route path="help" element={<TopExpertsHelp />} />
        <Route path="contact-us" element={<TopExpertContact />} />
        <Route path="terms" element={<TopExpertTerms />} />
      </Route>

      <Route
        path="/services/:serviceName"
        element={
          // <Suspense fallback={<ServiceDetailsLoader />}>
          <ServiceDetailsPage />
          // </Suspense>
        }
      />

      <Route
        path="/experts/application"
        element={
          // <Suspense fallback={<CreateServiceLoader />}>
          <ProtectedRoutes user={user} children={<ExpertApplicationPage />} />
          // </Suspense>
        }
      />

      <Route
        path="/client/profile"
        element={
          // <Suspense fallback={<MainProfileLoader />}>
          <ProtectedRoutes user={user}>
            <ClientProfilePage />
          </ProtectedRoutes>
          // </Suspense>
        }
      >
        <Route
          path=""
          element={
            // <Suspense fallback={<ProfileLoader />}>
            <ClientGeneralProfile />
            // </Suspense>
          }
        />

        <Route
          path="wish-list"
          element={
            // <Suspense fallback={<ProfileLoader />}>
            <ProtectedRoutes user={user}>
              <WishList />
            </ProtectedRoutes>
            // </Suspense>
          }
        />

        <Route
          path="messages"
          element={
            // <Suspense fallback={<ProfileLoader />}>
            <ProtectedRoutes user={user}>
              <ClientMessages />
            </ProtectedRoutes>
            // </Suspense>
          }
        />
      </Route>

      <Route
        path="/expert/profile"
        element={
          // <Suspense fallback={<MainProfileLoader />}>
          <ExpertRoutes user={user}>
            <ExpertProfilePage />
          </ExpertRoutes>
          // </Suspense>
        }
      >
        <Route
          path=""
          element={
            // <Suspense fallback={<ProfileLoader />}>
            <ExpertRoutes user={user}>
              <Profile />
            </ExpertRoutes>
            // </Suspense>
          }
        />

        <Route
          path="services"
          element={
            // <Suspense fallback={<ProfileLoader />}>
            <ExpertRoutes user={user}>
              <Products />
            </ExpertRoutes>
            // </Suspense>
          }
        />

        <Route
          path="wish-list"
          element={
            // <Suspense fallback={<ProfileLoader />}>
            <ExpertRoutes user={user}>
              <WishList />
            </ExpertRoutes>
            // </Suspense>
          }
        />

        <Route
          path="messages"
          element={
            // <Suspense fallback={<ProfileLoader />}>
            <ExpertRoutes user={user}>
              <Messages />
            </ExpertRoutes>
            // </Suspense>
          }
        />
      </Route>

      <Route
        path="/service/create"
        element={
          // <Suspense fallback={<CreateServiceLoader />}>
          <ExpertRoutes user={user}>
            <CreateServicePage />
          </ExpertRoutes>
          // </Suspense>
        }
      />

      <Route
        path="/messages/:messageId"
        element={
          // <Suspense fallback={<></>}>
          <ProtectedRoutes user={user}>
            <ServiceNegotationPage />
          </ProtectedRoutes>
          // </Suspense>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
