import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styles from "./TopExperts.module.css";

const TopExpertsProfileCover: React.FC = () => {
  const { topExpertDetails } = useSelector(
    (state: any) => state.topExpertDetailsState
  );
  const { pathname } = useLocation();

  const currentLocation = pathname.split("/")[3];

  return (
    <>
      <div className="relative h-[300px] w-full">
        <div className="absolute top-0 left-0 h-full w-full">
          {topExpertDetails && (
            <img
              alt={`${topExpertDetails?.business_name} cover_image`}
              data-nimg="fill"
              className="object-cover"
              sizes="100vw"
              src={topExpertDetails?.business_cover_image}
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                inset: "0px",
                color: "transparent",
              }}
            />
          )}
        </div>

        <div
          className={`relative h-full w-full  px-4 pt-10  text-center backdrop-blur-sm lg:px-8 lg:pt-14 lg:pb-20 ${styles.cover_overlay}`}
        >
          <div className="relative  mx-auto h-[75px] w-[75px] md:h-20 md:w-20 2xl:h-[90px] 2xl:w-[90px] 3xl:h-[100px] 3xl:w-[100px]">
            {topExpertDetails && (
              <div className="mt-5">
                <img
                  alt={`${topExpertDetails?.business_name} business logo`}
                  className="object-cover"
                  sizes="100vw"
                  src={topExpertDetails?.business_logo}
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    inset: "0px",
                    color: "transparent",
                    borderRadius: "20px",
                  }}
                />
              </div>
            )}
          </div>

          <h1 className="mt-3 text-sm font-medium text-cyan-50 lg:text-15px 2xl:mt-4">
            {topExpertDetails?.business_name}
          </h1>
        </div>

        <div className="relative  -mt-[33px] space-x-6 px-4 text-center text-13px rtl:space-x-reverse lg:space-x-8">
          <Link
            className={`${
              currentLocation === "services"
                ? styles.expert_nav_active
                : styles.expert_nav
            } relative cursor-pointer pb-3.5 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-dark-400 before:transition-all before:duration-300 before:ease-in-out hover:text-dark-100 dark:before:bg-light-400 dark:hover:text-light font-semibold text-dark-100 before:w-full dark:text-light`}
            to={`/experts/${topExpertDetails?.business_name}/services?eid=${topExpertDetails?.id}`}
          >
            Services
          </Link>
          <Link
            className={`${
              currentLocation === "about"
                ? styles.expert_nav_active
                : styles.expert_nav
            } relative cursor-pointer pb-3.5 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-dark-400 before:transition-all before:duration-300 before:ease-in-out hover:text-dark-100 dark:before:bg-light-400 dark:hover:text-light font-semibold text-dark-100 before:w-full dark:text-light`}
            to={`/experts/${topExpertDetails?.business_name}/about?eid=${topExpertDetails?.id}`}
          >
            About
          </Link>

          <Link
            className={`${
              currentLocation === "contact-us"
                ? styles.expert_nav_active
                : styles.expert_nav
            } relative cursor-pointer pb-3.5 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-dark-400 before:transition-all before:duration-300 before:ease-in-out hover:text-dark-100 dark:before:bg-light-400 dark:hover:text-light font-semibold text-dark-100 before:w-full dark:text-light`}
            to={`/experts/${topExpertDetails?.business_name}/contact-us?eid=${topExpertDetails?.id}`}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </>
  );
};

export default TopExpertsProfileCover;
