import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../../helpers/firebase";
import { setCurrentUser } from "./redux/userSlice";

let baseUrl: string;

if (process.env.NODE_ENV === "development") {
  baseUrl = process.env.REACT_APP_DEV_API_BASE_URL || "";
} else {
  baseUrl =
    process.env.REACT_APP_PROD_API_BASE_URL || "https://api.deskzone.pw/api";
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
      const token = await getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCurrentUser: builder.mutation({
      query: () => ({
        url: "/user/logged-in-user",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCurrentUser(data?.data));
        } catch (error: any) {
          // console.log(error);
        }
      },
    }),

    updateUserInfo: builder.mutation({
      query: (payload) => ({
        url: "/user/update-profile",
        method: "PATCH",
        body: payload,
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(userApi.endpoints.getCurrentUser.initiate(null));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),

    updateUserPassword: builder.mutation({
      query: (payload) => ({
        url: "/user/update-password",
        method: "PATCH",
        body: payload,
      }),
    }),

    serverHealthCheck: builder.mutation({
      query: () => ({
        url: "/auth/health-check",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCurrentUserMutation,
  useServerHealthCheckMutation,
  useUpdateUserInfoMutation,
  useUpdateUserPasswordMutation,
} = userApi;
