import styles from "./ErrorPage.module.css";

const ErrorPage: React.FC<{ message?: string; mainMessage?: string }> = ({
  message,
  mainMessage,
}) => {
  return (
    <div className="max-h-full mt-52 h-full flex  items-center justify-center px-4 pb-10 pt-5 md:px-6 md:pt-6 lg:px-7 lg:pb-12 3xl:px-8">
      <div className="text-center">
        <svg
          viewBox="0 0 130 126"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto mb-6 h-auto w-20 text-light dark:text-light/10 2xl:w-24 3xl:w-32"
        >
          <path
            d="M61.9847 125.251C96.2179 125.251 123.969 97.3824 123.969 63.0044C123.969 28.6264 96.2179 0.757568 61.9847 0.757568C27.7515 0.757568 0 28.6264 0 63.0044C0 97.3824 27.7515 125.251 61.9847 125.251Z"
            fill="currentColor"
          ></path>
          <path
            d="M112.876 36.0507V46.7358V105.156C112.876 105.156 43.9637 105.156 39.2298 105.156C36.8673 105.156 34.7269 104.193 33.1726 102.641C31.6272 101.08 30.668 98.9397 30.668 96.5583V30.7527H60.2171L64.6312 36.0418H112.876V36.0507Z"
            fill="#F7931E"
          ></path>
          <path
            d="M33.1193 70.0775C45.294 70.0775 55.1635 60.1663 55.1635 47.9401C55.1635 35.714 45.294 25.8027 33.1193 25.8027C20.9447 25.8027 11.0752 35.714 11.0752 47.9401C11.0752 60.1663 20.9447 70.0775 33.1193 70.0775Z"
            fill="white"
          ></path>
          <path
            d="M16.7686 62.7816C16.7686 62.7816 25.6679 74.8849 39.9406 76.1693C39.9406 76.1693 37.285 75.3665 36.0061 69.8902C34.7183 64.4049 18.7314 60.3021 16.7686 62.7816Z"
            fill="white"
          ></path>
          <path
            d="M35.509 47.9401L42.3034 41.1169C42.9607 40.4569 42.9607 39.3777 42.3034 38.7177C41.6462 38.0577 40.5715 38.0577 39.9143 38.7177L33.1199 45.5408L26.3254 38.7177C25.6682 38.0577 24.5935 38.0577 23.9363 38.7177C23.279 39.3777 23.279 40.4569 23.9363 41.1169L30.7307 47.9401L23.9363 54.7632C23.279 55.4233 23.279 56.5025 23.9363 57.1625C24.2649 57.4925 24.7001 57.662 25.1264 57.662C25.5527 57.662 25.9879 57.4925 26.3166 57.1625L33.111 50.3393L39.9054 57.1625C40.234 57.4925 40.6692 57.662 41.0955 57.662C41.5307 57.662 41.9571 57.4925 42.2857 57.1625C42.9429 56.5025 42.9429 55.4233 42.2857 54.7632L35.509 47.9401Z"
            fill="#ED1C24"
          ></path>
          <path
            d="M129.999 46.5308V96.5495C129.999 101.294 126.163 105.148 121.438 105.148H39.2383C43.9633 105.148 47.8001 101.294 47.8001 96.5495V46.5308H129.999Z"
            fill="#FFBD39"
          ></path>
          <path
            d="M78.4077 79.9345C80.0726 78.2626 80.0726 75.5519 78.4077 73.88C76.7429 72.2081 74.0436 72.2081 72.3787 73.88C70.7139 75.5519 70.7139 78.2626 72.3787 79.9345C74.0436 81.6064 76.7429 81.6064 78.4077 79.9345Z"
            fill="#F7931E"
          ></path>
          <path
            d="M68.6371 69.5779C68.193 69.5779 67.7756 69.3103 67.6157 68.8643C67.4025 68.2935 67.6867 67.6692 68.2552 67.4551L79.0019 63.4058C79.5615 63.1917 80.1921 63.4771 80.4052 64.048C80.6184 64.6188 80.3342 65.2431 79.7657 65.4572L69.019 69.5065C68.8946 69.5511 68.7614 69.5779 68.6371 69.5779Z"
            fill="#F7931E"
          ></path>
          <path
            d="M102.895 81.1348C105.219 80.756 106.798 78.5569 106.421 76.2229C106.043 73.889 103.854 72.304 101.529 72.6828C99.2054 73.0616 97.6271 75.2607 98.0042 77.5946C98.3814 79.9286 100.571 81.5135 102.895 81.1348Z"
            fill="#F7931E"
          ></path>
          <path
            d="M108.968 69.5778C108.843 69.5778 108.71 69.551 108.586 69.5064L97.8392 65.4571C97.2707 65.2431 96.9865 64.6098 97.1997 64.0479C97.4128 63.486 98.0434 63.1917 98.603 63.4057L109.35 67.455C109.918 67.6691 110.202 68.3023 109.989 68.8642C109.829 69.3102 109.412 69.5778 108.968 69.5778Z"
            fill="#F7931E"
          ></path>
          <path
            d="M91.4364 93.0174H86.1696C85.5656 93.0174 85.0771 92.5268 85.0771 91.9203C85.0771 91.3138 85.5656 90.8232 86.1696 90.8232H91.4364C92.0403 90.8232 92.5288 91.3138 92.5288 91.9203C92.5288 92.5268 92.0403 93.0174 91.4364 93.0174Z"
            fill="#F7931E"
          ></path>
        </svg>
        <h2 className="mb-1.5 text-15px font-semibold text-white">
          {message ? message : "Something went wrong!"}
        </h2>
        <p className="text-gray-500">
          {mainMessage ? mainMessage : "Check your network connection"}
        </p>

        <div className="mt-10">
          <a className={styles.reload_btn} href="/">
            Try Again!
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
