import Icon from "../components/commons/Icon";
import styles from "./NavgitaionBar.module.css";

const MobileSearchInput: React.FC = () => {
  return (
    <form className="block mt-1 md:hidden w-full">
      <div className="relative flex items-center">
        <input
          type="search"
          id="default-search"
          className={`${styles.search_input2} w-full  block  md:w-full p-4 ps-10 text-sm  border  bg-gray-50 border-gray-600 placeholder-gray-400 text-white `}
          placeholder="What service are you interested in?"
        />
        <button
          type="submit"
          className={` absolute end-2 bottom-2  font-medium  px-4 ${styles.search_btn}`}
        >
          <Icon
            iconClasses={`fa-solid fa-magnifying-glass ${styles.search_icons}`}
          />
        </button>
      </div>
    </form>
  );
};

export default MobileSearchInput;
