export const freelanceSkills: string[] = [
  "All",
  "SEO",
  "CMS",
  "AR/VR",
  "Sales",
  "Joomla",
  "Invoicing",
  "Marketing",
  "Networking",
  "Content Creation",
  "Digital Marketing",
  "Mobile App",
  "Graphic Design",
  "Copywriting",
  "Shopify",
  "UI Template",
  "Illustration",
  "Customer Service",
  "Software Engineer",
  "Web Development",
  "Wifeframe Kits",
  "Data Analysis",
  "Financial Planning",
  "Problem Solving",
  "Project Management",
  // "Copywriting",

  // "Web Development",
  // "Data Analysis",
  // "Financial Planning",
  // "Problem Solving",
  // "Project Management",
  // "Copywriting",
  // "Adaptability",
  // "Research",
  // "Web Development",
  // "Data Analysis",
  // "Financial Planning",
  // "Problem Solving",
  // "Project Management",
];
