import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { IconContext } from "react-icons";
import Spinner from "../../commons/Spinner";
import { FaAngleRight } from "react-icons/fa";
import { IoArchiveSharp, IoMailUnread, IoMail } from "react-icons/io5";
import { RiErrorWarningLine } from "react-icons/ri";
import { sortMainMesageByTime } from "../../../helpers/message";
// import Logo from "../../../Assets/poster5.png";
import styles from "../Profile.module.css";
import { log } from "console";

const OngoingMessages: React.FC<{
  messages?: [];
  onMarkMessageAsRead: (ids: string[]) => void;
  onArchiveMessage: (ids: string[]) => void;
  onMarkMessageAsUnread: (ids: string[]) => void;
  markAsReadLoading?: boolean;
  markAsUnreadLoading?: boolean;
  achiveMessageLoading?: boolean;
  characterByScreen?: number;
}> = ({
  messages,
  onMarkMessageAsRead,
  onArchiveMessage,
  onMarkMessageAsUnread,
  markAsReadLoading,
  markAsUnreadLoading,
  achiveMessageLoading,
  characterByScreen,
}) => {
  const [isChecked, setIsChecked] = useState<number[]>([]);

  const [messagedIds, setMessageIds] = useState<string[]>([]);
  const [sortedChatMessages, setSortedChatMessages] = useState<any[]>([]);
  const [messageId, setMessageId] = useState("");
  const { user } = useSelector((state: any) => state.userState);
  const { loggedInExpert } = useSelector(
    (state: any) => state.loggedInExpertState
  );

  useEffect(() => {
    if (messages) {
      setSortedChatMessages(
        messages.map((message: any) => {
          return {
            ...message,
            lastSentMessageTime: new Date(
              message?.lastMessage?.created_at
            ).getTime(),
          };
        })
      );
    }
  }, [messages]);

  const handleCheckboxChange = (index: number, messageId: string) => {
    setIsChecked((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });

    setMessageIds((prev) => {
      if (prev.includes(messageId)) {
        return prev.filter((id) => id !== messageId);
      } else {
        return [...prev, messageId];
      }
    });
  };

  const archiveMessage = (ids: string[], id: string) => {
    setMessageId(id);
    onArchiveMessage(ids);
  };

  const markMesssageAsRead = (ids: string[], id: string) => {
    setMessageId(id);
    onMarkMessageAsRead(ids);
  };

  const markMessageAsUnRead = (ids: string[], id: string) => {
    setMessageId(id);
    onMarkMessageAsUnread(ids);
  };

  return (
    <>
      {isChecked.length > 1 && (
        <div className="mb-5 -mt-10">
          <div className="flex text-xs justify-end">
            <button
              className="border text-white rounded-md px-2 py-1 mx-3 bg-[#ca8a04] hover:bg-[#fdd170]"
              onClick={() => onArchiveMessage(messagedIds)}
            >
              Archive All
            </button>
            <button
              className="border text-white rounded-md px-2 py-1 bg-[#24b47e] hover:bg-[#53ffc0]"
              onClick={() => onMarkMessageAsRead(messagedIds)}
            >
              Mark Read/Unread
            </button>
          </div>
        </div>
      )}

      {sortedChatMessages &&
        sortedChatMessages?.length > 0 &&
        sortMainMesageByTime(sortedChatMessages)?.map(
          (message: any, i: number) => {
            return (
              <div
                className={`mb-5 ${styles.message_container}`}
                pb-2
                key={message?.id}
              >
                <div
                  className={`${
                    message?.hasUnviewedMessages
                      ? `bg-[#19191A]`
                      : `bg-[#343434]`
                  } p-4 flex gap-3 items-start`}
                >
                  <input
                    checked={isChecked.includes(i)}
                    onChange={() => handleCheckboxChange(i, message?.id)}
                    type="checkbox"
                    className={`${styles.message_check2} hidden scale-125 md:block`}
                  />
                  <Link
                    to={`/messages/${message.id}`}
                    className={`w-full block`}
                  >
                    <div className="flex">
                      <div className="flex gap-4">
                        {message?.expert?.id &&
                        loggedInExpert?.id !== message?.expert?.id ? (
                          <img
                            src={message?.expert?.business_logo}
                            alt="logo"
                            className="rounded-full  h-10 w-10"
                          />
                        ) : (
                          <img
                            src={
                              message?.client_user?.avatar ||
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQABqQIdskCD9BK0I81EbVfV9tTz320XvJ35A&s"
                            }
                            alt="logo"
                            className="rounded-full  h-10 w-10"
                          />
                        )}

                        {message?.expert?.id &&
                        loggedInExpert?.id !== message?.expert?.id ? (
                          <p className="text-white  text-xs md:text-sm font-semibold">
                            {message?.expert?.business_name}
                          </p>
                        ) : (
                          <p className="text-white  text-xs md:text-sm font-semibold">
                            {message?.client_user?.first_name}{" "}
                            {message?.client_user?.last_name}
                          </p>
                        )}
                      </div>

                      <Link to={`/messages/${message.id}`} className="ml-auto">
                        <IconContext.Provider
                          value={{ color: "white", size: "1.3em" }}
                        >
                          <FaAngleRight />
                        </IconContext.Provider>
                      </Link>
                    </div>

                    <div className={`ml-14 -mt-5 w-4/5 md:w-11/12 `}>
                      <p className="text-gray-600 text-xs font-semibold">
                        <Moment fromNow>
                          {new Date(message?.lastMessage?.created_at)}
                        </Moment>
                      </p>
                      <p
                        className={`text-white text-xs md:text-sm ${styles.message_text}`}
                      >
                        {message?.lastMessage?.message}

                        {/* {truncatedText} */}

                        {/* {characterByScreen &&
                          message?.chatMessages[
                            message?.chatMessages.length - 1
                          ]?.message.length > characterByScreen &&
                          "..."} */}
                      </p>
                    </div>
                  </Link>
                </div>

                {/* {(isChecked.includes(i) || showOptions) && ( */}
                <div className="flex items-start ml-3 mt-2 pr-2">
                  <div className="flex items-center gap-2 ">
                    <IconContext.Provider
                      value={{ color: "#d1871f", size: "1.3em" }}
                    >
                      <RiErrorWarningLine />
                    </IconContext.Provider>

                    {message?.lastMessage?.sender?.id === user?.id ? (
                      <p className="text-xs  text-white">
                        <span className="text-[#24b47e]">
                          {message?.client_user?.first_name}{" "}
                          {message?.client_user?.last_name}
                        </span>{" "}
                        hasn't replied yet
                      </p>
                    ) : (
                      <p className="text-xs  text-white">
                        Don't forget to reply{" "}
                        <span className="text-[#24b47e]">
                          {message?.client_user?.first_name}{" "}
                          {message?.client_user?.last_name}
                        </span>
                      </p>
                    )}
                  </div>

                  <div className="ml-auto flex gap-4 items-center">
                    {messageId === message?.id && achiveMessageLoading ? (
                      <Spinner />
                    ) : (
                      <button
                        className="text-black"
                        title="Archive"
                        onClick={() =>
                          archiveMessage([message?.id], message.id)
                        }
                      >
                        <IconContext.Provider
                          value={{ color: "white", size: "1.3em" }}
                        >
                          <IoArchiveSharp />
                        </IconContext.Provider>
                      </button>
                    )}
                    {message?.lastMessage?.sender?.id !== user?.id &&
                      message?.hasUnviewedMessages &&
                      (messageId === message?.id && markAsReadLoading ? (
                        <Spinner />
                      ) : (
                        <button
                          className="text-white"
                          title="Mark as Read"
                          onClick={() =>
                            markMesssageAsRead([message?.id], message?.id)
                          }
                        >
                          <IconContext.Provider
                            value={{ color: "white", size: "1.3em" }}
                          >
                            <IoMailUnread />
                          </IconContext.Provider>
                        </button>
                      ))}

                    {message?.lastMessage?.sender?.id !== user?.id &&
                      !message?.hasUnviewedMessages &&
                      (messageId === message?.id && markAsUnreadLoading ? (
                        <Spinner />
                      ) : (
                        <button
                          className="text-white"
                          title="Mark as Unread"
                          onClick={() =>
                            markMessageAsUnRead([message?.id], message?.id)
                          }
                        >
                          <IconContext.Provider
                            value={{ color: "white", size: "1.3em" }}
                          >
                            <IoMail />
                          </IconContext.Provider>
                        </button>
                      ))}
                  </div>
                </div>
                {/* )} */}
              </div>
            );
          }
        )}
    </>
  );
};

export default OngoingMessages;
