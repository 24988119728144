import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatData: null,
};

export const chatSessionSlice = createSlice({
  initialState,
  name: "chatSessionState",
  reducers: {
    updateChatData: (state, action) => {
      state.chatData = action.payload;
    },
  },
});

export default chatSessionSlice.reducer;

export const { updateChatData } = chatSessionSlice.actions;
