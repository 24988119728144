import styles from "./TopExperts.module.css";

const TopExpertTerms: React.FC = () => {
  return (
    <div
      className="mx-auto flex h-full w-11/12 max-w-screen-xl flex-col py-6 focus:outline-none md:py-8 lg:py-10"
      style={{ opacity: "1", transform: "none" }}
    >
      <p className={`pb-20 text-center font-medium ${styles.terms_time}`}>
        Last updated on September 29, 2023
      </p>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Disclaimers and Limitation of Liability
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          The Website is provided "as is" and "as available" without any
          warranties, either expressed or implied. Pickbazar shall not be liable
          for any direct, indirect, incidental, special, consequential, or
          punitive damages resulting from the use or inability to use the
          Website.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Intellectual Property
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          The Website and its original content, features, and functionality are
          owned by [Your Company] and are protected by international copyright,
          trademark, and other intellectual property laws.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Privacy Policy
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          Your use of the Website is also governed by our Privacy Policy, which
          can be found [link to Privacy Policy]. By using the Website, you
          consent to the practices described in the Privacy Policy.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Use of the Website
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          You must be at least [Age] years old to use this Website. By using the
          Website, you represent and warrant that you are at least [Age] years
          old. You agree to use the Website for lawful purposes only and in a
          manner that does not infringe upon the rights of others.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Acceptance of Terms
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          By using this Website, you agree to comply with and be bound by these
          terms and conditions. If you do not agree to these terms, please do
          not use the Website.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Disclaimers and Limitation of Liability
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          The Website is provided "as is" and "as available" without any
          warranties, either expressed or implied. Pickbazar shall not be liable
          for any direct, indirect, incidental, special, consequential, or
          punitive damages resulting from the use or inability to use the
          Website.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Intellectual Property
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          The Website and its original content, features, and functionality are
          owned by [Your Company] and are protected by international copyright,
          trademark, and other intellectual property laws.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Privacy Policy
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          Your use of the Website is also governed by our Privacy Policy, which
          can be found [link to Privacy Policy]. By using the Website, you
          consent to the practices described in the Privacy Policy.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Use of the Website
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          You must be at least [Age] years old to use this Website. By using the
          Website, you represent and warrant that you are at least [Age] years
          old. You agree to use the Website for lawful purposes only and in a
          manner that does not infringe upon the rights of others.
        </div>
      </div>
      <div className="order-list-enable mb-8 last:mb-0 lg:mb-10">
        <h3
          className={`${styles.terms} mb-4 text-sm font-medium text-dark dark:text-light lg:mb-5`}
        >
          Acceptance of Terms
        </h3>
        <div className={`space-y-5 leading-6 ${styles.terms_time}`}>
          By using this Website, you agree to comply with and be bound by these
          terms and conditions. If you do not agree to these terms, please do
          not use the Website.
        </div>
      </div>
    </div>
  );
};

export default TopExpertTerms;
