import { Link, useNavigate } from "react-router-dom";
import styles from "./ExpertServices.module.css";

const ServiceInfo: React.FC<{
  businessLogo?: string;
  service?: string;
  businessName?: string;
  price?: number;
  id?: string;
  slug?: string;
  expertId?: string;
}> = ({ businessLogo, service, businessName, price, id, slug, expertId }) => {
  const navigate = useNavigate();

  return (
    <div className={`my-2 items-center px-2`}>
      <div className="gap-2 relative flex items-center flex-shrink-0 truncate overflow-hidden 4xl:h-9 4xl:w-9">
        <img
          alt="Omnico Team"
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="rounded-full h-8 w-8 object-cover"
          src={businessLogo}
          onClick={() => navigate(`/services/${slug}`)}
        />

        <div className={`${styles.service_info}  truncate`}>
          <Link to={`/services/${slug}`}>
            <h3
              title={service}
              className={`mb-0.5 truncate font-medium ${styles.card_service}`}
            >
              {service?.replace(/\b\w/g, (char) => char.toUpperCase())}
            </h3>

            <Link
              className={`${styles.business_name} font-medium text-light-base hover:text-brand dark:text-dark-800 dark:hover:text-brand`}
              to={`/experts/${businessName}/services?eid=${expertId}`}
            >
              {businessName?.replace(/\b\w/g, (char) => char.toUpperCase())}
            </Link>
          </Link>
        </div>
      </div>

      <div className="flex flex-shrink-0  justify-between -mt-5">
        <div></div>
        <div
          className={`-mt-1 ${styles.service_click}`}
          onClick={() => navigate(`/services/${slug}`)}
        >
          <span className={`${styles.price_col}   text-13px font-semibold`}>
            <span className={styles.from}>From</span> ${Math.floor(price ?? 0)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ServiceInfo;
