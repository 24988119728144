import React from "react";
import Icon from "../../commons/Icon";

interface IProps {
  name: string;
  rating: number;
  comment: string;
  date: string;
  upVoteNumber: number;
  downVoteNumber: number;
}

const ReviewTab: React.FC<IProps> = ({
  name,
  rating,
  comment,
  date,
  upVoteNumber,
  downVoteNumber,
}) => {
  return (
    <div className="">
      <div className="my-6">
        <div className="flex ">
          <div className="bg-[#3E3E3E] border border-gray-400 text-gray-300 p-2 rounded-full w-8 text-xs text-center h-8">
            CJ
          </div>
          <div className="ml-5">
            <div className="flex">
              <div className="text-white text-sm mt-2">{name}</div>

              <div className="text-white bg-[#23B47E] px-3 items-center flex rounded-2xl py-[2px] ml-6">
                <div className="text-[14px] font-bold">{rating}</div>
                <div className="text-[10px]  ml-1">
                  <Icon iconClasses={`fa-solid fa-star`} />
                </div>
              </div>
            </div>

            <div className="text-[13px] text-gray-300">
              <div className="mt-3">{comment}</div>

              <div className="flex mt-3">
                <div>{date}</div>

                <div className="font-extrabold text-xl mx-3 -mt-[10px]">.</div>

                <div>Report</div>

                <div className="font-extrabold text-xl mx-3 -mt-[10px]">.</div>

                <div className="flex">
                  <div className="flex">
                    <div className="mt-[0.9px]">
                      <Icon iconClasses={`fa-solid fa-thumbs-up`} />
                    </div>

                    <div className="mx-2 mt-[1px]">{upVoteNumber}</div>
                  </div>

                  <div className="flex ml-3">
                    <div className="mt-[0.9px]">
                      <Icon iconClasses={`fa-solid fa-thumbs-down`} />
                    </div>

                    <div className="mx-2 mt-[1px]">{downVoteNumber}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-[0.5px] border-gray-700"></div>
    </div>
  );
};

export default ReviewTab;
