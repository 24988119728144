// import { useState, useEffect } from "react";
// import ServicesLoaderCard from "./ServicesLoaderCard";
import styles from "./ServiceLoader.module.css";

const ServiceDetailsLoader: React.FC = () => {
  // const [width, setWidth] = useState(window.innerWidth);
  // const [cardNum, setCardNum] = useState(0);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   // Add event listener
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []); // Empty dependency array ensures this runs only on mount/unmount

  // useEffect(() => {
  //   if (width < 599) {
  //     setCardNum(1);
  //   } else if (width <= 949) {
  //     setCardNum(2);
  //   } else if (width <= 1279) {
  //     setCardNum(3);
  //   } else if (width <= 1535) {
  //     setCardNum(4);
  //   } else if (width <= 1779) {
  //     setCardNum(5);
  //   } else {
  //     setCardNum(6);
  //   }
  // }, [width]);

  return (
    <div
      className={`${styles.close_container} relative my-28  w-full h-fit  ${styles.service_details}  rounded-md`}
    >
      <div role="status" className="animate-pulse h-full">
        <div className="md:flex space-y-8 md:space-y-0 md:space-x-8 rtl:space-x-reverse">
          <div className="flex  md:basis-2/3  items-center justify-center w-full h-[270px] sm:h-[300px] lsm:h-[320px]  smd:h-[370px] xl:h-[450px] 2xl:h-[500px]  3xl:h-[600px] bg-gray-300 rounded  dark:bg-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="w-10 h-10 text-gray-600"
              viewBox="0 0 512 512"
              fill="currentColor"
            >
              <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
            </svg>
          </div>
          <div className="grid md:hidden smd:mt-5 xl:mt-5 2xl:mt-3 3xl:mt-10  grid-cols-4 w-full md:w-2/3 gap-5 2xl:gap-10">
            <div className="flex  items-center justify-center h-[40px] sm:h-[50px] lsm:h-[70px] smd:h-[100px] xl:h-[120px] 2xl:h-[140px] bg-gray-300 rounded  dark:bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-5 h-5 lsm:w-10 lsm:h-10 text-gray-600"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
              </svg>
            </div>
            <div className="flex  items-center justify-center  h-[40px] sm:h-[50px] lsm:h-[70px] smd:h-[100px] xl:h-[120px] 2xl:h-[140px] bg-gray-300 rounded  dark:bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-5 h-5 lsm:w-10 lsm:h-10 text-gray-600"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
              </svg>
            </div>
            <div className="flex  items-center justify-center  h-[40px] sm:h-[50px] lsm:h-[70px] smd:h-[100px] xl:h-[120px] 2xl:h-[140px] bg-gray-300 rounded  dark:bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-5 h-5 lsm:w-10 lsm:h-10 text-gray-600"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
              </svg>
            </div>
            <div className="flex  items-center justify-center  h-[40px] sm:h-[50px] lsm:h-[70px]  smd:h-[100px] xl:h-[120px] 2xl:h-[140px] bg-gray-300 rounded  dark:bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-5 h-5 lsm:w-10 lsm:h-10 text-gray-600"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
              </svg>
            </div>
          </div>
          <div className="w-full md:w-1/3">
            <div className="mb-10">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            </div>

            <div className="mb-10">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 hidden lg:block bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
              <div className="h-2 hidden lg:block bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 xl:hidden 2xl:block bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              <div className="h-2 xl:hidden 2xl:block bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 xl:hidden 2xl:block bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            </div>

            <div className="mb-10 lsm:hidden xl:block">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
              <div className="h-2  bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            </div>

            <div className="mb-5">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 md:hidden 3xl:block bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              <div className="h-2 md:hidden 3xl:block bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 md:hidden 3xl:block bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            </div>
          </div>
        </div>

        <div className="hidden md:grid smd:mt-5 xl:mt-5 2xl:mt-3 3xl:mt-10  grid-cols-4 w-full md:w-2/3 gap-5 2xl:gap-10">
          <div className="flex items-center justify-center h-[40px] sm:h-[50px] lsm:h-[70px] smd:h-[100px] xl:h-[120px] 2xl:h-[140px] bg-gray-300 rounded  dark:bg-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="w-5 h-5 lsm:w-10 lsm:h-10 text-gray-600"
              viewBox="0 0 512 512"
              fill="currentColor"
            >
              <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
            </svg>
          </div>
          <div className="flex  items-center justify-center  h-[40px] sm:h-[50px] lsm:h-[70px] smd:h-[100px] xl:h-[120px] 2xl:h-[140px] bg-gray-300 rounded  dark:bg-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="w-5 h-5 lsm:w-10 lsm:h-10 text-gray-600"
              viewBox="0 0 512 512"
              fill="currentColor"
            >
              <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
            </svg>
          </div>
          <div className="flex  items-center justify-center  h-[40px] sm:h-[50px] lsm:h-[70px] smd:h-[100px] xl:h-[120px] 2xl:h-[140px] bg-gray-300 rounded  dark:bg-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="w-5 h-5 lsm:w-10 lsm:h-10 text-gray-600"
              viewBox="0 0 512 512"
              fill="currentColor"
            >
              <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
            </svg>
          </div>
          <div className="flex  items-center justify-center  h-[40px] sm:h-[50px] lsm:h-[70px]  smd:h-[100px] xl:h-[120px] 2xl:h-[140px] bg-gray-300 rounded  dark:bg-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="w-5 h-5 lsm:w-10 lsm:h-10 text-gray-600"
              viewBox="0 0 512 512"
              fill="currentColor"
            >
              <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
            </svg>
          </div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>

      {/* <div
        role="status"
        className="md:flex md:gap-5 h-full md:justify-between w-full"
      >
        <div className="md:w-3/4 h-full">
          <div className="flex items-center justify-center h-[250px] sm:h-[300px] md:h-[350px] lg:h-[450px]  2xl:h-[550px] bg-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="w-10 h-10 text-gray-600"
              viewBox="0 0 512 512"
              fill="currentColor"
            >
              <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
            </svg>
          </div>

          <div className="my-6 md:my-10 grid gap-5 grid-cols-4">
            <div className="flex items-center justify-center h-10 sm:h-14 md:h-20 bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="h-5 w-5 md:w-10 md:h-10 text-gray-600"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
              </svg>
            </div>

            <div className="flex items-center justify-center bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="h-5 w-5 md:w-10 md:h-10 text-gray-600"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
              </svg>
            </div>

            <div className="flex items-center justify-center bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="h-5 w-5 md:w-10 md:h-10 text-gray-600"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
              </svg>
            </div>

            <div className="flex items-center justify-center bg-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="h-5 w-5 md:w-10 md:h-10 text-gray-600"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="md:w-2/5 h-fit">
          <div className="h-80">
            <div className="mb-8">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            </div>

            <div className="mb-8">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            </div>

            <div className="mb-8">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            </div>

            <div className="mb-8">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            </div>
          </div>
        </div>

        <span className="sr-only">Loading...</span>
      </div> */}

      {/* <div className="w-full  hidden md:block mt-10">
        <div className="mb-8">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        </div>

        <div className="mb-8">
          <div className="h-2.5  rounded-full bg-gray-700 w-48 mb-4"></div>
          <div className="h-2  rounded-full bg-gray-700 max-w-[480px] mb-2.5"></div>
          <div className="h-2  rounded-full bg-gray-700 mb-2.5"></div>
          <div className="h-2  rounded-full bg-gray-700 max-w-[440px] mb-2.5"></div>
          <div className="h-2  rounded-full bg-gray-700 max-w-[460px] mb-2.5"></div>
          <div className="h-2  rounded-full bg-gray-700 max-w-[360px]"></div>
        </div>
      </div>

      <div className="mt-64 md:mt-0 -z-10 grid grid-cols-1 lsm:grid-cols-2  md:grid-cols-2 smd:grid-cols-3 gap-5 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        {Array.from({ length: cardNum }, (_, i) => (
          <ServicesLoaderCard key={i} />
        ))}
      </div> */}
    </div>
  );
};

export default ServiceDetailsLoader;
