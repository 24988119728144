import StarRatings from "./StarRatings";
import ReviewTab from "./ReviewTab";
import Dropdown from "../../commons/DropDown";

const Reviews: React.FC<{
  service?: string;
  businessName?: string;
}> = ({ service, businessName }) => {
  const options = [
    { value: "recent", label: "Recent" },
    { value: "low-to-high", label: "Ratings: Low to High" },
    { value: "high-to-low", label: "Ratings: High to Low" },
  ];

  const handleDropdownChange = (value: string) => {
    // console.log("Selected value:", value);
  };
  return (
    <div>
      <div className="mt-7">
        <StarRatings />
      </div>

      <div>
        <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between mt-20">
          <h1 className="mb-3 sm:mb-0 text-white text-xs md:text-sm pt-1">
            Service Reviews (3)
          </h1>

          <Dropdown
            options={options}
            defaultValue="recent"
            onChange={handleDropdownChange}
          />
        </div>
      </div>

      <div className="overflow-y-auto max-h-[36rem]">
        <div>
          <div className="border-[0.5px] border-gray-700 mt-4"></div>

          {Array.from({ length: 5 }, (_, i) => (
            <ReviewTab
              key={i}
              comment="He really rendered this the way it is supposed to, he tried his best."
              date="2024-11-20"
              name="James Manuel"
              downVoteNumber={0}
              upVoteNumber={3}
              rating={4}
            />
          ))}
        </div>
      </div>

      <div className="flex justify-between text-[13px] text-gray-300 mt-3">
        <div className="t">Page 1 of 1</div>

        <div className="flex">
          <div>Prev</div>

          <div className="bg-green-600 text-white mx-3 px-2 rounded-md">1</div>

          <div>Next</div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
