import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useUpdateUserInfoMutation,
  useUpdateUserPasswordMutation,
} from "../../../lib/apis/userApi";
import ButtonLoader from "../../commons/ButtonLoader";
import Icon from "../../commons/Icon";
import { notify } from "../../../helpers/notify";
import styles from "../Profile.module.css";

interface OnLoadFn {
  (dataURL: string | ArrayBuffer | null): void;
}

const Profile: React.FC = () => {
  const [editInfo, setEditInfo] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    new_password: "",
    confirm_password: "",
  });
  const [prevImage, setPrevImage] = useState("");
  const [userData, setUserData] = useState<{
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    about: string;
    avatar: null | string;
  }>({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    about: "",
    avatar: null,
  });

  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const [imageFileToUpload, setImageFileToUpload] = useState<File | null>(null);

  const { user } = useSelector((state: any) => state.userState);

  const [updateUserInfo, { isLoading, isSuccess, isError, error }] =
    useUpdateUserInfoMutation();

  const [
    updateUserPassword,
    {
      isLoading: isPasswordLoading,
      isSuccess: isPasswordSuccess,
      isError: isPasswordError,
    },
  ] = useUpdateUserPasswordMutation();

  useEffect(() => {
    setUserData({
      firstName: user?.first_name,
      lastName: user?.last_name,
      middleName: user?.middle_name,
      email: user?.email,
      about: user?.about_me,
      avatar: user?.avatar,
    });
  }, []);

  useEffect(() => {
    const showAlert = () => {
      if (isError) {
        return notify("Profile update failed", "error");
      }

      if (isSuccess) {
        setEditInfo(false);
        return notify("Profile update successful", "success");
      }
      if (isPasswordError) {
        return notify("Password update failed", "error");
      }

      if (isPasswordSuccess) {
        setEditPassword(false);
        return notify("Password update successful", "success");
      }
    };

    showAlert();
  }, [isError, isSuccess, isPasswordError, isPasswordSuccess]);

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const onLoadFn: OnLoadFn = (dataURL) => {
      if (typeof dataURL === "string") {
        const type = atob(btoa(dataURL)).split(":")[1].split(";")[0];
        if (
          type !== "image/png" &&
          type !== "image/jpg" &&
          type !== "image/jpeg"
        ) {
          setPrevImage("");
          return;
          // return setErrorMessage("Invalid image format");
        }

        setPrevImage(dataURL);
      }
    };

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => onLoadFn(reader.result));
      reader.readAsDataURL(event.target.files[0]);

      setImageFileToUpload(event.target.files[0]);
      // setUserData({ ...userData, avatar: event.target.files[0] });
    }
  };

  const onUpdateUserInfo = async () => {
    const formData = new FormData();

    formData.append("first_name", userData?.firstName);
    formData.append("last_name", userData?.lastName);
    formData.append("middle_name", userData?.middleName);
    formData.append("about", userData?.about);
    if (imageFileToUpload) {
      formData.append("avatar", imageFileToUpload);
    }

    await updateUserInfo(formData);
  };

  const onUpdateUserPassword = async () => {
    await updateUserPassword(passwordData);
  };

  return (
    <main
      className={`flex w-full flex-grow flex-col lg:flex-grow-0 lg:bg-light lg:px-12 npm lg:dark:bg-dark-250`}
    >
      <div
        className="flex min-h-full flex-grow flex-col"
        style={{ opacity: 1, transform: "none" }}
      >
        <h1 className="mb-5 text-15px font-medium text-cyan-50 dark:text-light sm:mb-6">
          Personal Information
        </h1>
        <div className="flex flex-grow flex-col">
          <fieldset className="mb-6 grid gap-5 pb-5 sm:grid-cols-2 md:pb-9 lg:mb-8">
            <div className="sm:col-span-2">
              <div className="text-xs">
                <div className="flex flex-wrap gap-2.5">
                  <div
                    role="presentation"
                    tabIndex={0}
                    className={`${styles.logo_border} relative border-dashed border-2 border-light-500 dark:border-dark-600 text-center flex flex-col justify-center hover:text-black dark:hover:text-light items-center cursor-pointer focus:border-accent-400 focus:outline-none h-36 w-full rounded`}
                  >
                    <div className="h-full flex justify-center items-center">
                      {!prevImage && !userData?.avatar && (
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQABqQIdskCD9BK0I81EbVfV9tTz320XvJ35A&s"
                          alt="upload_image_preview"
                          className={styles.logo_preview}
                        />
                      )}

                      {prevImage && (
                        <img
                          src={prevImage}
                          alt="upload_image_preview"
                          className={styles.logo_preview}
                        />
                      )}

                      {userData?.avatar && !prevImage && (
                        <img
                          src={userData?.avatar}
                          alt="upload_image_preview"
                          className={styles.logo_preview}
                        />
                      )}

                      {editInfo && (
                        <div>
                          <a
                            className={styles.upload_btn}
                            href="#"
                            // onClick={onShowProfileModal}
                          >
                            <label
                              htmlFor="logo"
                              className="flex items-center justify-center w-full cursor-pointer"
                            >
                              <input
                                id="logo"
                                type="file"
                                className="hidden"
                                accept="image/*"
                                onInput={onSelectFile}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-camera-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                              </svg>
                            </label>
                          </a>
                        </div>
                      )}
                    </div>
                    {editInfo && (
                      <p className="text-[10px] text-white -mt-4">
                        Images should not be larger than 500kb
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label className="block text-13px">
                <span className="block cursor-pointer pb-2.5 font-normal text-zinc-400 rtl:text-right dark:text-light/70">
                  First Name
                </span>
                <input
                  disabled={!editInfo}
                  type="text"
                  name="name"
                  className={`${styles.profile_input} 
                   h-11 w-full text-cyan-50   rounded border  px-4 py-2  bg-transparent`}
                  value={userData?.firstName}
                  onChange={(event) =>
                    setUserData({ ...userData, firstName: event.target.value })
                  }
                />
              </label>
            </div>

            <div>
              <label className="block text-13px">
                <span className="block cursor-pointer pb-2.5 font-normal text-zinc-400 rtl:text-right dark:text-light/70">
                  Last Name
                </span>
                <input
                  disabled={!editInfo}
                  type="text"
                  name="name"
                  className={`${styles.profile_input}  
                   h-11 w-full text-cyan-50   rounded border  px-4 py-2  bg-transparent`}
                  value={userData?.lastName}
                  onChange={(event) =>
                    setUserData({ ...userData, lastName: event.target.value })
                  }
                />
              </label>
            </div>

            <div>
              <label className="block text-13px">
                <span className="block cursor-pointer pb-2.5 font-normal text-zinc-400 rtl:text-right dark:text-light/70">
                  Email
                </span>
                <input
                  disabled
                  type="text"
                  name="name"
                  className={`${styles.profile_input}  h-11 w-full text-cyan-50  rounded border  px-4 py-2  bg-transparent`}
                  value={userData?.email}
                  onChange={(event) =>
                    setUserData({ ...userData, email: event.target.value })
                  }
                />
              </label>
            </div>

            <div className="text-13px block">
              <span className="block cursor-pointer pb-2.5 font-normal text-zinc-400 rtl:text-right dark:text-light/70">
                Middle Name
              </span>
              <input
                disabled={!editInfo}
                type="text"
                name="name"
                className={`${styles.profile_input}   h-11 w-full text-cyan-50  rounded border  px-4 py-2  bg-transparent`}
                value={userData?.middleName}
                onChange={(event) =>
                  setUserData({ ...userData, middleName: event.target.value })
                }
              />
            </div>
            <div className="sm:col-span-2">
              <label className="block text-13px">
                <span className=" text-cyan-50 block cursor-pointer pb-2.5 font-normal text-dark/70 dark:text-light/70">
                  About
                </span>
                <textarea
                  disabled={!editInfo}
                  value={userData?.about}
                  placeholder="Describe yourself..."
                  name="profile.bio"
                  onChange={(event) =>
                    setUserData({ ...userData, about: event.target.value })
                  }
                  className={` ${styles.profile_input}  text-cyan-50 min-h-[150px] w-full appearance-none rounded border border-light-500 px-4 py-3 text-13px text-dark ring-[0.5px] ring-light-500 placeholder:text-dark-900 focus:border-brand focus:ring-[0.5px] focus:ring-brand dark:border-dark-600 dark:text-light dark:ring-dark-600 dark:placeholder:text-dark-700 dark:focus:border-brand dark:focus:ring-brand lg:px-5 bg-transparent`}
                ></textarea>
              </label>
            </div>
          </fieldset>
          <div className="mt-auto flex items-center gap-4 pb-3 lg:justify-end">
            {editInfo && (
              <>
                <button
                  onClick={() => setEditInfo(false)}
                  className={`${styles.profile_btn3} transition-fill-colors flex items-center justify-center gap-2 font-semibold duration-200 pointer-events-auto cursor-pointer opacity-100 min-h-[46px] sm:h-12 rounded py-3 px-4 md:px-5 bg-brand text-white hover:bg-brand-dark focus:bg-brand-dark flex-1 lg:flex-none`}
                >
                  Cancel
                </button>
                <button
                  onClick={onUpdateUserInfo}
                  type="button"
                  className={`${styles.profile_btn2} transition-fill-colors flex items-center justify-center gap-2 font-semibold duration-200 pointer-events-auto cursor-pointer opacity-100 min-h-[46px] sm:h-12 rounded py-3 px-4 md:px-5 bg-brand text-white hover:bg-brand-dark focus:bg-brand-dark flex-1 lg:flex-none`}
                >
                  {isLoading ? <ButtonLoader /> : "Save change"}
                </button>
              </>
            )}

            {!editInfo && (
              <button
                type="button"
                className={`${styles.profile_btn} transition-fill-colors flex items-center justify-center gap-2 font-semibold duration-200 pointer-events-auto cursor-pointer opacity-100 min-h-[46px] sm:h-12 rounded py-3 px-4 md:px-5 bg-brand text-white hover:bg-brand-dark focus:bg-brand-dark flex-1 lg:flex-none`}
                onClick={() => setEditInfo(true)}
              >
                Edit Profile
              </button>
            )}
          </div>
        </div>

        <h1 className="mb-5 text-15px font-medium text-cyan-50 dark:text-light sm:mb-6">
          Password
          <span className="block text-[10px] mt-1 text-zinc-400">
            Update your password
          </span>
        </h1>

        <div className="flex flex-grow flex-col">
          <fieldset className="mb-6 grid gap-5 pb-5 sm:grid-cols-2 md:pb-9 lg:mb-8">
            <div>
              <label className="block text-13px">
                <span className="block cursor-pointer pb-2.5 font-normal text-zinc-400 rtl:text-right dark:text-light/70">
                  Current Password
                </span>
                <input
                  disabled={!editPassword}
                  type={oldPasswordType}
                  name="name"
                  className={`${styles.profile_input} ${
                    !editInfo && "disabled"
                  } h-11 w-full text-cyan-50   rounded border  px-4 py-2  bg-transparent`}
                  value={passwordData.oldPassword}
                  onChange={(event) =>
                    setPasswordData({
                      ...passwordData,
                      oldPassword: event.target.value,
                    })
                  }
                />

                <Icon
                  iconClasses="fa-regular fa-eye  -ml-7 cursor-pointer text-gray-400"
                  onClickIcon={() =>
                    oldPasswordType === "password"
                      ? setOldPasswordType("text")
                      : setOldPasswordType("password")
                  }
                />
              </label>
            </div>

            <div>
              <label className="block text-13px">
                <span className="block cursor-pointer pb-2.5 font-normal text-zinc-400 rtl:text-right dark:text-light/70">
                  New Password
                </span>
                <input
                  disabled={!editPassword}
                  type={newPasswordType}
                  name="name"
                  className={`${styles.profile_input}   h-11 w-full text-cyan-50   rounded border  px-4 py-2  bg-transparent`}
                  value={passwordData.new_password}
                  onChange={(event) =>
                    setPasswordData({
                      ...passwordData,
                      new_password: event.target.value,
                    })
                  }
                />

                <Icon
                  iconClasses="fa-regular fa-eye  -ml-7 cursor-pointer text-gray-400"
                  onClickIcon={() =>
                    newPasswordType === "password"
                      ? setNewPasswordType("text")
                      : setNewPasswordType("password")
                  }
                />
              </label>
            </div>

            <div>
              <label className="block text-13px">
                <span className="block cursor-pointer pb-2.5 font-normal text-zinc-400 rtl:text-right dark:text-light/70">
                  Confirm Password
                </span>
                <input
                  disabled={!editPassword}
                  type={confirmPasswordType}
                  name="name"
                  className={`${styles.profile_input}   h-11 w-full text-cyan-50  rounded border  px-4 py-2  bg-transparent`}
                  value={passwordData.confirm_password}
                  onChange={(event) =>
                    setPasswordData({
                      ...passwordData,
                      confirm_password: event.target.value,
                    })
                  }
                />

                <Icon
                  iconClasses="fa-regular fa-eye  -ml-7 cursor-pointer text-gray-400"
                  onClickIcon={() =>
                    confirmPasswordType === "password"
                      ? setConfirmPasswordType("text")
                      : setConfirmPasswordType("password")
                  }
                />
              </label>
            </div>
          </fieldset>
          <div className="mt-auto flex items-center gap-4 pb-3 lg:justify-end">
            {editPassword && (
              <>
                <button
                  onClick={() => setEditPassword(false)}
                  className={`${styles.profile_btn3} transition-fill-colors flex items-center justify-center gap-2 font-semibold duration-200 pointer-events-auto cursor-pointer opacity-100 min-h-[46px] sm:h-12 rounded py-3 px-4 md:px-5 bg-brand text-white hover:bg-brand-dark focus:bg-brand-dark flex-1 lg:flex-none`}
                >
                  Cancel
                </button>
                <button
                  onClick={onUpdateUserPassword}
                  type="button"
                  className={`${styles.profile_btn2} transition-fill-colors flex items-center justify-center gap-2 font-semibold duration-200 pointer-events-auto cursor-pointer opacity-100 min-h-[46px] sm:h-12 rounded py-3 px-4 md:px-5 bg-brand text-white hover:bg-brand-dark focus:bg-brand-dark flex-1 lg:flex-none`}
                >
                  {isPasswordLoading ? <ButtonLoader /> : "Save change"}
                </button>
              </>
            )}

            {!editPassword && (
              <button
                type="button"
                className={`${styles.profile_btn} transition-fill-colors flex items-center justify-center gap-2 font-semibold duration-200 pointer-events-auto cursor-pointer opacity-100 min-h-[46px] sm:h-12 rounded py-3 px-4 md:px-5 bg-brand text-white hover:bg-brand-dark focus:bg-brand-dark flex-1 lg:flex-none`}
                onClick={() => setEditPassword(true)}
              >
                Update Password
              </button>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
