import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCreateExpertServiceMutation } from "../../lib/apis/serviceApis";
import { VideoToFrames, VideoToFramesMethod } from "../../helpers/VideoToFrame";
import { useGetLoggedInExpertsMutation } from "../../lib/apis/expertApis";
import useFormValidation from "../../hooks/useFormValidation";
import ServiceVideoPreview from "./ServiceVideoPreveiw";
import ImageCropModal from "./ImageCropModal";
import FileUploadSpinner from "./FileUploadSpinner";
import CreateServiceDetailsForm from "./CreateServiceDetailsForm";
import SuccessModal from "../commons/SuccessModal";
import ErrorModal from "../commons/ErrorModal";
import Icon from "../commons/Icon";
import styles from "./Services.module.css";
import { convertBase64File, convertBlobImage } from "../../helpers/files";

interface FileInputEvent extends React.ChangeEvent<HTMLInputElement> {}

interface OnLoadFn {
  (dataURL: string | ArrayBuffer | null): void;
}

const CreateService: React.FC = () => {
  const [banner, setBanner] = useState<File | string>("");
  const [prevImage, setPrevImage] = useState("");
  const [prevVideo, setPrevVideo] = useState("");
  const [category, setCategory] = useState<string>("");
  const [description, setDescription] = useState("");
  const [plainDesc, setPlainDesc] = useState("");
  const [price, setPrice] = useState<string>("");
  const [explaner_video, setExplanerVideo] = useState<File | string>("");
  const [stage, setStage] = useState<number>(1);
  const [bannerUploadIsLoading, setBannerUploadIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [generatedThumbnail, setGeneratedThumbnails] = useState<string[]>([]);
  const [status, setStatus] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [allBenefits, setAllBenefits] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [showInvalidFormError, setShowInvalidFormError] = useState(false);

  // const modalCtx = useContext(ModalContext);

  const [uploadPercentage, setUploadPercentagage] = useState(0);

  // validate registration form hook
  const [formIsValid, formError, validateFormInputs] = useFormValidation() as [
    boolean,
    { field: string; error: string },
    (type: string, formProps: any) => void
  ];

  const [inlineResult, setInlineResult] = useState<string>("");

  const navigate = useNavigate();

  const [getLoggedInExpert] = useGetLoggedInExpertsMutation();

  const [
    createExpertService,
    { isLoading, isSuccess, isError, data: serviceData, error },
  ] = useCreateExpertServiceMutation();

  useEffect(() => {
    if (isSuccess && serviceData) {
      setModalIsOpen(true);
    }
  }, [isSuccess, serviceData]);

  useEffect(() => {
    if (isError && error) {
      setErrorModalIsOpen(true);
    }
  }, [isError, error]);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const onClickImageUpload = (event: React.MouseEvent<HTMLElement>) => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setUploadPercentagage(0);
    setPrevImage("");
    setBanner("");
    setInlineResult("");
    setBannerUploadIsLoading(false);
  };

  const onDeleteSelectedVideo = (event: React.MouseEvent<HTMLElement>) => {
    setPrevVideo("");
    setExplanerVideo("");
    setGeneratedThumbnails([]);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setUploadPercentagage(0);
    setPrevImage("");
    setBanner("");
    setInlineResult("");
    setBannerUploadIsLoading(false);
  };

  const incrementUploadPercentage = () => {
    setBannerUploadIsLoading(true);
    let max = 100;
    setUploadPercentagage((prev) => (prev === max ? prev : prev + 10));
  };

  useEffect(() => {
    const changeImageLoadingState = () => {
      if (uploadPercentage === 100) {
        setBannerUploadIsLoading(false);
      }
    };

    changeImageLoadingState();
  }, [uploadPercentage]);

  const onLoadFn: OnLoadFn = (dataURL) => {
    if (typeof dataURL === "string") {
      const type = atob(btoa(dataURL)).split(":")[1].split(";")[0];
      if (
        type !== "image/png" &&
        type !== "image/jpg" &&
        type !== "image/jpeg"
      ) {
        setPrevImage("");
        return;
      }

      timerRef.current = setInterval(() => {
        incrementUploadPercentage();
      }, 500);
      setPrevImage(dataURL);
    }
  };

  const onLoadVideoFn: OnLoadFn = (dataURL) => {
    try {
      if (typeof dataURL === "string") {
        const type = atob(btoa(dataURL)).split(":")[1].split(";")[0];
        if (type !== "video/mp4" && type !== "image/avi") {
          setPrevVideo("");
          return;
        }

        setPrevVideo(dataURL);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectFile = (event: FileInputEvent): void => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => onLoadFn(reader.result));
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onSelectVideoFile = async (event: FileInputEvent) => {
    setGeneratedThumbnails([]);
    setStatus(true);
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => onLoadVideoFn(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setExplanerVideo(event.target.files[0]);

      const [file] = event.target.files;
      const fileUrl = URL.createObjectURL(file);

      if (fileUrl) {
        const frames = await VideoToFrames.getFrames(
          fileUrl,
          20,
          VideoToFramesMethod.totalFrames
        );

        // setStatus("IDLE");
        if (frames && frames?.length > 0) {
          setStatus(false);
          setGeneratedThumbnails(frames);
        }
      }
    }
  };

  // get cropped image
  const onDoneCropping = (inlineResult: string) => {
    setInlineResult(inlineResult);

    setPrevImage("");
    const onConvertBlobToFile = async () => {
      const result = await convertBlobImage(inlineResult);
      if (result) {
        return setBanner(result);
      }
    };

    onConvertBlobToFile();
  };

  useEffect(() => {
    getLoggedInExpert(null);
  }, []);

  const onSubmitServiceData = async (event: React.FormEvent) => {
    event.preventDefault();

    const formData = new FormData();

    // const croppedThumbnail = new File([inlineResult], "thumbnail");

    formData.append("title", title);
    formData.append("description", description);
    formData.append("lowest_acceptable_amount", price);
    formData.append("thumbnail", banner);
    formData.append("explainer_video", explaner_video);
    formData.append("currency", "1");
    formData.append("years_of_experience", "1");
    formData.append("service_category", category);
    formData.append("benefits_of_service", JSON.stringify(allBenefits));

    if (!formIsValid) return setShowInvalidFormError(true);

    await createExpertService(formData);
  };

  const onChangeServiceDescription = (
    description?: string,
    plainDescription?: string
  ) => {
    if (description && plainDescription) {
      setDescription(description);
      setPlainDesc(plainDescription);
    }
  };

  useEffect(() => {
    if (typeof validateFormInputs === "function") {
      const timer = setTimeout(() => {
        validateFormInputs("create-service", {
          title,
          price,
          description: plainDesc,
          category,
          banner: banner,
          explaner_video,
          allBenefits,
        });
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [title, price, category, banner, explaner_video, plainDesc, allBenefits]);

  useEffect(() => {
    if (
      formError.field === "explainer_video" ||
      formError.field === "thumbnail"
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [formError]);

  useEffect(() => {
    const onSetBannerFromGeneratedThumbnail = async () => {
      if (generatedThumbnail.length > 0) {
        const result = await convertBase64File(generatedThumbnail[10]);

        if (result) return setBanner(result);
      }
    };

    onSetBannerFromGeneratedThumbnail();
  }, [generatedThumbnail]);

  return (
    <>
      {modalIsOpen && (
        <SuccessModal
          message="Service Created Successfully"
          open={modalIsOpen}
          onCloseModal={() => navigate(`/expert/profile/services`)}
        />
      )}

      {errorModalIsOpen && (
        <ErrorModal
          message="Service creation failed"
          buttonText="Try Again"
          open={errorModalIsOpen}
          onCloseModal={() => setErrorModalIsOpen(false)}
        />
      )}

      {showInvalidFormError && (
        <ErrorModal
          message={formError.error}
          buttonText="OK !"
          open={showInvalidFormError}
          onCloseModal={() => setShowInvalidFormError(false)}
        />
      )}

      <div className={`relative ${styles.service_details}`}>
        <div className={`${styles.close_container}  rounded-t`}>
          <div className={styles.service_intro}>
            <p className="md:text-3xl xl:text-4xl mb-1 text-lg">
              Post a service on DeskZone
            </p>
            <h1 className="text-xs  md:text-sm">
              We will help you earn good value from your skills.
            </h1>

            {stage === 2 && (
              <div className="flex justify-end items-center">
                <Link
                  to={""}
                  className="text-cyan-50"
                  onClick={() => setStage(1)}
                >
                  Go back <Icon iconClasses="fa-solid fa-arrow-left" />
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.service_form2} flex justify-center`}>
          <div className="w-3/4 px-5 lg:pr-5">
            <div className="flex mb-9 items-start justify-between">
              <h3 className="font-semibold text-cyan-50 text-[10px] md:text-2xl">
                Tell us about your service
                <span className={`${styles.intro} text-[10px] md:text-xs`}>
                  {stage === 1
                    ? "Select a video and thumbnail for your service"
                    : "Provide more details about your service"}
                </span>
              </h3>

              <p className="text-cyan-50 mr-1 text-[10px] md:text-sm">
                {stage} / 2
              </p>
            </div>
            <form onSubmit={onSubmitServiceData}>
              {stage === 1 && (
                <>
                  {" "}
                  <div className="w-full mt-5">
                    <div className="">
                      <div className="flex -mb-2">
                        {formError && formError.field === "explainer_video" && (
                          <p className={styles.intro2}>{formError.error}</p>
                        )}
                        <div
                          className={`${styles.thrash} ml-auto px-3 text-sm`}
                        >
                          {prevVideo && (
                            <Icon
                              iconClasses="fa-regular cursor-pointer fa-trash-can"
                              onClickIcon={onDeleteSelectedVideo}
                            />
                          )}
                        </div>
                      </div>
                      <label
                        htmlFor="dropzone-file"
                        className={`${
                          prevVideo
                            ? "flex flex-col items-center justify-center"
                            : "flex justify-center items-center px-7"
                        }  w-full  mb-5 h-96 border-2  border-dashed rounded-lg cursor-pointer hover:bg-gray-800 bg-gray-700  border-gray-600`}
                      >
                        {!prevVideo && (
                          <div className="w-full flex flex-col justify-center items-center">
                            <svg
                              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                              or drag and drop video
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              MP4, AVI
                            </p>

                            <input
                              id="dropzone-file"
                              type="file"
                              className="hidden"
                              accept="video/*"
                              onInput={onSelectVideoFile}
                            />
                          </div>
                        )}

                        {prevVideo && (
                          <div className="h-full w-full">
                            <ServiceVideoPreview
                              serviceVideo={prevVideo}
                              thumbnail={
                                inlineResult
                                  ? inlineResult
                                  : generatedThumbnail[10]
                              }
                            />
                          </div>
                        )}
                      </label>

                      <div className="md:flex items-center gap-4">
                        <div className="w-full">
                          {status && (
                            <div
                              className={`${styles.thrash}  relative flex justify-between px-3  gap-2 items-center  text-sm`}
                            >
                              <FileUploadSpinner />
                            </div>
                          )}
                          <label
                            // htmlFor="ai-thumbnail"
                            className={`${
                              generatedThumbnail &&
                              generatedThumbnail.length <= 0
                                ? "flex flex-col items-center justify-center px-7  hover:bg-gray-800 bg-gray-700 "
                                : "flex justify-center items-center"
                            }  w-full  mb-5  h-48 border-2  border-dashed rounded-lg cursor-pointer border-gray-600 `}
                          >
                            {generatedThumbnail.length <= 0 && (
                              <div className="w-full">
                                <>
                                  <svg
                                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                  >
                                    <path
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                  </svg>
                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                      Auto-generated thumbnail
                                    </span>
                                  </p>
                                </>
                              </div>
                            )}

                            {generatedThumbnail &&
                              generatedThumbnail.length > 0 && (
                                <img
                                  src={generatedThumbnail[10]}
                                  alt="upload_image_preview"
                                  className="w-full h-full object-cover rounded-lg opacity-60"
                                />
                              )}
                          </label>
                        </div>

                        <div className="w-full">
                          {bannerUploadIsLoading && !inlineResult && (
                            <div
                              className={`${styles.thrash}  relative flex justify-between px-3  gap-2 items-center  text-sm`}
                            >
                              <FileUploadSpinner />
                            </div>
                          )}
                          <label
                            htmlFor="thumbnail"
                            className={`${
                              inlineResult
                                ? "flex flex-col items-center justify-center"
                                : "flex justify-center items-center px-7"
                            }  w-full  mb-5  h-48 border-2  border-dashed rounded-lg cursor-pointer hover:bg-gray-800 bg-gray-700  border-gray-600 `}
                          >
                            <div className="w-full">
                              <div className="flex -mb-2">
                                {/* <p className={styles.intro}>Video</p> */}
                                <div
                                  className={`${styles.thrash} ml-auto   px-3  text-sm`}
                                >
                                  {inlineResult && (
                                    <Icon
                                      iconClasses="fa-regular cursor-pointer fa-trash-can"
                                      onClickIcon={onClickImageUpload}
                                    />
                                  )}
                                </div>
                              </div>

                              {uploadPercentage < 100 && (
                                <>
                                  <svg
                                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                  >
                                    <path
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                  </svg>
                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>{" "}
                                    or drag and drop thumbnail
                                  </p>
                                  <p className="text-xs text-gray-500 dark:text-gray-400">
                                    PNG, JPG and JPEG
                                  </p>

                                  <input
                                    id="thumbnail"
                                    type="file"
                                    className="hidden"
                                    accept="image/jpeg, image/png, image/jpg"
                                    onInput={onSelectFile}
                                  />
                                </>
                              )}
                            </div>

                            {inlineResult && (
                              <div className="h-full w-full flex justify-center">
                                <img
                                  src={inlineResult}
                                  alt="upload_image_preview"
                                  className="w-full h-full object-cover rounded-lg opacity-60"
                                />
                              </div>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {uploadPercentage >= 100 && (
                    <ImageCropModal
                      prevImage={prevImage}
                      uploadPercentage={uploadPercentage}
                      onDoneCropping={onDoneCropping}
                    />
                  )}
                  <div className="my-5 flex justify-end">
                    <button
                      className={`${styles.service_button} font-semibold  rounded py-3 px-4 md:px-5  text-white`}
                      type="button"
                      // disabled={isDisabled}
                      onClick={() =>
                        !isDisabled
                          ? setStage(2)
                          : setShowInvalidFormError(true)
                      }
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {stage === 2 && (
                <CreateServiceDetailsForm
                  onChangeServiceTitle={(title: string) => setTitle(title)}
                  title={title}
                  onSelectCategory={(category: string) => setCategory(category)}
                  category={category}
                  onChangeServiceDescription={onChangeServiceDescription}
                  description={description}
                  onChangePrice={(price: string) => setPrice(price)}
                  price={price}
                  formError={formError}
                  formIsValid={formIsValid}
                  isLoading={isLoading}
                  onChangeBenefits={(benefits: string[]) =>
                    setAllBenefits(benefits)
                  }
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateService;
