import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import ProfileLoader from "../common/ProfileLoader";
import OngoingMessages from "./OngoingMessages";
import ArchivedMessages from "./ArchivedMessages";
import {
  useGetAllInboxMutation,
  useMarkMessageAsReadMutation,
  useMarkMessageAsUnReadMutation,
  useArchiveMessageMutation,
  useUnArchiveMessageMutation,
  useGetAllArchiveMessagesMutation,
} from "../../../lib/apis/messageApis";
import { notify } from "../../../helpers/notify";
import styles from "../Profile.module.css";

const ClientMessages: React.FC = () => {
  const { inboxes } = useSelector((state: any) => state.inboxState);
  const { archiveMessages } = useSelector(
    (state: any) => state.archiveMessageState
  );

  const [currentLocation, setCurrentLocation] = useState("Ongoing");

  const [getAllInbox, { data, isLoading, isSuccess }] =
    useGetAllInboxMutation();

  const [getAllArchiveMessages, { data: archiveData }] =
    useGetAllArchiveMessagesMutation();

  const [
    markMessageAsRead,
    {
      isSuccess: markAsReadSuccess,
      isError: MarkAsReadError,
      isLoading: markAsReadLoading,
    },
  ] = useMarkMessageAsReadMutation();

  const [
    archiveMessage,
    {
      isSuccess: archiveSuccess,
      isError: achiveError,
      isLoading: achiveMessageLoading,
    },
  ] = useArchiveMessageMutation();

  const [
    markMessageAsUnRead,
    {
      isSuccess: markAsUnReadSuccess,
      isError: markAsUnReadError,
      isLoading: markAsUnreadLoading,
    },
  ] = useMarkMessageAsUnReadMutation();

  const [
    unArchiveMessage,
    {
      isSuccess: unArchiveMessageSuccess,
      error: unArchiveMessageError,
      isLoading: unArchiveMessageIsLoading,
    },
  ] = useUnArchiveMessageMutation();

  useEffect(() => {
    getAllInbox(null);
    getAllArchiveMessages(null);
  }, []);

  useEffect(() => {
    const showAlert = () => {
      if (
        MarkAsReadError ||
        markAsUnReadError ||
        achiveError ||
        unArchiveMessageError
      ) {
        return notify("Something went wrong", "error");
      }

      if (archiveSuccess) {
        return notify("Message archived successfully", "success");
      }
      if (markAsReadSuccess) {
        return notify("Message marked as read successfully", "success");
      }

      if (markAsUnReadSuccess) {
        return notify("Message marked as unread successfully", "success");
      }

      if (unArchiveMessageSuccess) {
        return notify("Message unarchived successfully", "success");
      }
    };

    showAlert();
  }, [
    markAsReadSuccess,
    markAsUnReadError,
    archiveSuccess,
    achiveError,
    markAsUnReadError,
    markAsUnReadSuccess,
    MarkAsReadError,
  ]);

  return (
    <>
      {isLoading && <ProfileLoader />}
      {isSuccess && (
        <main className="flex w-full flex-grow flex-col pb-10 lg:flex-grow-0 lg:bg-light px-5 lg:px-12 lg:py-8 lg:dark:bg-dark-250">
          <div
            className="flex min-h-full flex-grow flex-col"
            style={{ opacity: "1", transform: "none" }}
          >
            <div
              className={`${styles.wishlist_category} p-2 mb-5 lg:pl-0 flex justify-between`}
            >
              <div className="">
                <h1 className="text-15px font-medium text-cyan-50">
                  {currentLocation === "Ongoing"
                    ? `Ongoing Messages 
                (${inboxes?.length})`
                    : `Archived Messages (${archiveMessages?.length})`}
                </h1>
              </div>
            </div>

            <AnimatePresence>
              <div>
                <div className="relative flex gap-3 md:gap-0 text-13px rtl:space-x-reverse lg:space-x-8">
                  <Link
                    onClick={() => setCurrentLocation("Ongoing")}
                    className={`
                    ${
                      currentLocation === "Ongoing"
                        ? styles.message_nav_active
                        : styles.message_nav
                    }
                relative cursor-pointer pb-3.5 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-dark-400 before:transition-all before:duration-300 before:ease-in-out hover:text-dark-100 dark:before:bg-light-400 dark:hover:text-light font-semibold text-dark-100 before:w-full dark:text-light`}
                    to={""}
                  >
                    Ongoing
                  </Link>

                  <Link
                    onClick={() => setCurrentLocation("Archived")}
                    className={`
                    ${
                      currentLocation === "Archived"
                        ? styles.message_nav_active
                        : styles.message_nav
                    }
                relative cursor-pointer pb-3.5 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-dark-400 before:transition-all before:duration-300 before:ease-in-out hover:text-dark-100 dark:before:bg-light-400 dark:hover:text-light font-semibold text-dark-100 before:w-full dark:text-light`}
                    to={""}
                  >
                    Achived
                  </Link>
                </div>
              </div>

              {currentLocation === "Ongoing" && (
                <div className="mt-10">
                  <motion.div
                    exit={{ opacity: 0, scale: 1.1 }}
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                  >
                    <OngoingMessages
                      messages={inboxes}
                      onMarkMessageAsRead={(ids: string[]) =>
                        markMessageAsRead(ids)
                      }
                      onArchiveMessage={(ids: string[]) => archiveMessage(ids)}
                      onMarkMessageAsUnread={(ids: string[]) =>
                        markMessageAsUnRead(ids)
                      }
                      markAsReadLoading={markAsReadLoading}
                      achiveMessageLoading={achiveMessageLoading}
                      markAsUnreadLoading={markAsUnreadLoading}
                    />
                  </motion.div>
                </div>
              )}

              {currentLocation === "Archived" && (
                <div className="mt-10">
                  <motion.div
                    exit={{ opacity: 0, scale: 1.1 }}
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                  >
                    <ArchivedMessages
                      messages={archiveMessages}
                      onRemoveMessageFromArchive={(ids: string[]) =>
                        unArchiveMessage(ids)
                      }
                      unArchiveMessageIsLoading={unArchiveMessageIsLoading}
                    />
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
          </div>
        </main>
      )}
    </>
  );
};

export default ClientMessages;
