import { useState } from "react";
import { useLocation } from "react-router-dom";
import useScrolling from "../hooks/useScroll";
import NavigationBar from "./NavigationBar";
import SideNavigationBar from "./SideNavigationBar";
import ExpertServicesFilterTab from "./ExpertServicesFilterTab";
import Notification from "../components/commons/Notification";
import { SEO } from "../helpers/SEO";
import BottomNav from "./BottomNav";
import MobileSearchInput from "./MobileSearchInput";
import MobileSideBar from "./MobileSideBar";
import AppRoutes from "./AppRoutes";
import styles from "./Layout.module.css";

const Layout: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isScrolling] = useScrolling();

  const { pathname } = useLocation();

  const onOpenMobileSideBar = () => {
    if (isOpen) {
      return setIsOpen(false);
    }

    return setIsOpen(true);
  };

  // useEffect(() => {
  //   window.google.accounts.id.initialize({
  //     client_id:
  //       "272816186247-ingnqtrirhvq3s3a8pg7mm64v1l824ol.apps.googleusercontent.com",
  //     callback: (response) => {
  //       console.log("JWT ID Token:", response.credential);
  //       // Send the ID token to your backend for verification
  //     },
  //   });

  //   window.google.accounts.id.prompt(); // Display One Tap
  // }, []);

  let titleData = {};

  if (pathname === "/") {
    titleData = {
      title: "DeskZone - Services",
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  if (pathname === `/auth/login`) {
    titleData = {
      title: "DeskZone - Login",
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  if (pathname === `/auth/sign-up`) {
    titleData = {
      title: "DeskZone - Sign Up",
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  if (pathname === `/auth/password/reset`) {
    titleData = {
      title: "DeskZone - Reset Password",
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  if (pathname === `/experts`) {
    titleData = {
      title: "Top Experts",
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  if (pathname === `/experts/${pathname.split("/")[2]}/services`) {
    titleData = {
      title: `${decodeURIComponent(pathname.split("/")[2])}'s Services`,
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  if (pathname === `/experts/${pathname.split("/")[2]}/about`) {
    titleData = {
      title: `About ${decodeURIComponent(pathname.split("/")[2])}`,
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  if (pathname === `/experts/${pathname.split("/")[2]}/contact-us`) {
    titleData = {
      title: `Contact ${decodeURIComponent(
        pathname.split("/")[2]
      )} on Deskzone`,
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  if (pathname === `/services/${pathname.split("/")[2]}`) {
    titleData = {
      title: pathname.split("/")[2].split("-").join(" "),
      metaDescription:
        "Discover a video-based freelance marketplace where skilled professionals showcase their expertise and connect with clients through engaging video profiles. Hire top talent or offer your services seamlessly!",
    };
  }

  SEO(titleData);

  return (
    <>
      <header
        className={`fixed top-0 left-0 right-0 z-10  transition ease-in-out delay-150`}
      >
        <NavigationBar
          toggleMobileSearchInput={() =>
            showInput ? setShowInput(false) : setShowInput(true)
          }
        />
        {(pathname === "/" || pathname === "/search/services") && (
          <ExpertServicesFilterTab />
        )}

        {showInput && <MobileSearchInput />}
        <Notification />
      </header>

      <main
        className={`${
          pathname === "experts" || pathname === "/"
            ? styles.main
            : styles.main2
        }`}
      >
        <aside>
          {pathname.split("/")[1] !== "expert" &&
            pathname.split("/")[2] !== "profile" && <SideNavigationBar />}
        </aside>

        <section
          className={`${
            pathname.split("/")[1] === "client" ||
            pathname.split("/")[1] === "expert"
              ? ""
              : styles.main_content
          } `}
        >
          <AppRoutes />
        </section>

        <aside>
          <MobileSideBar
            isOpen={isOpen}
            onOpenMobileSideBar={onOpenMobileSideBar}
          />
        </aside>
      </main>

      <footer>
        <BottomNav
          isOpen={isOpen}
          onOpenMobileSideBar={onOpenMobileSideBar}
          isScrolling={isScrolling}
        />
      </footer>
    </>
  );
};

export default Layout;
