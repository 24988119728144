import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../components/commons/Icon";
import styles from "./NavgitaionBar.module.css";

const SearchInput: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!searchQuery) return;
    navigate(`/search/services?q=${searchQuery}`);
  };

  return (
    <form
      className="hidden md:block md:max-w-96 xl:max-w-3xl w-5/6 mx-auto"
      onSubmit={handleSubmit}
    >
      <div className="relative flex items-center">
        <input
          type="search"
          id="default-search"
          className={`${styles.search_input} w-full block md:w-full p-4 ps-10 text-sm  border  bg-gray-50 border-gray-600 placeholder-gray-400 text-white `}
          placeholder="What service are you interested in?"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          disabled={!searchQuery}
          type="submit"
          className={` absolute end-2 bottom-2  font-medium  px-4 ${styles.search_btn}`}
        >
          <Icon
            iconClasses={`fa-solid fa-magnifying-glass ${styles.search_icons}`}
          />
        </button>
      </div>
    </form>
  );
};

export default SearchInput;
