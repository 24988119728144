import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInBottomWithScaleX } from "../../../lib/framer-motion/fade-in-bottom";
import VideoPreview from "../videoPlayer/VideoPreview";
import ServiceWishListModal from "../../Services/ServiceWishlistModal";
import { IServicesProps } from "../../../interfaces/propsInterfaces";
import ServiceInfo from "./ServiceInfo";
import RelatedServiceInfoNew from "./RelatedServiceInfoNew";
import styles from "./ExpertServices.module.css";

const ExpertServiceCard: React.FC<IServicesProps> = ({
  businessLogo,
  businessName,
  id,
  price,
  service,
  servicePoster,
  serviceVideo,
  category,
  slug,
  expertId,
  averageRating,
}) => {
  const [wishListModalOpen, setWishListModalOpen] = useState(false);

  const location = useLocation();

  const { wishlist } = useSelector((state: any) => state.userWishlistState);

  const { pathname } = location;

  return (
    <AnimatePresence>
      <motion.div
        exit={{ opacity: 0, scale: 1.1 }}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className={`group relative aspect-[3/2] w-full justify-center ${styles.services_card}`}
        variants={fadeInBottomWithScaleX()}
        title={service}
      >
        {!wishlist && (
          <ServiceWishListModal
            wishListModalOpen={wishListModalOpen}
            onShowWishList={() =>
              wishListModalOpen
                ? setWishListModalOpen(false)
                : setWishListModalOpen(true)
            }
            serviceId={id}
            service={service}
            category={category}
          />
        )}
        {serviceVideo && (
          <VideoPreview
            onShowWishList={() =>
              wishListModalOpen
                ? setWishListModalOpen(false)
                : setWishListModalOpen(true)
            }
            serviceName={service}
            serviceVideo={serviceVideo}
            servicePoster={servicePoster}
            id={id}
            slug={slug}
            averageRating={averageRating}
          />
        )}

        {pathname === "/" ? (
          <ServiceInfo
            businessLogo={businessLogo}
            businessName={businessName}
            price={price}
            service={service}
            id={id}
            slug={slug}
            expertId={expertId}
          />
        ) : (
          <RelatedServiceInfoNew
            businessLogo={businessLogo}
            businessName={businessName}
            price={price}
            service={service}
            id={id}
            slug={slug}
            expertId={expertId}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default ExpertServiceCard;
