import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import Moment from "react-moment";
import { FaAngleRight } from "react-icons/fa";
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
  sortChatMessagesByTime,
  sortMainMesageByTime,
} from "../../../helpers/message";
import Spinner from "../../commons/Spinner";
// import Logo from "../../../Assets/poster5.png";
import styles from "../Profile.module.css";

const ArchivedMessages: React.FC<{
  messages?: [];
  onRemoveMessageFromArchive: (ids: string[]) => void;
  unArchiveMessageIsLoading: boolean;
}> = ({ messages, onRemoveMessageFromArchive, unArchiveMessageIsLoading }) => {
  const [isChecked, setIsChecked] = useState<number[]>([]);
  // const [showOptions, setShowOptions] = useState(false);
  const [messagedIds, setMessageIds] = useState<string[]>([]);
  const [messageId, setMessageId] = useState<string>("");
  const [sortedChatMessages, setSortedChatMessages] = useState<any[]>([]);

  const { loggedInExpert } = useSelector(
    (state: any) => state.loggedInExpertState
  );

  useEffect(() => {
    if (messages) {
      setSortedChatMessages(
        messages.map((message: any) => {
          return {
            ...message,

            lastSentMessageTime: new Date(
              message?.lastMessage?.created_at
            ).getTime(),
          };
        })
      );
    }
  }, [messages]);

  const handleCheckboxChange = (index: number, messageId: string) => {
    setIsChecked((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });

    setMessageIds((prev) => {
      if (prev.includes(messageId)) {
        return prev.filter((id) => id !== messageId);
      } else {
        return [...prev, messageId];
      }
    });
  };

  const onArchiveMessage = (ids: string[], id: string) => {
    setMessageId(id);
    onRemoveMessageFromArchive(ids);
  };

  return (
    <>
      {isChecked.length > 1 && (
        <div className="mb-5 -mt-16">
          <div className="flex text-xs justify-end">
            <button
              className="border cursor-pointer text-white rounded-md px-2 py-1 mx-3 bg-[#313131] hover:bg-[#757575]"
              onClick={() => onRemoveMessageFromArchive(messagedIds)}
            >
              Un-archive All
            </button>
            {/* <button className="border text-white rounded-md px-2 py-1 bg-[#24b47e] hover:bg-[#53ffc0]">
              Mark Read/Unread
            </button> */}
          </div>
        </div>
      )}

      {sortedChatMessages &&
        sortedChatMessages?.length > 0 &&
        sortMainMesageByTime(sortedChatMessages)?.map(
          (message: any, i: number) => {
            return (
              <div
                className={`mb-5 ${styles.message_container}`}
                key={message?.id}
              >
                <div className={`bg-[#19191A] p-4 flex gap-3 items-start`}>
                  <input
                    checked={isChecked.includes(i)}
                    onChange={() => handleCheckboxChange(i, message?.id)}
                    type="checkbox"
                    className={`${styles.message_check2} hidden scale-125 md:block`}
                  />
                  <div
                    // to={`/messages/${message.id}`}
                    className="w-full block"
                  >
                    <div className="flex">
                      <div className="flex gap-4">
                        {message?.expert?.id &&
                        loggedInExpert?.id !== message?.expert?.id ? (
                          <img
                            src={message?.expert?.business_logo}
                            alt="logo"
                            className="rounded-full  h-10 w-10"
                          />
                        ) : (
                          <img
                            src={
                              message?.client_user?.avatar ||
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQABqQIdskCD9BK0I81EbVfV9tTz320XvJ35A&s"
                            }
                            alt="logo"
                            className="rounded-full  h-10 w-10"
                          />
                        )}

                        <p className="text-white  text-xs md:text-sm font-semibold">
                          {message?.expert?.id &&
                          loggedInExpert?.id !== message?.expert?.id ? (
                            <p className="text-white  text-xs md:text-sm font-semibold">
                              {message?.expert?.business_name}
                            </p>
                          ) : (
                            <p className="text-white  text-xs md:text-sm font-semibold">
                              {message?.client_user?.first_name}{" "}
                              {message?.client_user?.last_name}
                            </p>
                          )}
                        </p>
                      </div>

                      <span
                        // to={`/messages/${message.id}`}
                        className="ml-auto"
                      >
                        <IconContext.Provider
                          value={{ color: "white", size: "1.3em" }}
                        >
                          <FaAngleRight />
                        </IconContext.Provider>
                      </span>
                    </div>

                    <div className="ml-14 -mt-5 w-4/5 md:w-11/12">
                      <p className="text-gray-600 text-xs font-semibold">
                        <Moment fromNow>
                          {
                            new Date(
                              message?.chatMessages[
                                message?.chatMessages.length - 1
                              ]?.created_at
                            )
                          }
                        </Moment>
                      </p>
                      <p
                        className={`text-white text-xs md:text-sm ${styles.message_text}`}
                      >
                        {
                          message?.chatMessages[
                            message?.chatMessages.length - 1
                          ]?.message
                        }
                      </p>
                    </div>
                  </div>
                </div>

                {/* {(isChecked.includes(i) || showOptions) && ( */}
                <div className="flex items-start ml-3 mt-2 pr-2">
                  <div className="flex items-center gap-2">
                    {/* <IconContext.Provider
                      value={{ color: "#d1871f", size: "1.3em" }}
                    >
                      <RiErrorWarningLine />
                    </IconContext.Provider>

                    <p className="text-xs  text-white">
                      Don't forget to reply{" "}
                      <span className="text-[#24b47e]">Loko Dev Team</span>
                    </p> */}
                  </div>

                  <div className="ml-auto flex gap-4 items-center">
                    {messageId === message?.id && unArchiveMessageIsLoading ? (
                      <Spinner />
                    ) : (
                      <button
                        className="text-black"
                        title="Restore message"
                        onClick={() =>
                          onArchiveMessage([message?.id], message?.id)
                        }
                      >
                        <IconContext.Provider
                          value={{ color: "white", size: "1.3em" }}
                        >
                          <RiInboxUnarchiveLine />
                        </IconContext.Provider>
                      </button>
                    )}
                  </div>
                </div>
                {/* )} */}
              </div>
            );
          }
        )}
      {/* <div className={`bg-[#19191A] p-4 flex gap-3 items-start`}>
        <input
          // checked={isChecked.includes(i)}
          // onChange={() => handleCheckboxChange(i)}
          type="checkbox"
          className={`${styles.message_check2} hidden scale-125 md:block`}
        />
        <Link to="">
          <div className="flex">
            <div className="flex gap-4">
              <img src={Logo} alt="logo" className="rounded-full  h-10 w-10" />

              <p className="text-white  text-xs md:text-sm font-semibold">
                Loko Dev Team
              </p>
            </div>

            <Link to="" className="ml-auto">
              <IconContext.Provider value={{ color: "white", size: "1.3em" }}>
                <FaAngleRight />
              </IconContext.Provider>
            </Link>
          </div>

          <div className="ml-14 -mt-5 w-4/5 md:w-11/12">
            <p className="text-gray-600 text-xs font-semibold">( Yesterday )</p>
            <p className="text-white text-xs md:text-sm">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              mollitia, molestiae quas vel sint commodi repudiandae consequuntur
              voluptatum laborum numquam
            </p>
          </div>
        </Link>
      </div>

      <div className="flex items-start mt-3">
        <div className="flex items-center gap-3">
          <IconContext.Provider value={{ color: "#d1871f", size: "1.5em" }}>
            <RiErrorWarningLine />
          </IconContext.Provider>

          <p className="text-xs md:text-sm text-white">
            Don't forget to reply{" "}
            <span className="text-[#24b47e]">Loko Dev Team</span>
          </p>
        </div>

        <div className="ml-auto flex gap-4 items-center">
          <button className="text-black" title="Restore">
            <IconContext.Provider value={{ color: "white", size: "1.3em" }}>
              <RiInboxUnarchiveLine />
            </IconContext.Provider>
          </button>
        </div>
      </div> */}
    </>
  );
};

export default ArchivedMessages;
