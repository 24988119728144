import { useState, useRef, useEffect } from "react";
import Moment from "react-moment";
import {
  getFileType,
  getPdfInfo,
  generateRandomNameForFile,
  convertUrlToBase64,
} from "../../helpers/files";
import { IconContext } from "react-icons";
// import { convertUrlToBase64 } from "../../helpers/newFile";
import { getServerFileType } from "../../helpers/message";
import { BiPlay, BiPause } from "react-icons/bi";
import styles from "./Negotiation.module.css";

const ChatBubble: React.FC<{
  message?: string;
  isOwn?: boolean;
  created_at?: Date;
  otherUser?: string;
  otherUserImage?: string;
  file?: string;
  serverFile?: string;
}> = ({
  message,
  isOwn,
  created_at,
  otherUser,
  otherUserImage,
  file,
  serverFile,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = () => {
    if (isPlaying) {
      videoRef.current?.pause();
      setIsPlaying(false);
    } else {
      videoRef.current?.play();
      setIsPlaying(true);
    }
  };

  return (
    <>
      {!isOwn ? (
        <div className="flex items-start mb-5 gap-2.5">
          <img
            className="w-8 h-8 rounded-full"
            src={otherUserImage}
            alt="Jese_image"
          />
          <div className="flex flex-col gap-1 w-fit max-w-[300px] sm:max-w-[400px] md:max-w-[500px]">
            <div className="flex items-center space-x-2 rtl:space-x-reverse">
              <span className="text-xs md:text-sm font-semibold text-gray-900 dark:text-white">
                {otherUser}
              </span>
            </div>
            <div
              className={`flex flex-col break-words  w-fit leading-1.5 p-4 border-gray-200 rounded-e-xl rounded-es-xl bg-[#2c2a2a] ${styles.other_text}`}
            >
              <p className={` text-xs  md:text-sm  font-normal text-white`}>
                {message}
              </p>

              {serverFile && getServerFileType(serverFile) === "image" && (
                <div className="group relative my-2.5 ">
                  <div className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                    <a
                      href={serverFile
                        .split("/")
                        .map((ele: any) =>
                          ele === "upload" ? ele.concat("/fl_attachment") : ele
                        )
                        .join("/")}
                      download={generateRandomNameForFile()}
                    >
                      <button
                        data-tooltip-target="download-image"
                        className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50"
                      >
                        <svg
                          className="w-5 h-5 text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 16 18"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                          />
                        </svg>
                      </button>

                      <div
                        id="download-image"
                        role="tooltip"
                        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        Download image
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </a>
                  </div>
                  {/* <img src={file} className="rounded-lg" /> */}
                  <img src={serverFile} className="rounded-lg" />
                </div>
              )}

              {serverFile && getServerFileType(serverFile) === "video" && (
                <div className="group relative my-2.5 ">
                  <div className="z-[1] absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                    <a href={serverFile} download={generateRandomNameForFile()}>
                      <button
                        data-tooltip-target="download-image"
                        className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50"
                      >
                        <svg
                          className="w-5 h-5 text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 16 18"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                          />
                        </svg>
                      </button>

                      <div
                        id="download-image"
                        role="tooltip"
                        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        Download image
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </a>
                  </div>

                  <div>
                    <video
                      // controls

                      disablePictureInPicture
                      className={`rounded-md object-cover w-[300px] h-[200px]`}
                      ref={videoRef}
                      src={serverFile}
                    />
                    <div className={styles.controls}>
                      {isPlaying ? (
                        <button
                          style={{ backgroundColor: "rgb(0, 0, 0, 0.5)" }}
                          className="rounded-full flex items-center justify-center p-1"
                          onClick={handlePlay}
                        >
                          <IconContext.Provider
                            value={{ color: "white", size: "1.5em" }}
                          >
                            <BiPause />
                          </IconContext.Provider>
                        </button>
                      ) : (
                        <button
                          style={{ backgroundColor: "rgb(0, 0, 0, 0.5)" }}
                          className=" rounded-full flex items-center justify-center p-1"
                          onClick={handlePlay}
                        >
                          <IconContext.Provider
                            value={{ color: "white", size: "1.5em" }}
                          >
                            <BiPlay />
                          </IconContext.Provider>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {serverFile && getServerFileType(serverFile) === "pdf" && (
                <div className="me-2">
                  <a
                    href={serverFile
                      .split("/")
                      .map((ele: any) =>
                        ele === "upload" ? ele.concat("/fl_attachment") : ele
                      )
                      .join("/")}
                    download={generateRandomNameForFile()}
                  >
                    <span className="flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-white pb-2">
                      <svg
                        fill="none"
                        aria-hidden="true"
                        className="w-5 h-5 flex-shrink-0"
                        viewBox="0 0 20 21"
                      >
                        <g clip-path="url(#clip0_3173_1381)">
                          <path
                            fill="#E2E5E7"
                            d="M5.024.5c-.688 0-1.25.563-1.25 1.25v17.5c0 .688.562 1.25 1.25 1.25h12.5c.687 0 1.25-.563 1.25-1.25V5.5l-5-5h-8.75z"
                          />
                          <path
                            fill="#B0B7BD"
                            d="M15.024 5.5h3.75l-5-5v3.75c0 .688.562 1.25 1.25 1.25z"
                          />
                          <path
                            fill="#CAD1D8"
                            d="M18.774 9.25l-3.75-3.75h3.75v3.75z"
                          />
                          <path
                            fill="#F15642"
                            d="M16.274 16.75a.627.627 0 01-.625.625H1.899a.627.627 0 01-.625-.625V10.5c0-.344.281-.625.625-.625h13.75c.344 0 .625.281.625.625v6.25z"
                          />
                          <path
                            fill="#fff"
                            d="M3.998 12.342c0-.165.13-.345.34-.345h1.154c.65 0 1.235.435 1.235 1.269 0 .79-.585 1.23-1.235 1.23h-.834v.66c0 .22-.14.344-.32.344a.337.337 0 01-.34-.344v-2.814zm.66.284v1.245h.834c.335 0 .6-.295.6-.605 0-.35-.265-.64-.6-.64h-.834zM7.706 15.5c-.165 0-.345-.09-.345-.31v-2.838c0-.18.18-.31.345-.31H8.85c2.284 0 2.234 3.458.045 3.458h-1.19zm.315-2.848v2.239h.83c1.349 0 1.409-2.24 0-2.24h-.83zM11.894 13.486h1.274c.18 0 .36.18.36.355 0 .165-.18.3-.36.3h-1.274v1.049c0 .175-.124.31-.3.31-.22 0-.354-.135-.354-.31v-2.839c0-.18.135-.31.355-.31h1.754c.22 0 .35.13.35.31 0 .16-.13.34-.35.34h-1.455v.795z"
                          />
                          <path
                            fill="#CAD1D8"
                            d="M15.649 17.375H3.774V18h11.875a.627.627 0 00.625-.625v-.625a.627.627 0 01-.625.625z"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3173_1381">
                            <path
                              fill="#fff"
                              d="M0 0h20v20H0z"
                              transform="translate(0 .5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Download pdf
                    </span>
                  </a>
                  {/* <span className="flex text-xs font-normal text-gray-500 dark:text-gray-400 gap-2">
                    {pdfInfo?.pageNum}{" "}
                    {pdfInfo && pdfInfo?.pageNum > 1 ? "Pages" : "Page"}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="self-center"
                      width="3"
                      height="4"
                      viewBox="0 0 3 4"
                      fill="none"
                    >
                      <circle cx="1.5" cy="2" r="1.5" fill="#6B7280" />
                    </svg>
                    {pdfInfo &&
                      pdfInfo.fileSize &&
                      Math.round(pdfInfo?.fileSize / 1024).toFixed(2)}{" "}
                    kb
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="self-center"
                      width="3"
                      height="4"
                      viewBox="0 0 3 4"
                      fill="none"
                    >
                      <circle cx="1.5" cy="2" r="1.5" fill="#6B7280" />
                    </svg>
                    PDF
                  </span> */}
                </div>
              )}
            </div>
            <span className="text-[10px]  font-normal text-gray-400">
              {created_at ? (
                <Moment fromNow>{new Date(created_at)}</Moment>
              ) : (
                <Moment fromNow>{new Date()}</Moment>
              )}
            </span>
          </div>
        </div>
      ) : (
        <div className="flex justify-end">
          <div className="flex items-start flex-row-reverse  mb-5 gap-2.5">
            <div className="flex flex-col gap-1 w-fit max-w-[300px] sm:max-w-[400px] md:max-w-[500px]">
              <div
                className={`flex flex-col break-words  p-4 border-gray-200 rounded-b-xl rounded-tl-xl ${styles.other_chat_bubble}`}
              >
                <p className="text-xs ml-auto  md:text-sm font-normal text-gray-900 dark:text-white">
                  {message}
                </p>

                {serverFile && getServerFileType(serverFile) === "image" && (
                  <div className="group relative my-2.5 ">
                    <div className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                      <a
                        href={serverFile
                          .split("/")
                          .map((ele: any) =>
                            ele === "upload"
                              ? ele.concat("/fl_attachment")
                              : ele
                          )
                          .join("/")}
                        download={generateRandomNameForFile()}
                      >
                        <button
                          data-tooltip-target="download-image"
                          className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50"
                        >
                          <svg
                            className="w-5 h-5 text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 18"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                            />
                          </svg>
                        </button>

                        <div
                          id="download-image"
                          role="tooltip"
                          className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                        >
                          Download image
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </a>
                    </div>
                    {/* <img src={file} className="rounded-lg" /> */}
                    <img src={serverFile} className="rounded-lg" />
                  </div>
                )}

                {serverFile && getServerFileType(serverFile) === "video" && (
                  <div className="group relative my-2.5 ">
                    <div className="z-[1] absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                      <a
                        href={serverFile}
                        download={generateRandomNameForFile()}
                      >
                        <button
                          data-tooltip-target="download-image"
                          className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50"
                        >
                          <svg
                            className="w-5 h-5 text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 18"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                            />
                          </svg>
                        </button>

                        <div
                          id="download-image"
                          role="tooltip"
                          className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                        >
                          Download image
                          <div
                            className="tooltip-arrow"
                            data-popper-arrow
                          ></div>
                        </div>
                      </a>
                    </div>

                    <div>
                      <video
                        // controls

                        disablePictureInPicture
                        className={`rounded-md object-cover w-[300px] h-[200px]`}
                        ref={videoRef}
                        src={serverFile}
                      />
                      <div className={styles.controls}>
                        {isPlaying ? (
                          <button
                            style={{ backgroundColor: "rgb(0, 0, 0, 0.5)" }}
                            className="rounded-full flex items-center justify-center p-1"
                            onClick={handlePlay}
                          >
                            <IconContext.Provider
                              value={{ color: "white", size: "1.5em" }}
                            >
                              <BiPause />
                            </IconContext.Provider>
                          </button>
                        ) : (
                          <button
                            style={{ backgroundColor: "rgb(0, 0, 0, 0.5)" }}
                            className=" rounded-full flex items-center justify-center p-1"
                            onClick={handlePlay}
                          >
                            <IconContext.Provider
                              value={{ color: "white", size: "1.5em" }}
                            >
                              <BiPlay />
                            </IconContext.Provider>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {serverFile && getServerFileType(serverFile) === "pdf" && (
                  <div className="me-2">
                    <a
                      href={serverFile
                        .split("/")
                        .map((ele: any) =>
                          ele === "upload" ? ele.concat("/fl_attachment") : ele
                        )
                        .join("/")}
                      download={generateRandomNameForFile()}
                    >
                      <span className="flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-white pb-2">
                        <svg
                          fill="none"
                          aria-hidden="true"
                          className="w-5 h-5 flex-shrink-0"
                          viewBox="0 0 20 21"
                        >
                          <g clip-path="url(#clip0_3173_1381)">
                            <path
                              fill="#E2E5E7"
                              d="M5.024.5c-.688 0-1.25.563-1.25 1.25v17.5c0 .688.562 1.25 1.25 1.25h12.5c.687 0 1.25-.563 1.25-1.25V5.5l-5-5h-8.75z"
                            />
                            <path
                              fill="#B0B7BD"
                              d="M15.024 5.5h3.75l-5-5v3.75c0 .688.562 1.25 1.25 1.25z"
                            />
                            <path
                              fill="#CAD1D8"
                              d="M18.774 9.25l-3.75-3.75h3.75v3.75z"
                            />
                            <path
                              fill="#F15642"
                              d="M16.274 16.75a.627.627 0 01-.625.625H1.899a.627.627 0 01-.625-.625V10.5c0-.344.281-.625.625-.625h13.75c.344 0 .625.281.625.625v6.25z"
                            />
                            <path
                              fill="#fff"
                              d="M3.998 12.342c0-.165.13-.345.34-.345h1.154c.65 0 1.235.435 1.235 1.269 0 .79-.585 1.23-1.235 1.23h-.834v.66c0 .22-.14.344-.32.344a.337.337 0 01-.34-.344v-2.814zm.66.284v1.245h.834c.335 0 .6-.295.6-.605 0-.35-.265-.64-.6-.64h-.834zM7.706 15.5c-.165 0-.345-.09-.345-.31v-2.838c0-.18.18-.31.345-.31H8.85c2.284 0 2.234 3.458.045 3.458h-1.19zm.315-2.848v2.239h.83c1.349 0 1.409-2.24 0-2.24h-.83zM11.894 13.486h1.274c.18 0 .36.18.36.355 0 .165-.18.3-.36.3h-1.274v1.049c0 .175-.124.31-.3.31-.22 0-.354-.135-.354-.31v-2.839c0-.18.135-.31.355-.31h1.754c.22 0 .35.13.35.31 0 .16-.13.34-.35.34h-1.455v.795z"
                            />
                            <path
                              fill="#CAD1D8"
                              d="M15.649 17.375H3.774V18h11.875a.627.627 0 00.625-.625v-.625a.627.627 0 01-.625.625z"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3173_1381">
                              <path
                                fill="#fff"
                                d="M0 0h20v20H0z"
                                transform="translate(0 .5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        Download pdf
                      </span>
                    </a>
                    {/* <span className="flex text-xs font-normal text-gray-500 dark:text-gray-400 gap-2">
                      {pdfInfo?.pageNum}{" "}
                      {pdfInfo && pdfInfo?.pageNum > 1 ? "Pages" : "Page"}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="self-center"
                        width="3"
                        height="4"
                        viewBox="0 0 3 4"
                        fill="none"
                      >
                        <circle cx="1.5" cy="2" r="1.5" fill="#6B7280" />
                      </svg>
                      {pdfInfo &&
                        pdfInfo.fileSize &&
                        Math.round(pdfInfo?.fileSize / 1024).toFixed(2)}{" "}
                      kb
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="self-center"
                        width="3"
                        height="4"
                        viewBox="0 0 3 4"
                        fill="none"
                      >
                        <circle cx="1.5" cy="2" r="1.5" fill="#6B7280" />
                      </svg>
                      PDF
                    </span> */}
                  </div>
                )}
              </div>
              {/* <span className="ml-auto text-[9px] md:text-xs font-normal text-gray-500 dark:text-gray-400">
                Delivered
              </span> */}
              <div className="flex  space-x-2">
                <span className="text-[10px] ml-auto mr-1 font-normal text-gray-400">
                  {created_at ? (
                    <Moment fromNow>{new Date(created_at)}</Moment>
                  ) : (
                    <Moment fromNow>{new Date()}</Moment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBubble;
