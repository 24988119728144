import Negotation from "../components/Negotation/Negotation";
import ChatContextProvider from "../context/chat-context";

const ServiceNegotationPage: React.FC = () => {
  return (
    <ChatContextProvider>
      <Negotation />
    </ChatContextProvider>
  );
};

export default ServiceNegotationPage;
