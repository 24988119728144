import Btn from "./Btn";
import styles from "../icons/icons.module.css";

interface PipProps {
  isPipMode: boolean;
  onToggle: () => void;
}

const Pip: React.FC<PipProps> = ({ isPipMode, onToggle }) => {
  return (
    <Btn label="Picture in Picture" onClick={onToggle}>
      {isPipMode ? (
        <svg
          className={styles.player_icon}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.5833 6.41667H3.41667V18.5833H20.5833V6.41667ZM2 5V20H22V5H2Z"
          />
          <path d="M4.5 7.5H19.5V17.5H4.5V7.5Z" />
        </svg>
      ) : (
        <svg
          className={styles.player_icon}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.5833 6.41667H3.41667V18.5833H20.5833V6.41667ZM2 5V20H22V5H2Z"
          />
          <path d="M12 12.5H19.5V17.5H12V12.5Z" />
        </svg>
      )}
    </Btn>
  );
};

export default Pip;
