import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlist: null,
};

export const userWishlistSlice = createSlice({
  initialState,
  name: "userWishlistState",
  reducers: {
    setUsersWishlist: (state, action) => {
      state.wishlist = action.payload;
    },

    clearUsersWishlist: (state, action) => {
      state.wishlist = null;
    },
  },
});

export default userWishlistSlice.reducer;

export const { setUsersWishlist, clearUsersWishlist } =
  userWishlistSlice.actions;
