import { useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { BiPlay, BiPause } from "react-icons/bi";
import styles from "./Services.module.css";

const ServiceVideoPreview: React.FC<{
  serviceVideo?: string;
  thumbnail?: string;
}> = ({ serviceVideo, thumbnail }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState([0, 0]);
  const [currentTimeSec, setCurrentTimeSec] = useState<number>(0);
  const [duration, setDuration] = useState([0, 0]);
  const [durationSec, setDurationSec] = useState<number>(0);

  const sec2Min = (sec: number): [number, number] => {
    const min = Math.floor(sec / 60);
    const secRemain = Math.floor(sec % 60);
    return [min, secRemain];
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (videoRef.current) {
      const { duration } = videoRef.current;

      if (!isNaN(duration)) {
        const [min, sec] = sec2Min(duration);
        setDurationSec(duration);
        setDuration([min, sec]);
      }

      interval = setInterval(() => {
        if (videoRef.current && !isNaN(videoRef.current.currentTime)) {
          const { currentTime } = videoRef.current;
          const [min, sec] = sec2Min(currentTime);
          setCurrentTimeSec(currentTime);
          setCurrentTime([min, sec]);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isPlaying, currentTimeSec]);

  const handlePlay = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (isPlaying) {
      videoRef.current?.pause();
      setIsPlaying(false);
    } else {
      videoRef.current?.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className={`${styles.container}`}>
      {thumbnail && (
        <img
          alt={`deskzone service poster`}
          className={`${styles.thumbnail} ${
            isPlaying && "hidden"
          } shadow-lg object-cover w-full h-full`}
          src={thumbnail}
        />
      )}
      <video
        className={`${styles.videoPlayer} object-cover w-full h-full `}
        ref={videoRef}
        src={serviceVideo}
        style={{ maxHeight: "187px", width: "100%" }}
      />

      {videoRef?.current && (
        <div className={styles.controls}>
          {isPlaying ? (
            <button className={styles.controlButton} onClick={handlePlay}>
              <IconContext.Provider value={{ color: "white", size: "2em" }}>
                <BiPause />
              </IconContext.Provider>
            </button>
          ) : (
            <button className={styles.controlButton} onClick={handlePlay}>
              <IconContext.Provider value={{ color: "white", size: "2em" }}>
                <BiPlay />
              </IconContext.Provider>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceVideoPreview;
