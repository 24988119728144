import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import OtherServicePlayerPreview from "../videoPlayer/OtherServicePlayerPreview";

const responsive = {
  extraSuperLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1780 },
    items: 4,
  },
  superLargeDesktop2: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1779, min: 1536 },
    items: 4,
  },
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1535, min: 1280 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1279, min: 950 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 949, min: 600 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 3,
  },
};

const OtherServices: React.FC<{
  services: any[];
  businessName: string;
  expertName: string;
  expertId: string;
}> = ({ services, businessName, expertName, expertId }) => {
  return (
    <div className="my-6">
      <h1 className="mb-3 ml-3 text-xs md:text-sm text-cyan-50">
        More by{" "}
        <Link to={`/experts/${expertName}/services?eid=${expertId}`}>
          <span className="text-cyan-300 hover:text-cyan-50">
            {businessName}
          </span>
        </Link>
      </h1>

      <Carousel
        responsive={responsive}
        infinite={true}
        ssr={true}
        itemClass="carousel-item-spacing"
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile"]}
      >
        {services.map((service: any) => {
          return (
            <div
              style={{ cursor: "pointer", paddingTop: "10px" }}
              key={service?.id}
            >
              <OtherServicePlayerPreview
                serviceName={service?.title}
                serviceVideo={service?.explainer_video}
                servicePoster={service?.thumbnail}
                slug={service?.slug}
                averageRating={service?.average_rating}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default OtherServices;
