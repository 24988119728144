import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FormButton from "../commons/FormButton";
import ServiceDesc from "./ServiceDesc";
import styles from "./Services.module.css";

const CreateServiceDetailsForm: React.FC<{
  onChangeServiceTitle?: (title: string) => void;
  onChangeServiceDescription?: (
    description: string,
    plainDescription: string
  ) => void;
  onChangePrice?: (price: string) => void;
  onSelectCategory?: (category: string) => void;
  formError?: any;
  formIsValid?: boolean;
  title?: string;
  description?: string;
  category?: string;
  price?: string;
  isLoading?: boolean;
  onChangeBenefits: (benefits: string[]) => void;
}> = ({
  onChangeServiceTitle,
  onChangeServiceDescription,
  onChangePrice,
  onSelectCategory,
  price,
  title,
  formError,
  formIsValid,
  isLoading,
  onChangeBenefits,
}) => {
  const [benefits, setBenefits] = useState<string[]>([]);
  const [maxBenefits, setMaxBenefits] = useState<number[]>([]);

  const { serviceCategories } = useSelector(
    (state: any) => state.serviceCategoryState
  );

  useEffect(() => {
    const range = Array.from({ length: 4 }, (_, i) => i);
    setMaxBenefits(range);
  }, []);

  // get service description function
  const onChangeDescription = (
    description: string,
    plainDescription: string
  ) => {
    onChangeServiceDescription &&
      onChangeServiceDescription(description, plainDescription);
  };

  useEffect(() => {
    onChangeBenefits(benefits);
  }, [benefits]);

  return (
    <>
      <div className="my-5">
        <label
          htmlFor="service-title"
          className={`${styles.form_label} block mb-2  font-medium`}
        ></label>

        <span
          className={`${styles.required_field} flex gap-2 items-center  absolute ml-3 mt-1 text-sm `}
        >
          Title (required){" "}
          <a
            className={styles.input_info}
            href="#"
            data-title="A catchy title can help you get clients hook. When you create service titles, it’s a good idea to include keywords a client is likely to use when looking for services like yours."
          >
            <i className="fa-regular w-4 h-4 fa-circle-question"></i>
          </a>
        </span>
        <input
          type="text"
          name="title"
          id="email"
          className={`${styles.form_input} ${
            formError && formError.field === "title" && styles.error_border
          } text-sm block w-full  dark:text-white`}
          placeholder="e-commerce website"
          onChange={(event) =>
            onChangeServiceTitle && onChangeServiceTitle(event.target.value)
          }
          value={title}
        />
        <div className="flex justify-end -mt-5 mr-2">
          <span className=" text-fuchsia-200 text-sm">
            {title?.trim().length}/50
          </span>
        </div>
      </div>
      {formError && formError.field === "title" && (
        <p className={styles.intro3}>{formError.error}</p>
      )}
      <div className="md:flex gap-5 mb-5 max-md:gap-9 sm:justify-between items-center">
        <div className="w-full">
          <label
            htmlFor="category"
            className={`${styles.form_label} block mb-2  font-medium`}
          ></label>

          <span
            className={`${styles.required_field} flex gap-2 items-center  absolute ml-3 mt-1 text-sm `}
          >
            Category (required){" "}
            <a
              className={styles.input_info}
              href="#"
              data-title="Selecting the right category can help clients find your services easily. You can always change your category later."
            >
              <i className="fa-regular w-4 h-4 fa-circle-question"></i>
            </a>
          </span>
          <select
            id="countries"
            className={`${styles.form_select} ${
              formError && formError.field === "category" && styles.error_border
            } text-sm block w-full p-2.5  dark:text-white`}
            onChange={(event) =>
              onSelectCategory && onSelectCategory(event.target.value)
            }
          >
            <option className={styles.cat_options}>Select Category</option>
            {serviceCategories &&
              serviceCategories?.length > 0 &&
              serviceCategories?.map((category: any) => {
                return (
                  <option
                    key={category?.id}
                    value={category.id}
                    className={styles.cat_options}
                  >
                    {category?.name}
                  </option>
                );
              })}
          </select>
        </div>

        <div className="w-full md:-mt-7">
          <label
            htmlFor="price"
            className={`${styles.form_label} block mb-2  font-medium`}
          ></label>

          <span
            className={`${styles.required_field}  flex gap-2 items-center  absolute ml-3 mt-1 text-sm `}
          >
            Price (required){" "}
            <a
              className={styles.input_info}
              href="#"
              data-title="Stating a considerable and competitive price can increase your chances for clients to buy your services. You can always adjust your price later."
            >
              <i className="fa-regular w-4 h-4 fa-circle-question"></i>
            </a>
          </span>

          <input
            type="text"
            name="price"
            id="email"
            className={`${styles.form_input2} ${
              formError && formError.field === "price" && styles.error_border
            } text-sm block w-full py-2.5 pl-7 text-white`}
            onChange={(event) =>
              onChangePrice && onChangePrice(event.target.value)
            }
            value={price}
          />

          <div className="-mt-14 w-10">
            <input
              value="$"
              className={`${styles.form_input3} w-7 pl-3`}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="flex">
        {formError && formError.field === "category" && (
          <div className="mr-auto">
            <p className={styles.intro3}>{formError.error}</p>
          </div>
        )}

        {formError && formError.field === "price" && (
          <div className="ml-auto">
            <p className={styles.intro3}>{formError.error}</p>
          </div>
        )}
      </div>
      <div className="mb-5 mt-3">
        <label
          htmlFor="service-title"
          className={`${styles.form_label} block mb-2 mt-10 md:mt-0 font-medium`}
        >
          Service Description
        </label>

        <ServiceDesc
          onChangeDescription={onChangeDescription}
          formError={formError}
        />
      </div>

      <div className="">
        <label
          htmlFor="service-benefits"
          className={`${styles.form_label} block mb-2  mt-8 font-medium`}
        >
          Service Benefits
          <a
            className={styles.input_info}
            href="#"
            data-title="List the benefits of your service. This will help clients understand what they will gain from your service."
          >
            <i className="fa-regular w-4 h-4 fa-circle-question"></i>
          </a>
        </label>

        <div className="lsm:grid lsm:grid-cols-2 gap-5 mb-5">
          {maxBenefits.map((index) => {
            return (
              <div className="mb-5 lsm:mb-0">
                <input
                  type="text-input"
                  className={`${
                    styles.form_input_new
                  } text-sm w-full p-2.5  text-white ${
                    formError.field === "benefit" && styles.error_border
                  }`}
                  placeholder={`Benefit ${index + 1}`}
                  onChange={(event) =>
                    setBenefits((prev) => {
                      const newBenefits = [...prev];
                      newBenefits[index] = event.target.value;
                      return newBenefits;
                    })
                  }
                />

                {/* <div className="flex justify-end -mt-5 mr-2">
                  <span className=" text-fuchsia-200 text-xs">
                    {benefits[index].length || 0}
                    /30
                  </span>
                </div> */}
              </div>
            );
          })}
        </div>

        {formError && formError.field === "benefit" && (
          <p className={`${styles.intro3}`}>{formError.error}</p>
        )}
      </div>

      <div className="my-10">
        <FormButton
          formIsValid={true}
          textContent="Create Service"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default CreateServiceDetailsForm;
