import { ICloseBtnProps } from "../../interfaces/propsInterfaces";

const CloseFormModalBtn: React.FC<ICloseBtnProps> = ({
  onToggleModal,
  formTitle,
}) => {
  return (
    <>
      <div className="flex items-center justify-between rounded-t dark:border-gray-600">
        <button
          onClick={onToggleModal}
          type="button"
          className="end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white"
          data-modal-hide="authentication-modal"
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      </div>

      <div>
        <h3 className="text-xl text-center font-semibold text-white">
          {formTitle}
        </h3>
      </div>
    </>
  );
};

export default CloseFormModalBtn;
