import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "../lib/apis/authApi";
import { userApi } from "../lib/apis/userApi";
import { expertApis } from "../lib/apis/expertApis";
import { serviceApis } from "../lib/apis/serviceApis";
import { wishlistApis } from "../lib/apis/wishlistApi";
import { messageApis } from "../lib/apis/messageApis";
import userSlice from "../lib/apis/redux/userSlice";
import userWishlistSlice from "../lib/apis/redux/usersWishlistSlice";
import topExpertDetailsSlice from "../lib/apis/redux/topExpertDetailsSlice";
import serviceCategorySlice from "../lib/apis/redux/serviceCategorySlice";
import loggedInExpertSlice from "../lib/apis/redux/loggedInExpertSlice";
import archiveMessageSlice from "../lib/apis/redux/archiveMessageSlice";
import inboxSlice from "../lib/apis/redux/inboxSlice";
import messageCountSlice from "../lib/apis/redux/messageCountSlice";
import chatSessionSlice from "../lib/apis/redux/chatSessionSlice";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [expertApis.reducerPath]: expertApis.reducer,
    [serviceApis.reducerPath]: serviceApis.reducer,
    [wishlistApis.reducerPath]: wishlistApis.reducer,
    [messageApis.reducerPath]: messageApis.reducer,
    userState: userSlice,
    userWishlistState: userWishlistSlice,
    topExpertDetailsState: topExpertDetailsSlice,
    serviceCategoryState: serviceCategorySlice,
    loggedInExpertState: loggedInExpertSlice,
    archiveMessageState: archiveMessageSlice,
    inboxState: inboxSlice,
    messageCountState: messageCountSlice,
    chatSessionState: chatSessionSlice,
  },

  devTools: process.env.NODE_ENV !== "production",

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
      expertApis.middleware,
      serviceApis.middleware,
      wishlistApis.middleware,
      messageApis.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
