import { useState } from "react";

const useFormValidation = () => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [formError, setFormError] = useState({ field: "", error: "" });

  const validPassword = {
    hasUpper: /[A-Z]/,
    hasLower: /[a-z]/,
    hasNumber: /[0-9]/,
    hasSpclChr: /[@,#,$,%,&]/,
  };

  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateFormInputs = (type: string, formProps: any) => {
    if (type === "auth") {
      if (formProps?.firstName.trim().length === 0) {
        setFormIsValid(false);
        return setFormError({
          field: "firstName",
          error: "First name is required",
        });
      }

      if (formProps?.lastName.trim().length === 0) {
        setFormIsValid(false);
        return setFormError({
          field: "lastName",
          error: "Last name  is required",
        });
      }

      if (formProps?.email.trim().length === 0) {
        setFormIsValid(false);
        return setFormError({
          field: "email",
          error: "Email is required",
        });
      }

      if (!regex.test(formProps?.email)) {
        setFormIsValid(false);
        return setFormError({
          field: "email",
          error: "Invalid email address",
        });
      }

      if (formProps?.password.trim().length === 0) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password is required",
        });
      }

      if (!validPassword.hasUpper.test(formProps?.password)) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must contain a uppercase letter",
        });
      }

      if (!validPassword.hasLower.test(formProps?.password)) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must contain a lowercase letter",
        });
      }

      if (!validPassword.hasNumber.test(formProps?.password)) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must contain a number",
        });
      }

      if (!validPassword.hasSpclChr.test(formProps?.password)) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must contain special characters {@#%#^#&$*$%}",
        });
      }

      if (formProps?.password.trim().length < 8) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must not be less that 8 characters",
        });
      }

      if (formProps?.password.trim() !== formProps?.confirmPassword) {
        setFormIsValid(false);
        return setFormError({
          field: "confirmPassword",
          error: "Password does not match",
        });
      }

      if (!formProps?.terms) {
        setFormIsValid(false);
        return setFormError({
          field: "terms",
          error: "Accept our terms and conditions",
        });
      }
    }

    if (type === "verification") {
      setFormIsValid(false);
      if (!formProps?.verificationCode) {
        return setFormError({
          field: "verificationCode",
          error: "Verification code is required",
        });
      }

      if (formProps?.verificationCode.trim().length !== 6) {
        setFormIsValid(false);
        return setFormError({
          field: "verificationCode",
          error: "Invalid verification code",
        });
      }
    }

    if (type === "reset") {
      if (formProps?.email.trim().length === 0) {
        setFormIsValid(false);
        return setFormError({
          field: "email",
          error: "Email is required",
        });
      }

      if (!regex.test(formProps?.email)) {
        setFormIsValid(false);
        return setFormError({
          field: "email",
          error: "Invalid email address",
        });
      }
    }

    if (type === "update-password") {
      if (!formProps?.passwordResetCode) {
        setFormIsValid(false);
        return setFormError({
          field: "passwordResetCode",
          error: "Verification code is required",
        });
      }

      if (formProps?.passwordResetCode.trim().length !== 6) {
        setFormIsValid(false);
        return setFormError({
          field: "passwordResetCode",
          error: "Invalid verification code",
        });
      }

      if (formProps?.password.trim().length === 0) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password is required",
        });
      }

      if (!validPassword.hasUpper.test(formProps?.password)) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must contain a uppercase letter",
        });
      }

      if (!validPassword.hasLower.test(formProps?.password)) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must contain a lowercase letter",
        });
      }

      if (!validPassword.hasNumber.test(formProps?.password)) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must contain a number",
        });
      }

      if (!validPassword.hasSpclChr.test(formProps?.password)) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must contain special characters {@#%#^#&$*$%}",
        });
      }

      if (formProps?.password.trim().length < 8) {
        setFormIsValid(false);
        return setFormError({
          field: "password",
          error: "Password must not be less that 8 characters",
        });
      }

      if (formProps?.password.trim() !== formProps?.confirmPassword) {
        setFormIsValid(false);
        return setFormError({
          field: "confirmPassword",
          error: "Password does not match",
        });
      }
    }

    if (type === "expert-application") {
      if (formProps?.businessName.trim().length === 0) {
        setFormIsValid(false);
        return setFormError({
          field: "businessName",
          error: "Business name is required",
        });
      }

      if (formProps?.businessName.trim().length < 3) {
        setFormIsValid(false);
        return setFormError({
          field: "businessName",
          error: "Business name should not be less than 3 characters",
        });
      }

      if (formProps?.businessName.trim().length > 30) {
        setFormIsValid(false);
        return setFormError({
          field: "businessName",
          error: "Business name should not be longer than 30 characters",
        });
      }

      const specialCharsRegex = /[^a-zA-Z0-9\s]/;

      if (specialCharsRegex.test(formProps?.businessName)) {
        setFormIsValid(false);
        return setFormError({
          field: "businessName",
          error: "Business name should not contain special characters",
        });
      }

      if (formProps?.about.trim().length === 0) {
        setFormIsValid(false);
        return setFormError({
          field: "about",
          error: "Business description is required",
        });
      }

      if (formProps?.about.trim().length < 50) {
        setFormIsValid(false);
        return setFormError({
          field: "about",
          error: "Business description should not be less than 50 characters",
        });
      }

      if (formProps?.about.trim().length > 600) {
        setFormIsValid(false);
        return setFormError({
          field: "about",
          error: "Description should not be more than 600 characters",
        });
      }

      if (!formProps?.logo) {
        setFormIsValid(false);
        return setFormError({
          field: "logo",
          error: "Business logo is required",
        });
      }

      if (!formProps?.coverImage) {
        setFormIsValid(false);
        return setFormError({
          field: "coverImage",
          error: "Business cover image is required",
        });
      }
    }

    if (type === "service-request") {
      if (!formProps?.description) {
        setFormIsValid(false);
        return setFormError({
          field: "description",
          error: "Description is required",
        });
      }

      if (formProps?.description.trim().length > 500) {
        setFormIsValid(false);
        return setFormError({
          field: "description",
          error: "Description should not be  more than 50 character",
        });
      }

      if (formProps?.description.trim().length <= 50) {
        setFormIsValid(false);
        return setFormError({
          field: "description",
          error: "Description should not be less than 10 characters",
        });
      }

      if (!formProps?.project_end_date) {
        setFormIsValid(false);
        return setFormError({
          field: "date",
          error: "Please provide a end date",
        });
      }

      if (formProps?.project_end_date) {
        const givenDate = new Date(formProps?.project_end_date);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1); // Set to tomorrow
        tomorrow.setHours(0, 0, 0, 0); // Reset time to midnight

        if (givenDate < tomorrow) {
          setFormIsValid(false);
          return setFormError({
            field: "date",
            error: "Select a date later than today",
          });
        }
      }

      if (!formProps?.amount_offered) {
        setFormIsValid(false);
        return setFormError({
          field: "amount",
          error: "Amount is required",
        });
      }
    }

    if (type === "wishlist") {
      if (!formProps?.name) {
        setFormIsValid(false);
        return setFormError({
          field: "name",
          error: "Name is required",
        });
      }

      if (formProps?.name.trim().length > 50) {
        setFormIsValid(false);
        return setFormError({
          field: "name",
          error: "Wishlist name should not be more than 50 character",
        });
      }

      if (formProps?.name.trim().length <= 5) {
        setFormIsValid(false);
        return setFormError({
          field: "name",
          error: "Wishlist name should not be less than 5 characters",
        });
      }
    }

    if (type === "create-service") {
      if (!formProps?.explaner_video) {
        setFormIsValid(false);
        return setFormError({
          field: "explainer_video",
          error: "Video is requied to create a service",
        });
      }

      if (!formProps?.banner) {
        setFormIsValid(false);
        return setFormError({
          field: "banner",
          error: "Thumbnail is required",
        });
      }

      if (!formProps?.title) {
        setFormIsValid(false);
        return setFormError({
          field: "title",
          error: "Title is required",
        });
      }

      if (formProps?.title.trim().length <= 5) {
        setFormIsValid(false);
        return setFormError({
          field: "title",
          error: "Title should not be less than 5 characters",
        });
      }

      if (formProps?.title.trim().length > 50) {
        setFormIsValid(false);
        return setFormError({
          field: "title",
          error: "Title should not be more than 50 characters",
        });
      }

      const specialCharsRegex = /[^a-zA-Z0-9\s]/;
      if (specialCharsRegex.test(formProps?.title)) {
        setFormIsValid(false);
        return setFormError({
          field: "title",
          error: "Title should not contain special characters",
        });
      }

      if (!formProps?.category) {
        setFormIsValid(false);
        return setFormError({
          field: "category",
          error: "Category is required",
        });
      }

      if (!formProps?.price) {
        setFormIsValid(false);
        return setFormError({
          field: "price",
          error: "Price field is required",
        });
      }

      if (!/^\d+$/.test(formProps?.price)) {
        setFormIsValid(false);
        return setFormError({
          field: "price",
          error: "Enter a valid price:500",
        });
      }

      if (!formProps?.description) {
        setFormIsValid(false);
        return setFormError({
          field: "description",
          error: "Description is required",
        });
      }

      if (formProps?.description.trim().length <= 50) {
        setFormIsValid(false);
        return setFormError({
          field: "description",
          error: "Description should not be less than 50 characters",
        });
      }

      if (formProps?.description.trim().length > 600) {
        setFormIsValid(false);
        return setFormError({
          field: "description",
          error: "Description should not be more than 600 characters",
        });
      }

      if (formProps?.allBenefits.length > 0) {
        for (let benefit of formProps?.allBenefits) {
          if (benefit.trim().length <= 5) {
            setFormIsValid(false);
            return setFormError({
              field: "benefit",
              error: "Benefit should not be less than 5 characters",
            });
          }

          if (specialCharsRegex.test(benefit)) {
            setFormIsValid(false);
            return setFormError({
              field: "benefit",
              error: "Benefit should not contain special characters",
            });
          }
        }
      }

      if (
        formProps?.allBenefits.length < 4 ||
        formProps?.allBenefits.length > 4
      ) {
        setFormIsValid(false);
        return setFormError({
          field: "benefit",
          error: "You must provide four(4) benefit of your service",
        });
      }

      if (formProps?.allBenefits.length > 0) {
        for (let benefit of formProps?.allBenefits) {
          if (benefit.trim().length > 30) {
            setFormIsValid(false);
            return setFormError({
              field: "benefit",
              error: "Benefit should not be more than 30 characters",
            });
          }
        }
      }
    }

    setFormIsValid(true);
    return setFormError({ field: "", error: "" });
  };

  return [formIsValid, formError, validateFormInputs];
};

export default useFormValidation;
