import React, { useState } from "react";

interface Option {
  value: string;
  label: string;
}

interface StyledDropdownProps {
  options: Option[];
  defaultValue?: string;
  onChange: (value: string) => void;
}

const StyledDropdown: React.FC<StyledDropdownProps> = ({
  options,
  defaultValue = "",
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(
    options.find((option) => option.value === defaultValue) || options[0]
  );
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const handleSelect = (option: Option) => {
    setSelected(option);
    onChange(option.value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <button
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
        className="inline-flex justify-between items-center w-64 px-4 py-2 bg-[#181818] text-white text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring focus:ring-gray-500"
      >
        {selected.label}
        <svg
          className={`w-4 h-4 ml-2 transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-64 bg-[#181818]  rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ul className="py-1 text-sm text-white">
            {options.map((option) => (
              <li key={option.value}>
                <button
                  type="button"
                  onClick={() => handleSelect(option)}
                  className={`block w-full text-left px-4 py-2 hover:bg-gray-700 ${
                    option.value === selected.value ? "bg-gray-800" : ""
                  }`}
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default StyledDropdown;
